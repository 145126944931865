import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import 'rxjs/add/operator/filter';

@Component({
  selector: 'app-admin-sidebar',
  templateUrl: './admin-sidebar.component.html',
  styleUrls: ['./admin-sidebar.component.scss']
})
export class AdminSidebarComponent implements OnInit {

  @Output() onSelect = new EventEmitter<number>()

  selected = 4;
  isCollapsed = false;

  constructor() { }

  ngOnInit() {
    this.selectMenu(4);
  }

  selectMenu(indx: number): void {
    this.selected = indx;
    this.onSelect.emit(this.selected);
  }
}
