import { Injectable } from '@angular/core';
import { ResetPasswordModel} from '../model/reset-password.model';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject';
import { BehaviorSubject } from 'rxjs';
import 'rxjs/add/observable/of';
import { ServiceHandlerService } from '../../../shared/services/service-handler.service';
import { RoutePaths } from 'src/app/shared/constants';

@Injectable()
export class ResetPasswordService {
  footSubject: Subject<ResetPasswordModel> = new BehaviorSubject<ResetPasswordModel>(null);
  resetPassobj: { token: ResetPasswordModel; };
  resetBody: { email: any; password: any; auth: any; };

  constructor(private serviceHandlerService: ServiceHandlerService, private router: Router) { }

  resetPassword(resetPasswordDetails) {
    
    this.resetBody = {email: resetPasswordDetails.email, password: resetPasswordDetails.password, auth: resetPasswordDetails.auth};
    return this.serviceHandlerService.resetPost(`${RoutePaths.RESET_PASSWORD}`, this.resetBody)
      .pipe(
        map((res: ResetPasswordModel) => {
          if (res) {
            this.footSubject.next(res);
          }
          return res;
        })
      );
  }

  default(externalLandingDetails: ResetPasswordModel): Observable<ResetPasswordModel> {
    this.resetPassobj = {token: externalLandingDetails.id};
    return this.serviceHandlerService.loginPost<ResetPasswordModel>(`${RoutePaths.TRANSACTION}`, this.resetPassobj)
      .pipe(
        map((res: ResetPasswordModel) => {
          if (res) {
            this.footSubject.next(res);
          }
          return res;
        })
      );
  }

}
