<div class="full-width">
    <div class="header"> 
        <div>   
            <img src="assets/images/gkvk_logo.png" width="65" height="62" id="logoHome">

            <div>
                <h1>404</h1>
            </div>
            
        </div>  
    </div>
</div>

<div class="ContainerText">
    	<div class="errormessage">
        		<h1>Our apologies. We're unable to find the page you are looking for.</h1>
    	</div>
</div>





  
