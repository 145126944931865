import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ResetPasswordComponent } from './component/reset-password.component';
import { ResetPasswordService } from './service/reset-password.service';
import { ResetPasswordRoutingModule } from './reset-password.routing.module';
import { HttpClientModule } from '@angular/common/http';
import { NzGridModule } from 'ng-zorro-antd/grid';
import {MatGridListModule} from '@angular/material/grid-list';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    ResetPasswordRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    MatGridListModule,
    SharedModule,
    NzGridModule,
    HttpClientModule
  ],
  declarations: [ResetPasswordComponent],
   providers: [ResetPasswordService]
})
export class ResetPasswordModule { }
