<app-header></app-header>
<div class="container">

<div nz-row>
  <!-- <div nz-col nzSpan="12">
    <div class="message">
      Message from the VC to the students, internal members and external members about the Thesis Evaluation System, 
      why this has been put together, how it will help in contactless evaluation of project reports/theses.
    </div>
  </div> -->
  <div nz-col nzSpan="6">
  </div>
  <div class="signInForm" nz-col nzSpan="12">
      <div class="form-container" *ngIf="signInView">
      <div class="form-header">
        Sign In
      </div>
        <div class="form-content login" >
          <form role="form" method="POST" [formGroup]="loginForm">

            <input class="input-field red-border" [ngClass]="{'red-border': errorMsg}" type="text" name="email"
              formControlName="email" placeholder="Enter Email ID" (keypress)="filterKeyEvent($event)" autocomplete="off" required>
      
            <div class="invalid-text" *ngIf="errorMsg">Please enter a valid Email ID.</div>
            <br />
      
            <input class="input-field" type="password" id="password" formControlName="password" placeholder="Enter Password" [ngClass]="{'red-border': errorMsg}" (focus)="showError()" (blur)="hideError()" required>
            <div class="invalid-text" *ngIf="errorMsg">Please enter a valid Password.</div>
            <div class="invalid-password-text" *ngIf="authErrorMessage">Invalid credentials</div>
            <div class="invalid-password-text" *ngIf="authErrordeactiveMessage">Account deactivated. Please contact your administrator.</div>
            <div class="invalid-text-lock" *ngIf="authErrorLockMessage">Too many login attempts. Please use forgot password to reset your password.</div>
            
            <!-- <br /> -->
            <mat-grid-list cols="2">
              <mat-grid-tile class="grid-title">
                <button class="green login-btn" type="button" (click)="verifyLogin()">Sign In</button>
              </mat-grid-tile>
              <mat-grid-tile class="grid-title">
                <div class="forgot-password">
                  <a class="reset-link" (click)="openForgotPasswordModal()">Forgot Password</a>
                </div>
              </mat-grid-tile>
            </mat-grid-list>          
          </form>
        </div>          
    </div>

    <div class="form-container" *ngIf="secretOTPView">
      <div class="form-header">
        One Time Password(OTP)
      </div>
      <div class="pull-left modal-txt">&nbsp;
      </div>
        <div class="form-content login" >
          <form role="form" method="POST" [formGroup]="secretOTPForm">
            <div class="form-group">
              <div class="forgotpassword-label">Please enter your One Time Password</div>
              
                <input class="input-field red-border" [ngClass]="{'red-border': secretOTPErrorMsg}" type="text" maxlength="6"
                 name="secretOTP" formControlName="secretOTP" placeholder="Enter OTP" (keypress)="filterKeyEvent($event)" autocomplete="no-autoOTP" required>

               <div class="invalid-text" *ngIf="secretOTPErrorMsg">{{secretOTPErrorMsg}}</div>
            </div>
            <mat-grid-list cols="2">
              <mat-grid-tile class="grid-title">
                <button class="green login-btn" type="button" (click)="login()">Sign In</button>
              </mat-grid-tile>
              <mat-grid-tile class="grid-title">
                <div class="forgot-password">
                  <a class="reset-link" (click)="closeSecretOTPModal()">Back to Sign In</a>
                </div>
              </mat-grid-tile>
            </mat-grid-list>  
          </form>
        </div>          
    </div>


    <div class="form-container" *ngIf="forgotPasswordView">
      <div class="form-header">
        Forgot Password
      </div>
      <div class="pull-left modal-txt">&nbsp;
      </div>
        <div class="form-content login" >
          <form role="form" method="POST" [formGroup]="forgotpasswordForm" (ngSubmit)="sendPasswordResetLink()">
            <div class="form-group">
              <div class="forgotpassword-label">To reset your password, please enter your E-mail Address.</div>
              
                <input class="input-field red-border" [ngClass]="{'red-border': forgotPasswordErrorMsg}" type="text"
                 name="emailId" formControlName="emailId" placeholder="Enter Email ID" (keypress)="filterKeyEvent($event)" autocomplete="no-autoforgtpass" required>

               <div class="invalid-text" *ngIf="forgotPasswordErrorMsg">Please enter a valid Email ID.</div>
            </div>
            <mat-grid-list cols="2">
              <mat-grid-tile class="grid-title">
                <button class="green login-btn" type="submit">Submit</button>
              </mat-grid-tile>
              <mat-grid-tile class="grid-title">
                <div class="forgot-password">
                  <a class="reset-link" (click)="closeForgotPasswordModal()">Back to Sign In</a>
                </div>
              </mat-grid-tile>
            </mat-grid-list>  
          </form>
        </div>          
    </div>


    <div class="form-container" *ngIf="forgotPasswordSuccessView">
      <div class="form-header">
        Check your Email
      </div>
      <div class="pull-left modal-txt">&nbsp;
      </div>
        <div class="form-content login" >
            <div class="form-group">
              <div class="forgotpassword-success-label">An email with a link to reset your password is sent to the email address you provided.</div>

                <input class="input-field" type="hidden">
            </div>
            
            <mat-grid-list cols="2">
              <mat-grid-tile class="grid-title">
                <button class="green login-btn" (click)="continue()" type="button">Continue</button>
              </mat-grid-tile>
              
            </mat-grid-list>
        </div>          
    </div>

  </div>
  <div nz-col nzSpan="6">
  </div>
</div>
</div>

<nz-modal
      [nzStyle]="{ top: '20px' }"
      [(nzVisible)]="isVisibleTop"
      nzTitle="Confidentiality, Security and  Terms"
      nzClosable="false"
      nzMaskClosable = "false"
      nzOkText="Agree"
      nzCancelText="Disagree"
      (nzOnCancel)="cancelLogin()"
      (nzOnOk)="proceedToLogin(loginToken, loginRole, loginEmail)"
    >
      <div style="max-height: 45vh; overflow: scroll;">
        Confidentiality Agreement
        <br>
        <br>

This agreement outlines the obligations of confidentiality and non-disclosure that the Evaluator must adhere to in relation to the thesis evaluation process. "Confidential Information" refers to all materials, documents, findings, discussions, and any other information related to the UASB postgraduate / Ph.D. Student's thesis.

Obligations:<br><br>

A. The Evaluator agrees to treat all Confidential Information with the highest level of confidentiality and to use it solely for the purpose of evaluating the Student's thesis.<br>

B. The Evaluator shall not, without the prior written consent of the Institution / UASB, disclose, distribute, reproduce or otherwise make available any part of the Confidential Information to any third party.<br>

C. The Evaluator agrees to use the Confidential Information solely for the purpose of evaluating the Student's thesis and providing feedback to the Institution. The Evaluator shall not use the Confidential Information for personal gain, research or any other purpose not directly related to the evaluation process.<br>

D. The obligations of confidentiality and non-disclosure shall continue indefinitely beyond the conclusion of the evaluation process and shall remain in force even if the Evaluator's services are completed.<br>

E. Upon completion of the evaluation process, the Evaluator shall promptly return, delete or destroy all materials, documents and copies of the Confidential Information in their possession, in a manner that ensures the information's complete confidentiality.<br>

F. The UASB reserves the right to seek legal remedies and damages in the event of a breach of this Agreement by the Evaluator.<br>

By marking / ticking / signing below, the Evaluator acknowledges and agrees to the terms and conditions outlined in this Confidentiality and Non-Disclosure Agreement of the University (UASB).<br><br>

<div style="text-align: end !important;">
  Sign / Signature of evaluation
</div>
      </div>
    </nz-modal>