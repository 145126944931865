import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { LoginService } from '../../../modules/login/service/login.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SideNavService } from 'src/app/modules/home/service/side-nav.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { BlockUIService } from '../../services/block-ui.service';
import { UserProfileService } from '../../services/userprofile.service';
import { SharedService } from '../../services/shared.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  token: string;
  validSession: boolean;
  toggleValue: any;
  @Input() ifThesisTitle: boolean
  @Input() ifThesisSubmit: boolean
  @Output() toggleDrawer = new EventEmitter<boolean>();
  failureMsg: string;
  profileData: any;
  UserName: any;
  UserEmail: any;
  UserRole: any;
  placement: string = 'left';
  visible: boolean = false;
  UserReference: any;

  constructor(private loginService: LoginService, 
              private router: Router, 
              private sharedService: SharedService,
              private sideNavService: SideNavService,
              private blockUIService: BlockUIService,
              private userProfileService: UserProfileService,
              private modal: NzModalService) { }

  ngOnInit() {
    this.toggleValue = 0;
    this.token = localStorage.getItem('id');
    
    if (this.token) {
      this.validSession = true;
      this.getProfileData()     // get profile data
    } else {
      this.validSession = false;
    }

    
  }

  getProfileData(): void {
    
      // Call '' service
    const profileMetaData = { email: localStorage.getItem('email')};
    this.userProfileService.getUserProfile(profileMetaData).subscribe(data => {
      
      if (data.status_code === '01') {
        this.profileData = data.profile_data;
        this.UserName = data.profile_data.name;
        this.UserEmail = data.profile_data.email;
        this.UserRole = data.profile_data.role;
        this.UserReference = data.profile_data.ref_no;
          
        } else if (data.status_code === '06') {
          this.sharedService.logout();
          this.router.navigate(['/']);
          
        } else {
          this.failureMsg = data.message;
        }
      }, (err) => {
        this.failureMsg = 'Something went wrong. Please Try again';
      });
  }

  logout() {
    this.loginService.logout();
  }

  routeTo(): void {
    this.router.navigate(['/home']);
  }

  resetpassword() {
    // this.router.navigateByUrl(RouteUrls.RESET_PASSWORD);
  }

  profileDrawer() {
    this.toggleDrawer.emit(true);
  }

  clickMenu() {
  }

}
