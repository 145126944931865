import { NgModule } from '@angular/core';
import { HomeComponent } from './component/home.component';
import { RouterModule, Routes } from '@angular/router';
import { UserHomeComponent } from './user-component/user-component.component';
import { DocDetailsComponent } from './doc-details/doc-details.component';
import { MasterGuard } from 'src/app/shared/guards/master-guard';
import { InternalUserAuthGuardService } from 'src/app/shared/guards/internal-user-auth-guard';
import { ViewDocumentComponent } from './view-doc/view-doc.component';

export const externalLandingRoutes: Routes = [
  { path: 'dashboard', component: HomeComponent, canActivate: [InternalUserAuthGuardService] },
  { path: 'home', component: UserHomeComponent, canActivate: [MasterGuard] },
  { path: 'document', component: DocDetailsComponent, canActivate: [MasterGuard] },
  { path: 'document/:id', component: DocDetailsComponent, canActivate: [MasterGuard] },
  { path: 'view', component: ViewDocumentComponent },
  // { path: 'default', component: ExternalLandingComponent }
  // { path: 'default?id=:guid', component: ExternalLandingComponent }
];

@NgModule({
  imports: [RouterModule.forChild(externalLandingRoutes)],
  exports: [RouterModule]
})
export class HomeRoutingModule { }
