<div nz-row class="home-header-row">
  <div nz-col nzSpan="24">
      <app-header></app-header>
  </div>
</div>

<div *ngIf="isAdvisor">
  <div nz-row style="background: #f6f8f9; z-index: 9;">
    <div nz-col [nzSpan]="5" class="scroll-container">
      <div style="margin-top: 53px;">
        <div class="comment-container">

          <nz-card nzSize="small"  style="width:auto;margin-top: 0px;" (click)="activateCard()" >
            <div class="">
              <nz-breadcrumb>
                <nz-breadcrumb-item>
                <a (click)="routeToHome()">Home</a>
                </nz-breadcrumb-item>
                <nz-breadcrumb-item *ngIf="showBreadcrumb">
                  <a (click)="routeToDocDetails()">Thesis details</a>
                </nz-breadcrumb-item>
                <nz-breadcrumb-item>
                  Thesis
                </nz-breadcrumb-item>
              </nz-breadcrumb>
            </div>
          </nz-card>
          <nz-card class="tes-card" nzSize="small" style="width:auto;margin-top: 20px"  >
            <div class="content-body question-border" >
              <p class="containers-details">Thesis Title: <span class="boldFont">{{thesisName}}</span></p>
               <p class="containers-details">Student: <span class="boldFont">{{StudentName}}</span></p>
               <p class="containers-details">Student ID: <span class="boldFont">{{StudentID}}</span></p>
            </div>
          </nz-card>
        </div>
        <!-- <div class="comment-container" >
          <nz-card class="display-help" (click)="ShowHelp()" nzSize="small"  style="width:auto;margin-top: 0px;" >
            <div class="helpTitle">
              <span >{{ showHelpCard ? 'Hide help': 'Show help' }}</span>
            </div>
          </nz-card>
          <nz-card *ngIf="showHelpCard" class="tes-card" nzSize="small" style="width:auto;margin-top: 20px"  >
            <div class="content-body question-border" >
              <p class="help-details"><span style="font-weight: bold;">Add Comment</span>: You can add a comment by selecting a portion of text in the document and then add the comment. The added comment will be shown in the 'Comments' section</p>
              <p class="help-details"><span style="font-weight: bold;">Comments</span>: Please note that all comments are saved automatically as soon as they are added.</p>
            </div>
          </nz-card>
        </div> -->

        <!-- <div class="comment-container" *ngIf="userRole ==='external_member'">
          <nz-card class="display-help" nzSize="small"  style="width:auto;margin-top: 0px;" >
            <div class="helpTitle">
              <span >Questions</span>
            </div>
          </nz-card>

          <div>
            <nz-card nzHoverable nzSize="small" class="tes-card"  style="width:auto;margin-top: 20px" *ngFor="let list of questionsList;" (click)="moveTo(list); $event.stopPropagation();">
              <div class="content-body">                
                <div nz-row nzSpan="24" class="comment-list">
                  <p>{{list.question_text}}</p>
                </div>
                <div >
                    <div nz-row nzSpan="24" [ngClass]="{'reply-comment-list': list.answer_text}" class="comment-list" *ngIf="list.answer_text">
                      <p>{{list.answer_text}}</p>
                    </div>
                  </div>                 
              </div>
              <div>
              </div>
            </nz-card>
            </div>

        </div> -->

      </div>
    </div>
    <div nz-col [nzSpan]="14">
      <div style="margin-top: 53px;">
        <div class="pdf-container">
          <div class="iframe-ht" [ngClass]="{'show': true}" >
              <pdf-viewer *ngIf="showPdf"  [src]="pdfSrc" [render-text]="true" [original-size]="false" [show-all]="true" [(page)]="pageVariable" (error)="onError($event)" (page-rendered)="pageRendered($event)"></pdf-viewer>
          </div>
        </div>
        
        <div class="js-popper popper" [style.display]="getStyle()">
          <div class="icons">
            <nz-card nzHoverable nzSize="small" id="q1" class="Add-comment"  >
              <div class="content-body">
                <div nz-col nzSpan="24" class="paddingLR-0">
                  <textarea [nzAutosize]="{ minRows: 3, maxRows: 20 }"  [(ngModel)]="addComment" rows="5" cols="33" style="color:black; font-size:16px" placeholder="Add comment" class="textarea-comment">
                  </textarea>
                </div>
                <div class="btn-container">
                  <button nz-button nzSize="small" nzType="default" (click)="cancel()">Cancel</button>
                 <button nz-button class="btn-save" nzSize="small" nzType="primary" (click)="save()">Save</button>
                </div>
              </div>
            </nz-card>
          </div>
        </div>
      </div>
    </div>
    <div  nz-col [nzSpan]="5" class="scroll-container">
      <div style="margin-top: 53px;">
        <div class="comment-container">
          <nz-card nzSize="small"  style="width:auto;margin-top: 0px;" (click)="activateCard()" >
            <nz-card-meta style="text-align: center;" nzTitle="Comments"></nz-card-meta>
            <div>
            </div>
          </nz-card>

          <nz-card *ngIf="areaInfo.length === 0" nzSize="small" style="width:auto;margin-top: 4%;">
            <div class="align-center">
              No comments added
            </div>
          </nz-card>
          <div *ngIf="areaInfo.length > 0">
            <nz-card nzHoverable nzSize="small" class="tes-card" style="width:auto;margin-top: 20px"
              *ngFor="let list of areaInfo;" (click)="moveTo(list); $event.stopPropagation();">
              <div class="content-body">
                <div nz-row nzSpan="24" class="">
                  <span nz-col nzSpan="14" class="card-title"><b>{{list.comments[0].name}}</b></span>
                  <div nz-col nzSpan="10" style="float: right; text-align: right;" *ngIf="list.status">
                    <span
                      [ngClass]="{'chip': list.status === 'accepted', 'rejectChip': list.status === 'rejected', 'pendingChip': list.status === 'pending'}">
                      {{list.status | titlecase}}
                    </span>
                  </div>
                </div>
                <div nz-row nzSpan="24">
                  <span class="card-date">{{list?.comments[0].comment_datetime | date: 'longDate'}}</span>
                </div>
                <div nz-row nzSpan="24" class="comment-list">
                  <p>{{list?.comments[0].comment}}</p>
                </div>
                <div>
                  <div nz-row nzSpan="24" *ngIf="list.comments.length > 1"
                    [ngClass]="{'reply-comment-list': list.comments.length > 1}">
                    <span class="card-title"><b>{{list.comments[1].name}}</b></span>
                  </div>
                  <div nz-row nzSpan="24" *ngIf="list.comments.length > 1">
                    <span class="card-date">{{list.comments[1].comment_datetime | date: 'longDate' }}</span>
                  </div>
                  <div nz-row nzSpan="24" class="comment-list" *ngIf="list.comments.length > 1 ">
                    <p>{{list.comments[1].comment}}</p>
                  </div>
                </div>          
              </div>
              <div>
              </div>
            </nz-card>
          </div>
        </div>

      </div>
    </div>
    <!-- <div nz-col [nzSpan]="5" class="scroll-container"> -->
      <!-- <div style="margin-top: 53px;">
        <div class="comment-container">
          <nz-card nzSize="small"  style="width:auto;margin-top: 0px;" (click)="activateCard()" >
            <nz-card-meta style="text-align: center;" nzTitle="Comments"></nz-card-meta>
            <div>
            </div>
          </nz-card>

          <nz-card *ngIf="areaInfo.length === 0" nzSize="small"  style="width:auto;margin-top: 4%;" >
            <div class="align-center">
              No comments added
            </div>
          </nz-card>
          <div *ngIf="areaInfo.length > 0">
          <nz-card nzHoverable nzSize="small" class="tes-card"  style="width:auto;margin-top: 20px" *ngFor="let list of areaInfo;" (click)="moveTo(list); $event.stopPropagation();">
            <div class="content-body">
              <div nz-row nzSpan="24" class="">
                <span nz-col nzSpan="14" class="card-title"><b>{{list.comments[0].name}}</b></span>
                <div nz-col nzSpan="10" style="float: right; text-align: right;" *ngIf="list.status">
                  <span
                    [ngClass]="{'chip': list.status === 'accepted', 'rejectChip': list.status === 'rejected', 'pendingChip': list.status === 'pending'}">
                    {{list.status | titlecase}}
                  </span>
                </div>
              </div>
              <div nz-row nzSpan="24">
                <span class="card-date">{{list?.comments[0].comment_datetime | date: 'longDate'}}</span>
              </div>
              <div nz-row nzSpan="24" class="comment-list">
                <p>{{list?.comments[0].comment}}</p>
              </div>
              <div >
                  <div nz-row nzSpan="24" *ngIf="list.comments.length > 1" [ngClass]="{'reply-comment-list': list.comments.length > 1}">
                    <span class="card-title"><b>{{list.comments[1].name}}</b></span>                  
                  </div>
                  <div nz-row nzSpan="24" *ngIf="list.comments.length > 1">
                    <span class="card-date">{{list.comments[1].comment_datetime | date: 'longDate' }}</span>
                  </div>
                  <div nz-row nzSpan="24" class="comment-list" *ngIf="list.comments.length > 1 ">
                    <p>{{list.comments[1].comment}}</p>
                  </div>
                </div>               
            </div>
            <div>
            </div>
          </nz-card>
          </div>
        </div>

      </div> -->
    <!-- </div> -->
  </div>
</div>

<div *ngIf="isExternalAdvisor">
  <div nz-row style="background: #f6f8f9; z-index: 9;">
    <div nz-col [nzSpan]="5" class="scroll-container">
      <div style="margin-top: 53px;">
        <div class="comment-container">

          <nz-card nzSize="small" style="width:auto;margin-top: 0px;" (click)="activateCard()">
            <div class="">
              <nz-breadcrumb>
                <nz-breadcrumb-item>
                  <a (click)="routeToHome()">Home</a>
                </nz-breadcrumb-item>
                <nz-breadcrumb-item *ngIf="showBreadcrumb">
                  <a (click)="routeToDocDetails()">Thesis details</a>
                </nz-breadcrumb-item>
                <nz-breadcrumb-item>
                  Thesis
                </nz-breadcrumb-item>
              </nz-breadcrumb>
            </div>
          </nz-card>

          <nz-card class="tes-card" nzSize="small" style="width:auto;margin-top: 4%">
            <div class="content-body question-border">
              <p class="containers-details">Thesis Title: <span class="boldFont">{{thesisName}}</span></p>
              <p class="containers-details">Student: <span class="boldFont">{{StudentName}}</span></p>
              <p class="containers-details">Student ID: <span class="boldFont">{{StudentID}}</span></p>
            </div>
          </nz-card>
            <nz-card class="display-help" (click)="ShowHelp()" nzSize="small" style="width:auto;margin-top: 4%;">
              <div class="helpTitle">
                <span>{{ showHelpCard ? 'Hide help': 'Show help' }}</span>
              </div>
            </nz-card>
          
            <nz-card *ngIf="showHelpCard" class="tes-card" nzSize="small" style="width:auto;margin-top: 20px">
              <div class="content-body question-border">
                <p class="help-details"><span style="font-weight: bold;">Add Comment</span>: You can add a comment by selecting a
                  portion of text in the document and then add the comment. The added comment will be shown in the 'Comments'
                  section</p>
                <p class="help-details"><span style="font-weight: bold;">Comments</span>: Please note that all comments are saved
                  automatically as soon as they are added.</p>
                <p class="help-details"><span style="font-weight: bold;">Questions</span>: Please make sure all questions are answered before submitting review comments.</p>
              </div>
            </nz-card>

          <!-- <nz-card nzSize="small"  style="width:auto;margin-top:4%;" (click)="activateCard()" >
            <nz-card-meta style="text-align: center;" nzTitle="Questions"></nz-card-meta>
            <div>
            </div>
          </nz-card> -->


          <!-- <nz-card nzHoverable class="tes-card" nzSize="small" id="q1" *ngFor="let qesList of questionsList"  style="width:auto;margin-top: 20px"  >
            <div class="content-body question-border" >
               <p class="containers" [class.show]="qesList.show">{{qesList.question_text}}</p>
               <span *ngIf="qesList.question_text.length >= 60" style="font-size: x-small;" (click)="qesList.show = !qesList.show"> {{ qesList.show ? 'Show less': 'Show More' }}</span>
            </div>
            <div *ngIf="!showReply" (click)="activateQuestionCard(qesList)" style="float: right; margin-top: 4%;">
              <span style="color: #179EEA;" *ngIf="!qesList.answer_text">Answer</span>
              <span style="color: #179EEA;" *ngIf="qesList.answer_text">View Answer</span>
            </div>
            <div *ngIf="qesList.showReply">
              <textarea *ngIf="qesList.answer_text"
              [(ngModel)]="qesList.answer_text"
                  style="margin-top: 7px;"
                  class="textarea-question"
                  nz-input
                  placeholder="Write your answer here"
                  [nzAutosize]="{ minRows: 2, maxRows: 6 }">
                </textarea>
              <textarea *ngIf="!qesList.answer_text"
              [(ngModel)]="qesList.answer_filler"
                  style="margin-top: 7px;"
                  class="textarea-question"
                  nz-input
                  placeholder="Write your answer here"
                  [nzAutosize]="{ minRows: 2, maxRows: 6 }">
                </textarea>
              <div style="margin-top: 5%; float: right;">
                <button  nz-button nzSize="small" (click)="hideQuestionCard(qesList)" nzType="default">Cancel</button>
                <button  nz-button nzSize="small" (click)="saveAnswer(qesList)" style="margin-left: 10px;" nzType="primary">Save</button>
              </div>
            </div>
            <div>
            </div>
          </nz-card> -->
        </div>

      </div>
    </div>
    <div nz-col [nzSpan]="14">
      <div style="margin-top: 53px;">
        <div class="pdf-container">
          <div class="iframe-ht" [ngClass]="{'show': true}"  *ngIf="thesisCurrentStatus !== 'complete'"
          (mousemove)="mouseEvent($event)" 
          (mousedown)="mouseEvent($event)"
          (mouseup)="mouseEvent($event)">
          
              <pdf-viewer *ngIf="showPdf" 
              [src]="pdfSrc"
              [render-text]="true"
              [original-size]="false"
              [show-all]="true"
              [(page)]="pageVariable"
              (error)="onError($event)"
              (page-rendered)="pageRendered($event)">
            </pdf-viewer>
              <!-- <pdf-viewer  *ngIf="showPdf"  [src]="pdfSrc"
              [render-text]="true" [show-all]="true" [(page)]="pageVariable" (error)="onError($event)" (page-rendered)="pageRendered($event)"></pdf-viewer> -->
          </div>
          <div class="iframe-ht" [ngClass]="{'show': true}" *ngIf="thesisCurrentStatus === 'complete'">
          
              <pdf-viewer *ngIf="showPdf" 
              [src]="pdfSrc"
              [render-text]="true"
              [original-size]="false"
              [show-all]="true"
              [(page)]="pageVariable"
              (error)="onError($event)"
              (page-rendered)="pageRendered($event)">
            </pdf-viewer>
              <!-- <pdf-viewer  *ngIf="showPdf"  [src]="pdfSrc"
              [render-text]="true" [show-all]="true" [(page)]="pageVariable" (error)="onError($event)" (page-rendered)="pageRendered($event)"></pdf-viewer> -->
          </div>
        </div>

        <div  *ngIf="userRole === thesisAdvisorType && userEmail === tokenEmail" class="js-popper popper" [style.display]="getStyle()">
          <div class="icons">
            <nz-card nzHoverable nzSize="small" id="q1" class="Add-comment"  >
              <div class="content-body">
                <div nz-col nzSpan="24" class="paddingLR-0">
                  <textarea [nzAutosize]="{ minRows: 3, maxRows: 20 }"  [(ngModel)]="addComment" rows="5" cols="33" style="color:black; font-size:16px" placeholder="Add comment" class="textarea-comment">
                  </textarea>
                </div>
                <div class="btn-container">
                  <button nz-button nzSize="small" nzType="default" (click)="cancel()">Cancel</button>
                 <button nz-button class="btn-save" nzSize="small" nzType="primary" (click)="save()">Save</button>
                </div>
              </div>
            </nz-card>
          </div>
        </div>
      </div>
    </div>
    <div nz-col [nzSpan]="5" class="scroll-container">
      <div style="margin-top: 53px;">
        <div class="comment-container">
          <nz-card nzSize="small"  style="width:auto;margin-top: 0px;" (click)="activateCard()" >
            <nz-card-meta style="text-align: center;" nzTitle="Comments"></nz-card-meta>
            <div>
            </div>
          </nz-card>

          <nz-card *ngIf="areaInfo.length === 0" nzSize="small" style="width:auto;margin-top: 4%;">
            <div class="align-center">
              No comments added
            </div>
          </nz-card>
          <div *ngIf="areaInfo.length > 0">
            <nz-card nzHoverable nzSize="small" class="tes-card" style="width:auto;margin-top: 20px"
              *ngFor="let list of areaInfo;" (click)="moveTo(list); $event.stopPropagation();">
              <div class="content-body">
                <div nz-row nzSpan="24" class="">
                  <span nz-col nzSpan="14" class="card-title"><b>{{list.comments[0].name}}</b></span>
                  <div nz-col nzSpan="10" style="float: right; text-align: right;" *ngIf="list.status">
                    <span
                      [ngClass]="{'chip': list.status === 'accepted', 'rejectChip': list.status === 'rejected', 'pendingChip': list.status === 'pending'}">
                      {{list.status | titlecase}}
                    </span>
                  </div>
                </div>
                <div nz-row nzSpan="24">
                  <span class="card-date">{{list?.comments[0].comment_datetime | date: 'longDate'}}</span>
                </div>
                <div nz-row nzSpan="24" class="comment-list">
                  <p>{{list?.comments[0].comment}}</p>
                </div>
                <div>
                  <div nz-row nzSpan="24" *ngIf="list.comments.length > 1"
                    [ngClass]="{'reply-comment-list': list.comments.length > 1}">
                    <span class="card-title"><b>{{list.comments[1].name}}</b></span>
                  </div>
                  <div nz-row nzSpan="24" *ngIf="list.comments.length > 1">
                    <span class="card-date">{{list.comments[1].comment_datetime | date: 'longDate' }}</span>
                  </div>
                  <div nz-row nzSpan="24" class="comment-list" *ngIf="list.comments.length > 1 ">
                    <p>{{list.comments[1].comment}}</p>
                  </div>
                </div>          
              </div>
              <div>
              </div>
            </nz-card>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<div *ngIf="isStudent">
  <div nz-row style="background: #f6f8f9; z-index: 9;">
    <div nz-col [nzSpan]="5" class="scroll-container">
      <div style="margin-top: 53px;">
        <div class="comment-container">
      
          <nz-card nzSize="small" style="width:auto;margin-top: 0px;" (click)="activateCard()">
            <div class="">
              <nz-breadcrumb>
                <nz-breadcrumb-item>
                  <a (click)="routeToHome()">Home</a>
                </nz-breadcrumb-item>
                <nz-breadcrumb-item *ngIf="showBreadcrumb">
                  <a (click)="routeToDocDetails()">Thesis details</a>
                </nz-breadcrumb-item>
                <nz-breadcrumb-item>
                  Thesis
                </nz-breadcrumb-item>
              </nz-breadcrumb>
            </div>
          </nz-card>
      
          <nz-card class="tes-card" nzSize="small" style="width:auto;margin-top: 20px">
            <div class="content-body question-border">
              <p class="containers-details">Thesis Title: <span class="boldFont">{{thesisName}}</span></p>
              <p class="containers-details">Student: <span class="boldFont">{{StudentName}}</span></p>
              <p class="containers-details">Student ID: <span class="boldFont">{{StudentID}}</span></p>
      
            </div>
          </nz-card>
        </div>
        <div class="comment-container" *ngIf="userRole ==='external_member'">
          <nz-card class="display-help" (click)="ShowHelp()" nzSize="small"  style="width:auto;margin-top: 0px;" >
            <div class="helpTitle">
              <span >Questions</span>
            </div>
          </nz-card>

          <div>
            <nz-card nzHoverable nzSize="small" class="tes-card"  style="width:auto;margin-top: 20px" *ngFor="let list of questionsListForAdvisors;" (click)="moveTo(list); $event.stopPropagation();">
              <div class="content-body">                
                <div nz-row nzSpan="24" class="comment-list">
                  <p>{{list.question_text}}</p>
                </div>
                <div >
                    <div nz-row nzSpan="24" [ngClass]="{'reply-comment-list': list.answer_text}" class="comment-list" *ngIf="list.answer_text">
                      <p>{{list.answer_text}}</p>
                    </div>
                  </div>                 
              </div>
              <div>
              </div>
            </nz-card>
            </div>

        </div>
      
      </div>

    </div>
    <div nz-col [nzSpan]="14">
      <div style="margin-top: 53px;">
        <div class="pdf-container">
          
            <div class="iframe-ht" [ngClass]="{'show': true}">
              <!-- <pdf-viewer *ngIf="showPdf" [src]="pdfSrc" [render-text]="true" [show-all]="true"
                 [(page)]="pageVariable" (page-rendered)="pageRendered($event)"
                (text-layer-rendered)="textLayerRendered($event)" (error)="onError($event)"></pdf-viewer> -->

                <pdf-viewer *ngIf="showPdf" 
                [src]="pdfSrc"
                [render-text]="true"
                [original-size]="false"
                [show-all]="true"
                [(page)]="pageVariable"
                (error)="onError($event)"
                (page-rendered)="pageRendered($event)">
              </pdf-viewer>
            </div>
        </div>
       
      </div>
    </div>
    <div nz-col [nzSpan]="5" class="scroll-container">
      <div style="margin-top: 53px;">
        <div class="comment-container">
          <nz-card nzSize="small"  style="width:auto;margin-top: 0px;" (click)="activateCard()" >
            <nz-card-meta style="text-align: center;" nzTitle="Comments"></nz-card-meta>
            <div>
            </div>
          </nz-card>
          <nz-card *ngIf="studentAreaInfo.length === 0" nzSize="small" style="width:auto;margin-top: 4%;">
            <div class="align-center">
              No comments added
            </div>
          </nz-card>
          <div *ngIf="studentAreaInfo.length > 0">
            <nz-card nzHoverable (mouseenter)="activeStudentReply(commentList)" class="tes-card" nzSize="small" id="q1" *ngFor="let commentList of studentAreaInfo;" (click)="moveTo(commentList); $event.stopPropagation();"  style="width:auto;margin-top: 20px"  >
  
              <div nz-row nzSpan="24" class="">
                <span nz-col nzSpan="14" class="card-title"><b>{{commentList.comments[0].name}}</b></span>
                <div nz-col nzSpan="10" style="float: right; text-align: right;" *ngIf="commentList.status">
                  <span [ngClass]="{'chip': commentList.status === 'accepted', 'rejectChip': commentList.status === 'rejected', 'pendingChip': commentList.status === 'pending'}">
                    {{commentList.status | titlecase}}
                  </span>
                </div>
              </div>
              <div nz-row nzSpan="24">
                <span class="card-date">{{commentList?.comments[0].comment_datetime | date: 'longDate'}}</span>
              </div>
              <div class="content-body row-container" (mouseenter)="activeStudentReply(commentList)" (click)="activeStudentReply(commentList)">
                <p>{{commentList?.comments[0].comment}}</p>
              </div>              
              <div *ngIf="commentList.markReply && commentList.comments.length >= 1">
                <textarea 
                    *ngIf="commentList.comments.length === 1"
                    [(ngModel)]="commentList.comments[0].reply"
                    style="margin-top: 7px;"
                    class="textarea-question"
                    nz-input
                    [ngClass]="{'highlightTextArea': commentList.comments[0].mandatoryComment, 'borderTextarea': commentList.comments[0].mandatoryCommentSuccess}"
                    placeholder="Enter comments here.."
                    (keypress)="onKeypressEvent($event, commentList)"
                    [nzAutosize]="{ minRows: 2, maxRows: 6 }">
                  </textarea>
                <textarea 
                    *ngIf="commentList.comments.length > 1"
                    [(ngModel)]="commentList.comments[1].comment"
                    style="margin-top: 7px;"
                    class="textarea-question"
                    nz-input
                    [ngClass]="{'highlightTextArea': commentList.comments[0].mandatoryComment, 'borderTextarea': commentList.comments[0].mandatoryCommentSuccess}"
                    placeholder="Enter comments here.."
                    (keypress)="onKeypressEvent($event, commentList)"
                    [nzAutosize]="{ minRows: 2, maxRows: 6 }">
                  </textarea>
                <div>
                  <div *ngIf="commentList.comments.length === 1" style="margin-top: 5%; float: right;">
                    <button  nz-button nzSize="small" (click)="rejectStudentReply(commentList, 'initial')" nzType="default">Reject</button>
                    <button  nz-button nzSize="small" (click)="acceptStudentReply(commentList, 'initial')" style="margin-left: 10px;" nzType="primary">Accept</button>
                  </div>
                  <div *ngIf="commentList.comments.length > 1" style="margin-top: 5%; float: right;">
                    <button  nz-button nzSize="small" [disabled]="!canComment" (click)="rejectStudentReply(commentList, 'edit')" nzType="default">Reject</button>
                    <button  nz-button nzSize="small" [disabled]="!canComment" (click)="acceptStudentReply(commentList, 'edit')" style="margin-left: 10px;" nzType="primary">Accept</button>
                  </div>                  
                  
                </div>
              </div>
              <div>
              </div>
            </nz-card>
          </div>          
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="isDeanPgs">
  <div nz-row style="background: #f6f8f9; z-index: 9;">
    <div nz-col [nzSpan]="5" class="scroll-container">
      <div style="margin-top: 53px;">
        <div class="comment-container">

          <nz-card nzSize="small" style="width:auto;margin-top: 0px;" (click)="activateCard()">
            <div class="">
              <nz-breadcrumb>
                <nz-breadcrumb-item>
                  <a (click)="routeToHome()">Home</a>
                </nz-breadcrumb-item>
                <nz-breadcrumb-item *ngIf="showBreadcrumb">
                  <a (click)="routeToDocDetails()">Thesis details</a>
                </nz-breadcrumb-item>
                <nz-breadcrumb-item>
                  Thesis
                </nz-breadcrumb-item>
              </nz-breadcrumb>
            </div>
          </nz-card>

          <nz-card class="tes-card" nzSize="small" style="width:auto;margin-top: 4%">
            <div class="content-body question-border">
              <p class="containers-details">Thesis Title: <span class="boldFont">{{thesisName}}</span></p>
              <p class="containers-details">Student: <span class="boldFont">{{StudentName}}</span></p>
              <p class="containers-details">Student ID: <span class="boldFont">{{StudentID}}</span></p>
            </div>
          </nz-card>
        </div>

      </div>
    </div>
    <div nz-col [nzSpan]="14">
      <div style="margin-top: 53px;">
        <div class="pdf-container">
          <div class="iframe-ht" [ngClass]="{'show': true}" >
          
              <pdf-viewer *ngIf="showPdf" 
              [src]="pdfSrc"
              [render-text]="true"
              [original-size]="false"
              [show-all]="true"
              [(page)]="pageVariable"
              (error)="onError($event)"
              (page-rendered)="pageRendered($event)">
            </pdf-viewer>
              <!-- <pdf-viewer  *ngIf="showPdf"  [src]="pdfSrc"
              [render-text]="true" [show-all]="true" [(page)]="pageVariable" (error)="onError($event)" (page-rendered)="pageRendered($event)"></pdf-viewer> -->
          </div>
        </div>

        
      </div>
    </div>
    <div nz-col [nzSpan]="5" class="scroll-container">
      <div style="margin-top: 53px;">
        <div class="comment-container">
          <nz-card nzSize="small"  style="width:auto;margin-top: 0px;" (click)="activateCard()" >
            <nz-card-meta style="text-align: center;" nzTitle="Comments"></nz-card-meta>
            <div>
            </div>
          </nz-card>

          <nz-card *ngIf="areaInfo.length === 0" nzSize="small" style="width:auto;margin-top: 4%;">
            <div class="align-center">
              No comments added
            </div>
          </nz-card>
          <div *ngIf="areaInfo.length > 0">
            <nz-card nzHoverable nzSize="small" class="tes-card" style="width:auto;margin-top: 20px"
              *ngFor="let list of areaInfo;" (click)="moveTo(list); $event.stopPropagation();">
              <div class="content-body">
                <div nz-row nzSpan="24" class="">
                  <span nz-col nzSpan="14" class="card-title"><b>{{list.comments[0].name}}</b></span>
                  <div nz-col nzSpan="10" style="float: right; text-align: right;" *ngIf="list.status">
                    <span
                      [ngClass]="{'chip': list.status === 'accepted', 'rejectChip': list.status === 'rejected', 'pendingChip': list.status === 'pending'}">
                      {{list.status | titlecase}}
                    </span>
                  </div>
                </div>
                <div nz-row nzSpan="24">
                  <span class="card-date">{{list?.comments[0].comment_datetime | date: 'longDate'}}</span>
                </div>
                <div nz-row nzSpan="24" class="comment-list">
                  <p>{{list?.comments[0].comment}}</p>
                </div>
                <div>
                  <div nz-row nzSpan="24" *ngIf="list.comments.length > 1"
                    [ngClass]="{'reply-comment-list': list.comments.length > 1}">
                    <span class="card-title"><b>{{list.comments[1].name}}</b></span>
                  </div>
                  <div nz-row nzSpan="24" *ngIf="list.comments.length > 1">
                    <span class="card-date">{{list.comments[1].comment_datetime | date: 'longDate' }}</span>
                  </div>
                  <div nz-row nzSpan="24" class="comment-list" *ngIf="list.comments.length > 1 ">
                    <p>{{list.comments[1].comment}}</p>
                  </div>
                </div>          
              </div>
              <div>
              </div>
            </nz-card>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
