import { Component, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { LoginModel, ForgotPasswordModel } from '../model/login.model';
import { Router } from '@angular/router';
import { LoginService } from '../service/login.service';
import { Messages, RoutePaths, RouteUrls } from '../../../shared/constants/constants';
import { BlockUIService } from '../../../shared/services/block-ui.service';
import { CookieService } from 'ngx-cookie-service';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  isCustomer: boolean;
  errorMsg: string;
  forgotPasswordErrorMsg: string;
  successMsg: string;
  failureMsg: string;
  loginForm: FormGroup;
  secretOTPForm: FormGroup;
  forgotpasswordForm: FormGroup;
  messages: any;
  isValidSession: any;
  showForgotPasswordModal: boolean;
  showErrorMsg = false;
  authErrorMessage = false;
  sessionToken: any;
  signInView = true;
  forgotPasswordView = false;
  forgotPasswordSuccessView = false;
  authErrorLockMessage: boolean;
  authErrordeactiveMessage: boolean;
  isVisibleTop = false;
  loginEmail: string;
  showTAndCModal: boolean = false;
  loginToken: any;
  loginRole: any;
  secretOTPView= false;
  secretOTPErrorMsg= '';


  constructor(private fb: FormBuilder,
              private loginService: LoginService,
              private router: Router,
              private modal: NzModalService,
              private viewContainerRef: ViewContainerRef,
              private blockUIService: BlockUIService,
              private cookieService: CookieService) { }

  ngOnInit() {
    // Added by Arjun to identify the route logins
    this.isValidSession = localStorage.getItem('id');
    if (this.isValidSession) {
      if(localStorage.getItem('role') == 'admin'){
        this.router.navigate([RouteUrls.DASHBOARD]);
      } else {
        this.router.navigate([RouteUrls.HOME]);
      }

    }

    this.createForm();
    this.messages = Messages;
  }

  createForm() {
    this.loginForm = this.fb.group(
      {
        email: [null, Validators.compose([Validators.required, Validators.email])],
        password: [null, Validators.compose([Validators.required])],
      }
    );

    this.forgotpasswordForm = new FormGroup({
      emailId: new FormControl('',
        Validators.compose([
          Validators.required,
          Validators.email
        ]))
    });
  
    this.secretOTPForm = new FormGroup({
      secretOTP: new FormControl('',
        Validators.compose([
          Validators.required
        ]))
    });

  }


  filterKeyEvent(event) {
    var e =  event;  // get event object
    var key = e.keyCode // get key cross-browser
    if (((key>=33)
          &&(key<=44)||(key==94)||(key==61)||(key==91)||(key==92)||(key==93)||(key==47)||(key==123)
          ||(key==124)||(key==125)||(key==96)||(key==126)||(key>=58)&&(key<=63))) {
        if (e.preventDefault) e.preventDefault(); //normal browsers
        e.returnValue = false; //IE
    }
  }
 

  cancelLogin(): void {
    this.isVisibleTop = false;
  }

  verifyLogin() {
    if (this.loginForm.get('email').errors) { this.errorMsg = 'Need a valid email ID'; this.authErrorMessage = false; this.authErrorLockMessage = false; this.authErrordeactiveMessage = false; return; }
    if (this.loginForm.get('password').errors) { this.errorMsg = 'Need a valid password'; this.authErrorMessage = false; this.authErrorLockMessage = false; this.authErrordeactiveMessage = false; return; }

    this.blockUIService.display(true);
    const loginModel: LoginModel = { ...this.loginForm.value };
    if (loginModel.email && loginModel.password) {
      let cred = btoa(
        JSON.stringify({
          email: loginModel.email,
          password: loginModel.password
        })
      );

      this.loginService.getSecretOtp(cred).subscribe(data => {
        if (data.status === 'success') {
          this.signInView = false;
          this.forgotPasswordSuccessView = false;
          this.forgotPasswordView = false;
          this.secretOTPView = true;          
        } else if (data.status_code === '02') {
          this.authErrorLockMessage = false;
          this.authErrordeactiveMessage = false;
          this.authErrorMessage = true;
        } else if (data.status_code === '03') {
          this.authErrorMessage = false;
          this.authErrordeactiveMessage = false;
          this.authErrorLockMessage = true;
        } else if (data.status_code === '06') {
          this.authErrorMessage = false;
          this.authErrorLockMessage = false;
          this.authErrordeactiveMessage = true;
        } else {
          this.errorMsg = this.messages.INVALIDCREDENTIALS;
        }
      }, (err) => {
        this.blockUIService.display(false);
        this.errorMsg = this.messages.INVALIDCREDENTIALS;
      });
    } else {
      this.errorMsg = this.messages.INVALIDCREDENTIALS;
    }
  }

  login() {
    if (this.loginForm.get('email').errors) { this.errorMsg = 'Need a valid email ID'; this.authErrorMessage = false; this.authErrorLockMessage = false; this.authErrordeactiveMessage = false; return; }
    if (this.loginForm.get('password').errors) { this.errorMsg = 'Need a valid password'; this.authErrorMessage = false; this.authErrorLockMessage = false; this.authErrordeactiveMessage = false; return; }
    if (this.secretOTPForm.get('secretOTP').errors) { this.secretOTPErrorMsg = 'Please enter OTP'; this.authErrorMessage = false; this.authErrorLockMessage = false; this.authErrordeactiveMessage = false; return; }

    this.blockUIService.display(true);
    const loginModel: LoginModel = { ...this.loginForm.value };
    const secretModel: any = { ...this.secretOTPForm.value };
    if (loginModel.email && loginModel.password && secretModel.secretOTP) {
      let cred = btoa(
        JSON.stringify({
          email: loginModel.email,
          password: loginModel.password,
          secret: secretModel.secretOTP,
        })
      );

      this.loginService.login(cred).subscribe(data => {
        
        if (data.status_code === '02') {
          this.authErrorLockMessage = false;
          this.authErrordeactiveMessage = false;
          this.authErrorMessage = true;
        }
        if (data.status_code === '03') {
          this.authErrorMessage = false;
          this.authErrordeactiveMessage = false;
          this.authErrorLockMessage = true;
        }

        if (data.status_code === '06') {
          this.authErrorMessage = false;
          this.authErrorLockMessage = false;
          this.authErrordeactiveMessage = true;
        }
        if (data.status_code === '07') {
          this.authErrorMessage = false;
          this.authErrorLockMessage = false;
          this.authErrordeactiveMessage = false;
          this.secretOTPErrorMsg = data.message;
        }
        if (data.status === 'success') {
          // model
          this.loginToken = data.id;
          this.loginRole = data.role;
          this.loginEmail = loginModel.email;
          this.showTAndCModal = true;
          if (data.t_and_c_status !== 'agreed') {
            this.isVisibleTop = true;
          }
          else {
            this.proceedToLogin(this.loginToken, this.loginRole, this.loginEmail);
          }
        } else {
          this.errorMsg = this.messages.INVALIDCREDENTIALS;
        }
      }, (err) => {
        this.blockUIService.display(false);
        this.errorMsg = this.messages.INVALIDCREDENTIALS;
      });
    } else {
      this.errorMsg = this.messages.INVALIDCREDENTIALS;
    }
  }

  proceedToLogin(token, role, loginEmail): void {
    this.isVisibleTop = false;
    this.blockUIService.display(false);

    // document.cookie = 'Token=' + token + '; Path=/;SameSite=Strict;';
    if (role === 'student') {
      
      localStorage.setItem('id', token);
      localStorage.setItem('email', loginEmail);
      localStorage.setItem('role', role);

      this.router.navigate([RouteUrls.HOME]);
    }
    if (role === 'advisor') {            
      localStorage.setItem('id', token);
      localStorage.setItem('email', loginEmail);
      localStorage.setItem('role', role);

      this.router.navigate([RouteUrls.HOME]);
    }
    if (role === 'dean_pgs') {            
      localStorage.setItem('id', token);
      localStorage.setItem('email', loginEmail);
      localStorage.setItem('role', role);

      this.router.navigate([RouteUrls.HOME]);
    }
    if (role === 'admin') {            
      localStorage.setItem('id', token);
      localStorage.setItem('email', loginEmail);
      localStorage.setItem('role', role);
      // localStorage.setItem('email', data.email);

      this.router.navigate([RouteUrls.DASHBOARD]);
    }

    if (role === 'pg_coordinator') {
      
      localStorage.setItem('id', token);
      localStorage.setItem('email', loginEmail);
      localStorage.setItem('role', role);
      this.router.navigate([RouteUrls.HOME]);
    }

    if (role === 'hod') {
      
      localStorage.setItem('id', token);
      localStorage.setItem('email', loginEmail);
      localStorage.setItem('role', role);
      // localStorage.setItem('email', data.email);

      this.router.navigate([RouteUrls.HOME]);
    }

    if (role === 'dean') {
      
      localStorage.setItem('id', token);
      localStorage.setItem('email', loginEmail);
      localStorage.setItem('role', role);
      // localStorage.setItem('email', data.email);

      this.router.navigate([RouteUrls.HOME]);
    }

    if (role === 'external_member') {
      
      localStorage.setItem('id', token);
      localStorage.setItem('email', loginEmail);
      localStorage.setItem('role', role);
      // localStorage.setItem('email', data.email);

      this.router.navigate([RouteUrls.HOME]);
    }
    // this.router.navigate([RouteUrls.HOME]);
    this.closeTandC('agree');
    
  }



  closeTandC(modalStatus): void {
    this.blockUIService.display(true);
    const closeTermsMetadata = {
      user_action: modalStatus
    };
    this.loginService.callCloseterms(closeTermsMetadata).subscribe(data => {
    
    this.blockUIService.display(false);
    if (data.status_code === '01') {
 
    }
    }, (err) => {
     this.blockUIService.display(false);
    });
   }


  showError() {
    this.showErrorMsg = true;
  }

  hideError() {
    this.showErrorMsg = false;
  }

  sendPasswordResetLink() {
      if (this.forgotpasswordForm.get('emailId').errors) { this.forgotPasswordErrorMsg = 'Need a valid email ID'; return; }

      this.blockUIService.display(true);
      const forgotPasswordModel: ForgotPasswordModel = { ...this.forgotpasswordForm.value };
      if (forgotPasswordModel.emailId) {

        // Call forgot password service
        this.loginService.forgotPassword(forgotPasswordModel).subscribe(data => {
          this.blockUIService.display(false);
          if (data.status_code === '01') {
            this.forgotPasswordView = false;
            this.signInView = false;
            this.secretOTPView = false;
            this.forgotPasswordSuccessView = true;

          } else {
            this.failureMsg = data.message;
          }
        }, (err) => {
          this.blockUIService.display(false);
          this.failureMsg = this.messages.FORGOTPASSWORDERROR;
        });
      } else {
        this.failureMsg = this.messages.PASSWORDRESETERROR;
      }
  }

  openForgotPasswordModal() {
    this.forgotpasswordForm.reset(); // for clearing old values
    this.forgotPasswordErrorMsg = ''; // remove existing validations
    this.signInView = false;
    this.forgotPasswordSuccessView = false;
    this.secretOTPView = false;
    this.forgotPasswordView = true;
  }

  closeForgotPasswordModal() {
    this.loginForm.reset();
    this.errorMsg = '';
    this.forgotPasswordView = false;
    this.forgotPasswordSuccessView = false;
    this.secretOTPView = false;

    this.signInView = true;
  }
  closeSecretOTPModal() {
    this.loginForm.reset();
    this.secretOTPForm.reset();
    this.errorMsg = '';
    this.secretOTPErrorMsg = '';
    this.secretOTPView = false;
    this.forgotPasswordSuccessView = false;
    this.secretOTPView = false;

    this.signInView = true;
  }
  continue() {
    this.forgotPasswordSuccessView = false;
    this.forgotPasswordView = false;
    this.secretOTPView = false;
    this.signInView = true;
  }

}
