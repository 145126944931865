import { Component, OnInit, ViewChild, EventEmitter, OnChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HomeService } from '../service/home.service';
import { BlockUIService } from 'src/app/shared/services/block-ui.service';
import 'rxjs/add/operator/filter';
import { NzModalService, NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-user-home',
  templateUrl: './user-component.component.html',
  styleUrls: ['./user-component.component.scss']
})
export class UserHomeComponent implements OnInit, OnChanges {
  thesisTitle: string = '';
  titlePlaceholder: string = 'Enter thesis title here';
  files: any = [];
  titleError: boolean = false;

  searchValue = '';
  searchFilterValue = '';
  searchVisible = false;
  listOfReviewPendingData: internalPendingData[];
  listOfinternalHomeData: internalData[];
  listOfExternalHomeData: internalData[];
  listOfData: Person[] = [];
  listOfinternalHome: internalData[] = [];
  listOfReviewPending: internalPendingData[] = [];
  listOfExternalHome: internalData[] = [];
  showDocViewer = false;
  showReply: boolean = false;
  role: string;
  profileDrawer: boolean;
  failureMsg: any;
  ErrorMsg: any;
  AdvisorType: string;
  getQuestionMetaData: { email: string; thesis_id: any; };
  isVisible = false;
  selectedThesis: any;
  isRejectVisible = false;
  acceptReason: any;
  approvedStudentEmail: any;
  approvedThesisID: any;
  rejectedThesisID: any;
  rejectedStudentEmail: any;
  rejectReason: any;
  rejectReasonRequired: boolean = false;
  testshow: boolean = false;
  selectedAdv_id: any;
  selectedReviewDoc_id: any;
  reviewThesisfiles: File[] = [];
  @ViewChild('ReviewFile') ReviewFile;
  modalTitle: string;
  confirmModal?: NzModalRef;
  submission_type: string;
  sessionEmail: string;
  showAdvisorThesis: boolean = false;
  showpendingAdvisorThesis: boolean = false;
  isAnswerVisible = false;
  isPrintAnswerVisible = false;
  questions_data: any;
  print_questions_data: any;
  displayQuestions: boolean = false;
  displayPrintQuestions: boolean = false;
  selecetedThesis: any;
  disableComplete: boolean = false;
  showGuidelines= false;

  constructor(
              private router: Router,
              private homeService: HomeService,
              private blockUIService: BlockUIService,
              private modal: NzModalService) { }

  ngOnInit() {
    this.role = localStorage.getItem('role');
    if(this.role === 'student') {
      this.getUploadedThesis();
    } else if (this.role === 'advisor' || this.role === 'dean_pgs') {
      this.getAdvisorThesis();
      this.getAdvisorPendingThesis();
    } else if (this.role === 'external_member') {
      this.getExternalThesis();
    }
  }

  ngOnChanges(changes: any): void {
  }

  showModal(value): void {
    this.isVisible = true;
    this.selectedThesis = value.thesis_name;
    this.approvedThesisID = value.thesis_id;
    this.approvedStudentEmail = value.student_email;
  }


  handleOk(): void {

    this.blockUIService.display(true);
    const handleApproveMetadata = {
      email: localStorage.getItem('email'),
      student_email: this.approvedStudentEmail,
      thesis_id: this.approvedThesisID,
      action: 'approved',
      comment: this.acceptReason ? this.acceptReason : ''
    };
      this.homeService.approveThesisByPG(handleApproveMetadata).subscribe(data => {        
        this.blockUIService.display(false);
        if (data.status_code === '01') {
          this.acceptReason = '';
          if (this.role === 'advisor' || this.role === 'dean_pgs') {
            this.getAdvisorThesis();
            this.getAdvisorPendingThesis();
          }          
          this.isVisible = false;
        }
      }, (err) => {
        this.blockUIService.display(false);
          this.ErrorMsg = 'Something went wrong. Please Try again';
      });
    
  }
  
  showRejectModal(value): void {
    this.isRejectVisible = true;
    this.selectedThesis = value.thesis_name;
    this.rejectedThesisID = value.thesis_id;
    this.rejectedStudentEmail = value.student_email;
  }


  handleRejectOk(): void {
    if(this.rejectReason === '' || this.rejectReason === undefined || this.rejectReason === null) {this.rejectReasonRequired = true; return}
    this.blockUIService.display(true);
    const handleApproveMetadata = {
      email: localStorage.getItem('email'),
      student_email: this.rejectedStudentEmail,
      thesis_id: this.rejectedThesisID,
      action: 'rejected',
      comment: this.rejectReason ? this.rejectReason : ''
    };
      this.homeService.approveThesisByPG(handleApproveMetadata).subscribe(data => {
        
        this.blockUIService.display(false);
        if (data.status_code === '01') {
          this.rejectReason = '';
          if (this.role === 'advisor' || this.role === 'dean_pgs') {
            this.getAdvisorThesis();
            this.getAdvisorPendingThesis();
          }          
          this.isRejectVisible = false;
        }
      }, (err) => {
        this.blockUIService.display(false);
          this.ErrorMsg = 'Something went wrong. Please Try again';
      });
  }

  refreshTable() {
    this.getAdvisorThesis();
    this.getAdvisorPendingThesis();
  }

  onKeypressEvent(event: any): void {
    this.rejectReasonRequired = false;
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  handleRejectCancel(): void {
    this.isRejectVisible = false;
  }

  filterKeyEvent(event) {
    var e =  event;  // get event object
    var key = e.keyCode // get key cross-browser
    if (((key>=33)
          &&(key<=44)||(key==94)||(key==61)||(key==91)||(key==92)||(key==93)||(key==47)||(key==123)
          ||(key==124)||(key==125)||(key==96)||(key==126)||(key>=58)&&(key<=63))) {
        if (e.preventDefault) e.preventDefault(); //normal browsers
        e.returnValue = false; //IE
    }
  }

  getUploadedThesis(): void {
    this.blockUIService.display(true);
    const getThesisMetadata = {email: localStorage.getItem('email')};
    if (getThesisMetadata.email) {
      this.homeService.getUploadedThesis(getThesisMetadata).subscribe(data => {        
        this.blockUIService.display(false);
        if (data.status_code === '01') {
          this.listOfData = data.thesis_list;
          
        } else if (data.message === 'Invalid Request') {
          this.router.navigate(['/']);
        } else {
          this.ErrorMsg = data.message;
          this.listOfData = [];
        }
      }, (err) => {
        this.blockUIService.display(false);
          this.ErrorMsg = 'Something went wrong. Please Try again';
          this.listOfData = [];
      });
    } else {
      this.ErrorMsg = 'Thesis Name is required.';
    }
  }

  getAdvisorThesis(): void {
    this.blockUIService.display(true);
    const getThesisMetadata = {email: localStorage.getItem('email')};
    if (getThesisMetadata.email) {
      this.homeService.getAdvisorThesisList(getThesisMetadata).subscribe(data => {        
        this.blockUIService.display(false);
        if (data.status_code === '01') {
          this.listOfinternalHome = data.thesis_list;
          this.listOfinternalHomeData = [...this.listOfinternalHome];  
          this.showAdvisorThesis = true;  
        } else if(data.message == 'Invalid Request') {
          this.router.navigate(['/']);
        } else {
          this.ErrorMsg = data.message;
          this.listOfinternalHome = [];
        }
      }, (err) => {
        this.blockUIService.display(false);
          this.ErrorMsg = 'Something went wrong. Please Try again';
          this.listOfinternalHome = [];
      });
    } else {
      this.ErrorMsg = 'Thesis Name is required.';
    }
  }

  getAdvisorPendingThesis(): void {
    this.blockUIService.display(true);
    const getThesisPendingMetadata = {email: localStorage.getItem('email')};
    if (getThesisPendingMetadata.email) {
      this.homeService.getAdvisorPendingThesisList(getThesisPendingMetadata).subscribe(data => {        
        this.blockUIService.display(false);
        if (data.status_code === '01') {
          this.listOfReviewPending = data.theses;
          this.listOfReviewPendingData = [...this.listOfReviewPending];  
          this.showpendingAdvisorThesis = true;        
        } else if(data.message == 'Invalid Request') {
          this.router.navigate(['/']);
        } else {
          this.ErrorMsg = data.message;
          this.listOfReviewPending = [];
        }
      }, (err) => {
        this.blockUIService.display(false);
          this.ErrorMsg = 'Something went wrong. Please Try again';
          this.listOfReviewPending = [];
      });
    } else {
      this.ErrorMsg = 'User email is required.';
    }
  }
  
  getExternalThesis(): void {
    this.blockUIService.display(true);
    const getThesisMetadata = {email: localStorage.getItem('email')};
    if (getThesisMetadata.email) {
      this.homeService.getAdvisorPendingThesisList(getThesisMetadata).subscribe(data => {
        this.blockUIService.display(false);
        if (data.status_code === '01') {
          this.listOfExternalHome = data.theses;
          this.listOfExternalHomeData = [...this.listOfExternalHome];
        } else if(data.message == 'Invalid Request') {
          this.router.navigate(['/']);
        } else {
          this.ErrorMsg = data.message;
          this.listOfExternalHome = [];
        }
      }, (err) => {
        this.blockUIService.display(false);
          this.ErrorMsg = 'Something went wrong. Please Try again';
          this.listOfExternalHome = [];
      });
    } else {
      this.ErrorMsg = 'Thesis Name is required.';
    }
  }


  handleSubmitAdvisorReview(data): void {
    this.blockUIService.display(true);
    const submitThesisReviewMetadata = {
                                email: localStorage.getItem('email'),
                                thesis_id: data.thesis_id,
                                advisor_type: data.advisor_type,
                              };
    if (submitThesisReviewMetadata.advisor_type) {
      this.homeService.submitAdvisorReview(submitThesisReviewMetadata).subscribe(data => {        
        this.blockUIService.display(false);
        if (data.status_code === '01') {
          if (this.role === 'advisor' || this.role === 'dean_pgs') {
            this.getAdvisorThesis();
            this.getAdvisorPendingThesis();
          } else if (this.role === 'external_member') {
            this.getExternalThesis();
          }
        }
      }, (err) => {
        this.blockUIService.display(false);
          this.ErrorMsg = 'Something went wrong. Please Try again';
      });
    }
  }

  onSelect(event) {
    if(this.files.length === 0) {
      this.files.push(...event.addedFiles);
    }    
  }

  onRemove(event) {
    
    this.files.splice(this.files.indexOf(event), 1);
  }

  viewDoc(): void {
    this.showDocViewer = true;
  }
  
  hideDocView(): void {
    this.showDocViewer = false;
  }

  confirmSubmit(data): void {
    this.modal.confirm({
      nzTitle: '<i>Are you sure you want to submit your review comments?</i>',
      nzContent: 'You wont be able to review "' + data.thesis_name +  '" after you submit.',
      nzOnOk: () => {
        this.handleSubmitAdvisorReview(data);
      }
    });
  }

  reset(): void {
    this.searchValue = '';
    this.searchFilterValue = '';
    this.searchInternalData();
    this.filterInternalData();
    this.filterPendingInternalData();
    this.filterExternalData();
  }

  searchInternalData(): void {
    this.searchVisible = false;
    this.listOfinternalHomeData = this.listOfinternalHome.filter((item: internalData) => item.student_name.indexOf(this.searchValue) !== -1);
  }

  filterInternalData(): void {
    this.listOfinternalHomeData = this.listOfinternalHome.filter((item: internalData) => {
      return item.student_name.toLowerCase().indexOf(this.searchFilterValue.toLowerCase()) !== -1 ||
        item.student_id.toString().toLowerCase().indexOf(this.searchFilterValue.toLowerCase()) !== -1 ||
        item.thesis_name.toLowerCase().indexOf(this.searchFilterValue.toLowerCase()) !== -1;
    });
    }
    filterPendingInternalData(): void {
    this.listOfReviewPendingData = this.listOfReviewPending.filter((item: internalPendingData) => {
      return item.student_name.toLowerCase().indexOf(this.searchFilterValue.toLowerCase()) !== -1 ||
        item.student_ref_no.toLowerCase().indexOf(this.searchFilterValue.toLowerCase()) !== -1 ||
        item.thesis_name.toLowerCase().indexOf(this.searchFilterValue.toLowerCase()) !== -1;
    });
    }

  filterExternalData(): void {
    this.listOfExternalHomeData = this.listOfExternalHome.filter((item: internalData) => {
      return item.student_name.toLowerCase().indexOf(this.searchFilterValue.toLowerCase()) !== -1 ||
        item.student_ref_no.toLowerCase().indexOf(this.searchFilterValue.toLowerCase()) !== -1 ||
        item.thesis_name.toLowerCase().indexOf(this.searchFilterValue.toLowerCase()) !== -1;
    });
    }

  afterCloseAlert(): void {
    
  }

  handleCancelUpload() {
    this.files = [];
  }

  handleUploadThesis(thesisTitle: string) {
    if (thesisTitle.length === 0) {
      this.titleError = true;
      this.titlePlaceholder = 'Thesis Title is required!';
      return;
    }

    const formData: FormData = new FormData()
    formData.append('file', this.files[0]);
    formData.append('email', localStorage.getItem('email'));
    formData.append('thesis_name', thesisTitle);
    formData.append('submission', 'submission_1');

    this.blockUIService.display(true);
    if (this.thesisTitle) {

      this.homeService.UploadThesis(formData).subscribe(data => {
        this.blockUIService.display(false);
        
        if (data.status_code === '01') {
          this.getUploadedThesis();
          this.files = [];
        } else if(data.status_code === '03') {
          this.failureMsg = 'Invalid file uploaded. Please upload only .docx files';
        } else {
          this.failureMsg = 'Something went wrong. Please try again..';
        }
      }, (err) => {
        this.blockUIService.display(false);
        this.failureMsg = 'Something went wrong. Please Try again';
      });
    } else {
      this.failureMsg = 'Thesis Name is required.';
    }
  }

  onKeypressTitleEvent(event: any): void {
    var e =  event;  // get event object
    var key = e.keyCode // get key cross-browser
    if (((key>=33)
          &&(key<=44)||(key==94)||(key==61)||(key==91)||(key==92)||(key==93)||(key==47)||(key==123)
          ||(key==124)||(key==125)||(key==96)||(key==126)||(key>=58)&&(key<=63))) {
        if (e.preventDefault) e.preventDefault(); //normal browsers
        e.returnValue = false; //IE
    }
    this.titleError = false;
    this.titlePlaceholder = 'Enter thesis title here';
  }


  docDetails(data) {
    this.router.navigate(['/document', { id: '3', thesis: data.thesis_id }]);    //pass id as '3' if role is student
  }

  viewThesis(data) {
    localStorage.setItem('routeRole', data.advisor_type)
    if (data.advisor_type === 'major_advisor') {
      this.router.navigate(['/document', { id: '1', thesis: data.thesis_id  }]); // pass id as '1' if role is major advisor
    } else if (data.advisor_type === 'internal_member') {
      this.router.navigate(['/view', { id: localStorage.getItem('email'), role: data.advisor_type, thesis: data.thesis_id, ver: data.version }]);

    } else if (data.advisor_type === 'pg_coordinator') {
      this.router.navigate(['/document', { id: '2', thesis: data.thesis_id  }]); // pass id as '2' if role is Pg coordinator
    } else if (data.advisor_type === 'hod') {
      this.router.navigate(['/view', { id: localStorage.getItem('email'), role: data.advisor_type, thesis: data.thesis_id, ver: data.version }]);
    } else if (data.advisor_type === 'dean') {
      this.router.navigate(['/view', { id: localStorage.getItem('email'), role: data.advisor_type, thesis: data.thesis_id, ver: data.version }]);
    } else if (data.advisor_type === 'external_member') {
      this.router.navigate(['/view', { id: localStorage.getItem('email'), role: data.advisor_type, thesis: data.thesis_id, ver: data.version }]);
    } else if (data.advisor_type === 'dean_pgs') {
      this.router.navigate(['/document', { id: '4', thesis: data.thesis_id  }]); // pass id as '1' if role is dean pgs
    }
  }
  viewDocument(data) {
    this.router.navigate(['/view', { id: localStorage.getItem('email'), role: data.advisor_type, thesis: data.thesis_id, ver: data.stage_level }]);
  }

  profileToggleDrawer(event) {
    
    this.profileDrawer = event;
  }

  selectFileUploadReviewDoc(data) {
    this.sessionEmail = localStorage.getItem('id');
	  this.selectedAdv_id = localStorage.getItem('id');
    // this.selectedAdv_id = data.adv_id;
    this.selectedReviewDoc_id = data.review_docid;
  this.reviewThesisfiles = [];
  this.ReviewFile.nativeElement.click()
  }

  onUploadReviewedThesis(event, review_docid, item) {
	
    const reviewedUploadfiles: { [key: string]: File } = this.ReviewFile.nativeElement.files;
    for (let key in reviewedUploadfiles) {
       if (!isNaN(parseInt(key))) {
         this.reviewThesisfiles.push(reviewedUploadfiles[key])
       }
     }
     if (item.stage_level == 1) {
        this.submission_type = 'submission_1';
     } else if (item.stage_level == 2) {
      this.submission_type = 'submission_2';
     } else if (item.stage_level == 3) {
      this.submission_type = 'submission_3';
     }
     this.uploadReviewDoc(this.submission_type, this.reviewThesisfiles[0].name, this.selectedAdv_id, this.selectedReviewDoc_id, item);
 
   }

   uploadReviewDoc(stage, docName, adv_id, review_docid, data): void {
		
		this.modalTitle = 'Are you sure you want to upload the reviewed document for the thesis?';
		this.confirmModal = this.modal.confirm({
		  nzTitle: this.modalTitle,
		  nzContent: 'Selected file: ' + docName,
		  nzOkText: 'Upload',
		  nzCancelText: 'Cancel',
		  nzOnCancel: () => {       
		  },
		  nzOnOk: () => {
			  
			const formData: FormData = new FormData()
			formData.append('file', this.reviewThesisfiles[0]);
			formData.append('submission', stage);
			formData.append('user_id', adv_id);
			formData.append('thesis_id', data.thesis_id);
			formData.append('email', localStorage.getItem('email'));
			if(review_docid) {
				formData.append('review_docid', review_docid);
			}
			this.blockUIService.display(true);
			if (data.thesis_id) {
			  this.homeService.UploadReviewedDoc(formData).subscribe(data => {
				this.blockUIService.display(false);              
				if (data.status_code === '01') {
					this.reviewThesisfiles = [];
				  this.getAdvisorThesis();
          this.getAdvisorPendingThesis();
				} else if(data.status_code === '03') {
          this.modal.warning({
            nzTitle: 'Invalid file type',
            nzContent: 'Only .docx files can be uploaded'
          });
        } 
        else {
				  this.failureMsg = data.message;
				}
			  }, (err) => {
				this.blockUIService.display(false);
				this.failureMsg = 'Something went wrong. Please Try again';
			  });
			} else {
			  this.failureMsg = 'Thesis is required.';
			}
	
	
		  
		  }
		});
    }
    


    markAsComplete(data): void {
      this.sessionEmail = localStorage.getItem('id');
	    this.selectedAdv_id = localStorage.getItem('id');
      if (data.stage_level == 1) {
        this.submission_type = 'submission_1';
     } else if (data.stage_level == 2) {
      this.submission_type = 'submission_2';
     } else if (data.stage_level == 3) {
      this.submission_type = 'submission_3';
     }
      this.modalTitle = 'Are you sure you want to submit your review and mark as complete?';
      this.confirmModal = this.modal.confirm({
        nzTitle: this.modalTitle,
        nzContent: 'Thesis title: ' + data.thesis_name,
        nzOkText: 'Mark as complete',
        nzCancelText: 'Cancel',
        nzOnCancel: () => {
               
        },
        nzOnOk: () => {
          
        this.blockUIService.display(true);
        const markCompleteMetadata = {
        email: localStorage.getItem('email'),
        thesis_id: data.thesis_id,
        user_type: data.advisor_type,
        user_id: this.selectedAdv_id,
        review_docid: data.review_docid,
        submission: this.submission_type,
        stage_type: data.stage_type
        };
        this.homeService.markCompleteDetails(markCompleteMetadata).subscribe(data => {
        
        this.blockUIService.display(false);
        if (data.status_code === '01') {
          this.getAdvisorThesis();
          this.getAdvisorPendingThesis();
          this.getExternalThesis();
        }
        if(data.status_code === '05') {

          this.confirmModal = this.modal.confirm({
            nzTitle: data.message,
            nzOkText: 'Ok',
            nzCancelText: 'Cancel',
            nzOnCancel: () => {
                   
            },
            nzOnOk: () => {
            
            }
          });

        }
        }, (err) => {
        this.blockUIService.display(false);
        });
        
        }
      });
      }

      
      answerQuestionnaire(info) {
        this.selecetedThesis = info.thesis_id;
        this.sessionEmail = localStorage.getItem('id');
        this.blockUIService.display(true);
        const getextquesMetadata = {
        email: localStorage.getItem('email'),
        thesis_id: info.thesis_id,
        user_id: localStorage.getItem('id')
        };
        this.homeService.callExternalGetQuestions(getextquesMetadata).subscribe(data => {
        this.blockUIService.display(false);
        if (data.status_code === '01') {
          this.questions_data = data.questions_data;
          this.isAnswerVisible = true;
          this.displayQuestions = true;
          
        }
        }, (err) => {
        this.blockUIService.display(false);
        });
      }


      handleAnswerOk(): void {

        this.blockUIService.display(true);
        const answerquesMetadata = {
        email: localStorage.getItem('email'),
        thesis_id: this.selecetedThesis,
        question_ans: this.questions_data
        };
        this.questions_data.forEach(element => {
          if(element.answer_text == '') {
            element.answer_text = null;
          }  
        });
        this.homeService.answerExternalQuestions(answerquesMetadata).subscribe(data => {
        this.blockUIService.display(false);
        if (data.status_code === '01') {
          this.isAnswerVisible = false;
          this.getAdvisorPendingThesis();
        }
        }, (err) => {
        this.blockUIService.display(false);
        });
        
      }
    
      handleAnswerCancel(): void {
        this.isAnswerVisible = false;
      }

      printAnswers(info) {
        this.selecetedThesis = info.thesis_id;
        this.sessionEmail = localStorage.getItem('id');
        this.blockUIService.display(true);
        const getextquesMetadata = {
        email: localStorage.getItem('email'),
        thesis_id: info.thesis_id,
        user_id: localStorage.getItem('id')
        };
        this.homeService.callExternalGetQuestions(getextquesMetadata).subscribe(data => {
        this.blockUIService.display(false);
        if (data.status_code === '01') {
          this.print_questions_data = data.questions_data;
          this.displayPrintQuestions = true;
          if (this.print_questions_data) {
            
            var styles = `<style>
                        .wrapper {
                            font-family: sans-serif;
                            padding: 0 20px;
                        }
                        h3 {
                            padding-bottom: 9px;
                            border-bottom: 1px solid #c5c5c5;
                        }
                        .names {
                            font-weight: bold;
                            margin-bottom: 10px;
                        }
                        .names span {
                            font-weight: normal;
                        }
                        .qa-wrapper {
                            padding-top: 25px;
                            border-top: 1px solid #c5c5c5;
                            margin-top: 15px;
                        }
                        .each-qa {
                            margin-bottom: 25px;
                        }
                        .q {
                            font-weight: bold;
                        }
                        .q span {
                            display: inline-block;
                            width: 15px;
                        }
                        .a {
                            margin-left: 17px;
                            margin-top: 7px;
                        }
                    </style>`;
            var qa_json = this.print_questions_data;
            var student_name = info.student_name;
            var Student_ID = info.student_ref_no;
            var thesis_name = info.thesis_name;
            var thesisInfo = `
                    <div class="names">
                        Student Name: <span>`+student_name+`</span>
                    </div>
                    <div class="names">
                        Thesis Name: <span>`+thesis_name+`</span>
                    </div>
                    <div class="names">
                        Student ID: <span>`+Student_ID+`</span>
                    </div>
            `
            generatePrintElement();
            function generatePrintElement() {
                let wrapper = document.createElement("div");
                wrapper.innerHTML = styles;
                wrapper.innerHTML += `<h3>Thesis Evaluation System</h3>`;
                wrapper.innerHTML += thesisInfo;

                let qaWrapper = document.createElement("div");
                qaWrapper.classList.add("qa-wrapper");

                qa_json.forEach((item, id) => {
                    let question = item['question_text'];
                    let answer = item['answer_text'];
                    let eachQA = `
                        <div class="each-qa">
                            <div class="q">
                                <span>`+(id+1).toString()+`.</span>`+question+`
                            </div>
                            <div class="a">
                                `+answer+`
                            </div>
                        </div>
                    `
                    qaWrapper.innerHTML += eachQA;
                })
                wrapper.appendChild(qaWrapper);
                printPage(wrapper);
            }

            function printPage(printContent) {
                const WindowPrt = window.open(
                "",
                "",
                "left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0"
                );
                WindowPrt.document.write(printContent.innerHTML);
                WindowPrt.document.close();
                WindowPrt.focus();
                WindowPrt.print();
            }

          }



          
        }
        }, (err) => {
        this.blockUIService.display(false);
        });
      }

      onCloseModal() {
        this.showGuidelines = false;
      }

      displayGuidelines() {
        this.showGuidelines = true;
      }

      downloadFiles(report_url: string, thesisName: string) {
        var element = document.createElement('a');
        element.setAttribute('href', report_url);
        element.setAttribute('download', thesisName);
        element.setAttribute('target', '_self');
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element)
        }






}
interface Person {
  thesis_id: string;
  thesis_name: string;
  uploaded_datetime: string;
  review_status: string;
}
interface Student {
  key: string;
  name: string;
  email: string;
  reference: string;
}

interface internalData {
  thesis_id: string;
  student_name: string;
  student_ref_no: string;
  student_id: string;
  thesis_name: string;
  created_datetime: string;
  advisor_type: string;
  total_comments: string;
  accepted_comments: string;
  rejected_comments: string;
  review_status: string;
}
interface internalPendingData {
  thesis_id: string;
  student_name: string;
  student_ref_no: string;
  thesis_name: string;
  start_datetime: string;
  stage_type: string;
  stage_level: string;
  review_docid: string;
  download_url: string;
  advisor_type: string;
  review_status: string;
}
