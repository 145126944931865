import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Roles, RoutePaths } from '../constants/constants';
import { User } from '../../modules/login/model/login.model';
import { ServiceHandlerService } from '../services/service-handler.service';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class UserProfileService {
  constructor(private serviceHandlerService: ServiceHandlerService, private router: Router) {}

  user: User;
  userFound = false;
  footSubject: Subject<User> = new BehaviorSubject<User>(null);

  // getUserProfile(): Observable<User> {
  //   return this.serviceHandlerService
  //     .get<User>('/profile')
  //     .pipe(
  //       map((res: User) => {
  //         if (res) {
  //           this.footSubject.next(res);
  //         } else {
  //           this.router.navigate(['/']);
  //         }
  //         return res;
  //       })
  //     );
  // }
  getUserProfile(body): Observable<User> {
    
    return this.serviceHandlerService
      .post<User>('/profile', body)
      .pipe(
        map((res: User) => {
          if (res) {
            this.footSubject.next(res);
          } else {
            this.router.navigate(['/']);
          }
          return res;
        })
      );
  }
}
