import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, RoutesRecognized } from '@angular/router';
import { HomeService } from '../service/home.service';
import { BlockUIService } from 'src/app/shared/services/block-ui.service';
import 'rxjs/add/operator/filter';
import * as Popper from 'popper.js/dist/umd/popper.js';
import * as $ from 'jquery';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-view-document',
  templateUrl: './view-doc.component.html',
  styleUrls: ['./view-doc.component.scss']
})
export class ViewDocumentComponent implements OnInit {
  pdfSrc: string;
  replyComment: string;
  userEmail: string;
  userRole: string;
  ThesisTitleState: boolean = true;
  ThesisSubmitState: boolean;
  files: File[] = [];
  addComment: string;
  questionAnswer: string = '';
  questionOne: string = 'It is recommended that all students make an appointment for both a dissertation format check and submission.';
  showReply: boolean = false;
  showStudentReply: boolean = false;

  rect: Rectangle = { x1: 0, y1: 0, x2: 0, y2: 0, width: 0, height: 0, page_no: null };
  lastMousePosition: Position = { x: 0, y: 0 };
  canvasPosition: Position = { x: 0, y: 0 };
  mousePosition: Position = { x: 0, y: 0 };
  mouseDownFlag: boolean = false;
  pagePosition: Position = { x: 0, y: 0 };

  cnv;
  pdfBody;
  ctx;
  element = null;
  dataPageNumber: number;

  areaInfo: AreaInfo[] = [];
  studentAreaInfo: AreaInfo[] = [];
  role: string;
  isStudent = false;
  isAdvisor = false;
  pageVariable: any;
  questionsList: { question_id: any, question_text: string, show?: boolean, showReply?: boolean, answer_text?: string, answer_filler?: string }[];
  questionsListForAdvisors: { question_id: any, question_text: string, show?: boolean, showReply?: boolean, answer_text?: string, answer_filler?: string }[];
  path: any[];
  isMandatoryReply: boolean = false;
  isExternalAdvisor: boolean = false;
  showHelpCard = true;
  isPgCoordinator = false;
  isHod = false;
  isDean = false;
  showPdf = false;
  thesisId: any;
  thesisName: any;
  StudentName: any;
  StudentID: any;
  getQuestionMetaData: { email: string; thesis_id: any; };
  saveAnswerMetaData: { email: string; answer: any; thesis_id: any; question_id: any; };
  AddCommentMetaData: { thesis_id: any; thread_id: any; isEdit: any; comment_id: any; action: string; version: number; comment: string; email: string; rect: Rectangle; };
  currentComment: any;
  thesisVersion: any;
  thesisCurrentStatus: any;
  thesisAdvisorType: any;
  thesisAdvisorEmail: any;
  tokenEmail: string;
  getThesisObj: { thesis_id: any; version: any; };
  encodedthesis: string;
  previousURL: string;
  showBreadcrumb: boolean;
  isRejectEdited: boolean;
  isAcceptEdited: boolean;
  rectElem: AreaInfo[] = [];
  routeRole: string;
  acceptCommentID: any;
  rejectCommentID: any;
  getAdvisorsQuestionMetaData: { email: string; external_email: string; thesis_id: any; };
  externalName: string;
  canComment: any;
  isDeanPgs = false;

  constructor(private router: Router,
              private modal: NzModalService,
              private homeService: HomeService,
              private blockUIService: BlockUIService,
              private route: ActivatedRoute) {

    this.areaInfo = [];
    this.studentAreaInfo = [];
   }

  // tslint:disable-next-line: typedef
  ngOnInit() {
    this.addComment = '';
    this.tokenEmail = localStorage.getItem('email');
    this.routeRole = localStorage.getItem('routeRole');
    
    this.route.params.subscribe(params => {
      
      if (params.id) {
        this.userEmail = params.id;
      }
      if (params.role) {
        this.userRole = params.role;
      }
      if (params.thesis) {
        this.thesisId = params.thesis;
      }
      if (params.ver) {
        this.thesisVersion = params.ver;
      }
      this.getThesisObj = { thesis_id: this.thesisId, version: this.thesisVersion };
      this.encodedthesis = btoa(JSON.stringify(this.getThesisObj));
      this.getThesis();
      this.getComments();
      this.getThesisInfo();
      this.handleCanComment();
    });
    if(this.userRole === 'external_member') {
      this.externalName= localStorage.getItem('externalName');
      // this.getQuestionsForAdvisors();
    }
    this.role = localStorage.getItem('role');
    if (this.role === 'student') {
      this.showBreadcrumb = true;
    }
    if (this.role === 'advisor') {
      if (this.routeRole === 'major_advisor' || this.routeRole === 'pg_coordinator') {
        this.showBreadcrumb = true;
      } else {
        this.showBreadcrumb = false;
      }
    }

    if (this.role === 'advisor' || this.role === 'hod') {
      this.isStudent = false;
      this.isPgCoordinator = false;
      this.isHod = false;
      this.isDean = false;
      this.isAdvisor = true;
      this.ThesisSubmitState = true;
    } else if(this.role === 'student') {
      this.isAdvisor = false;
      this.isPgCoordinator = false;
      this.isHod = false;
      this.isDean = false;
      this.isStudent = true;
      this.ThesisSubmitState = false;
    } else if (this.role === 'external_member') {
      this.isStudent = false;
      this.isPgCoordinator = false;
      this.isAdvisor = false;
      this.isExternalAdvisor = true;
      this.isHod = false;
      this.isDean = false;
      this.ThesisSubmitState = true;
      this.handleGetQuestion();
    } else if (this.role === 'dean_pgs') {
      this.isStudent = false;
      this.isPgCoordinator = false;
      this.isAdvisor = false;
      this.isExternalAdvisor = false;
      this.isHod = false;
      this.isDean = false;
      this.isDeanPgs = true;
      this.ThesisSubmitState = false;
    }
  }

  routeToHome(): void {
    this.router.navigate(['/home']);
  }

  routeToDocDetails(): void {
    if (localStorage.getItem('role') === 'student') {
      this.router.navigate(['/document', { id: '3', thesis: this.thesisId }]);
    } else if(this.routeRole === 'major_advisor') {
      this.router.navigate(['/document', { id: '1', thesis: this.thesisId }]);
    } else if(this.routeRole === 'pg_coordinator') {
      this.router.navigate(['/document', { id: '2', thesis: this.thesisId }]);
    }
  }

  handleGetQuestion(): void {
    this.getQuestionMetaData = {
      email: localStorage.getItem('email'),
      thesis_id: this.thesisId
    };
    this.homeService.callGetExternalQuestions(this.getQuestionMetaData).subscribe(data => {
      
      if (data.status_code !== '01') {
        alert('error');
        this.blockUIService.display(false);
      }
      if (data.status_code === '01') {
        this.questionsList = data.questions_data;
      }
    }, (err) => {
      this.blockUIService.display(false);
      alert('error');
    });
  }

  getQuestionsForAdvisors(): void {
    this.getAdvisorsQuestionMetaData = {
      email: localStorage.getItem('email'),
      external_email: this.userEmail,
      thesis_id: this.thesisId
    };
    this.homeService.callGetExternalQuestions(this.getAdvisorsQuestionMetaData).subscribe(data => {
      
      if (data.status_code !== '01') {
        alert('error');
        this.blockUIService.display(false);
      }
      if (data.status_code === '01') {
        this.questionsListForAdvisors = data.questions;
      }
    }, (err) => {
      this.blockUIService.display(false);
      alert('error');
    });
  }

  saveAnswer(datavalue): void {
    if (datavalue.answer_filler === '') {return alert('answer cannot be empty')}
    this.saveAnswerMetaData = {
      email: localStorage.getItem('email'),
      answer: datavalue.answer_filler,
      thesis_id: this.thesisId,
      question_id: datavalue.question_id
    };
    this.homeService.callsaveAnswer(this.saveAnswerMetaData).subscribe(data => {
      
      if (data.status_code !== '01') {
        alert('error');
        this.blockUIService.display(false);
      }
      if (data.status_code === '01') {
        this.handleGetQuestion();
        datavalue.showReply = false;
      }
    }, (err) => {
      this.blockUIService.display(false);
      alert('error');
    });
  }

  
  getThesis(): void {
    this.blockUIService.display(true);
    this.pdfSrc = window.origin + '/api/get_thesis?id=' + this.encodedthesis;
    
    setTimeout(() => {
      this.showPdf = true;
      this.blockUIService.display(false);
    }, 600);
    this.blockUIService.display(true);
    const selectedThesisId = { thesis_id: this.thesisId}
    this.homeService.getThesisDetails(selectedThesisId).subscribe(data => {
      
      this.blockUIService.display(false);
      if (data.status_code === '01') {
        this.thesisName = data.thesis_data.thesis_name;
        this.StudentName = data.thesis_data.name;
        this.StudentID = data.thesis_data.ref_no;
          
        }
      }, (err) => {
        this.blockUIService.display(false);
      });
    
  }

  getComments(): void {
    this.blockUIService.display(true);
    const getCommentMetadata = {
                                email: localStorage.getItem('email'),
                                thesis_id: this.thesisId,
                                version: this.thesisVersion, 
                                advisor_email: this.userEmail,
                                role: this.userRole,
                              };
    this.homeService.getCommentsDetails(getCommentMetadata).subscribe(data => {
      this.blockUIService.display(false);
      if (data.status_code === '01') {
        if (this.role === 'advisor' || this.role === 'external_member' || this.role === 'dean_pgs' || this.role === 'hod') {
          this.areaInfo = data.comments_data;
        } else {
          this.studentAreaInfo = data.comments_data;
          this.studentAreaInfo.forEach(element => {
            element.rect.x1 = parseFloat(element.rect.x1);
            element.rect.x2 = parseFloat(element.rect.x2);
            element.rect.y1 = parseFloat(element.rect.y1);
            element.rect.y2 = parseFloat(element.rect.y2);
          });
          
        }
        }
      }, (err) => {
        this.blockUIService.display(false);
      });
    
  }

  getThesisInfo(): void {
    this.blockUIService.display(true);
    const getThesisInfoMetadata = {
                                email: localStorage.getItem('email'),
                                thesis_id: this.thesisId,
                                version: this.thesisVersion,
                                advisor_email: this.userEmail,
                              };
    this.homeService.handleThesisInfo(getThesisInfoMetadata).subscribe(data => {
      this.blockUIService.display(false);
      if (data.status_code === '01') {
        this.thesisCurrentStatus = data.thesis_info.status;
        this.thesisAdvisorType = 'external_member';
        // this.thesisAdvisorType = data.thesis_info.advisor_type;
        this.thesisAdvisorEmail = data.thesis_info.email;
        }
      }, (err) => {
        this.blockUIService.display(false);
      });
  }

  handleCanComment(): void {
    this.blockUIService.display(true);
    const canCommentMetadata = {
      email: localStorage.getItem('email'),
      thesis_id: this.thesisId,
      advisor_type: this.userRole
    };
    this.homeService.canUserComment(canCommentMetadata).subscribe(data => {
      this.blockUIService.display(false);
      if (data.status_code === '01') {
        this.canComment= data.can_comment;
        }
      }, (err) => {
        this.blockUIService.display(false);
      });
  }

  onSelect(event) {
    if (this.files.length === 0) {
      this.files.push(...event.addedFiles);
    }
  }

  ShowHelp():void {
    this.showHelpCard = this.showHelpCard ? false : true;
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  activateCard(): void {
  }
  activateQuestionCard(value): void {
    this.questionsList.forEach(elements => {
      if (elements.question_id === value.question_id) {
        value.showReply = true;
      } else {
        elements.showReply = false;
      }
    });
  }

  activeStudentReply(value): void {
    this.studentAreaInfo.forEach(elements => {
      if (elements.comments[0].rectangleId === value.comments[0].rectangleId) {
        value.markReply = true;
      } else {
        elements.markReply = false;
      }
    });
  }

  hideQuestionCard(value): void {
    value.showReply = false;
  }

  hideStudentReply(value): void {
    // 
    if (value.reply === '' || value.reply === null || value.reply === undefined) {
      value.isMandatoryReply = true;
      value.markReply = true;
      value.commentReplyHolder = 'Comment is required*'
    } else {
      value.markReply = false;
      value.selectedChip = 'Rejected';
    }
  }

  onKeypressEvent(event: any, value: any): void {
    value.comments[0].mandatoryCommentSuccess = true;
  }

  acceptStudentReply(value, editValue): void {
    if (value.comments.length === 1) {
      this.currentComment = value.comments[0].reply;
    } else {
      this.currentComment = value.comments[1].comment;
    }
    if(editValue === 'initial') {
      this.isAcceptEdited = false;
      this.acceptCommentID = value.comments[0].comment_id;
    }  else {
      this.isAcceptEdited = true;
      this.acceptCommentID = value.comments[1].comment_id;
    }
    if(this.currentComment === undefined || this.currentComment === null) {
      this.currentComment = '';
    }
    this.AddCommentMetaData = {
      thesis_id: this.thesisId,
      thread_id: value.comments[0].comment_ct_id,
      isEdit: this.isAcceptEdited,
      comment_id: this.acceptCommentID,
      action: 'accepted',
      version: this.thesisVersion,
      comment: this.currentComment,
      email: localStorage.getItem('email'),
      rect: this.rect
    };
    this.homeService.callAddComment(this.AddCommentMetaData).subscribe(data => {
      if (data.status_code !== '01') {
        this.blockUIService.display(false);
      }
      if (data.status_code === '01') {
        this.getComments();
      }
    }, (err) => {
      this.blockUIService.display(false);
      alert('error');
    });
  }

  rejectStudentReply(value, editValue): void {
    if (value.comments.length === 1) {
      this.currentComment = value.comments[0].reply;
    } else {
      this.currentComment = value.comments[1].comment;
    }
    if(editValue === 'initial') {
      this.isRejectEdited = false;
      this.rejectCommentID = value.comments[0].comment_id;
    }  else {
      this.isRejectEdited = true;
      this.rejectCommentID = value.comments[1].comment_id;
    }
    if(this.currentComment === undefined || this.currentComment === null || this.currentComment === '') {
      value.comments[0].mandatoryComment = true;
      return;
    }
    this.AddCommentMetaData = {
      thesis_id: this.thesisId,
      thread_id: value.comments[0].comment_ct_id,
      isEdit: this.isRejectEdited,
      comment_id: this.rejectCommentID,
      action: 'rejected',
      version: this.thesisVersion,
      comment: this.currentComment,
      email: localStorage.getItem('email'),
      rect: this.rect
    };
    
    this.homeService.callAddComment(this.AddCommentMetaData).subscribe(data => {
      if (data.status_code !== '01') {
        this.blockUIService.display(false);
      }
      if (data.status_code === '01') {
        this.getComments();
      }
    }, (err) => {
      this.blockUIService.display(false);
      alert('error');
    });
  }

  callBackFn(pdf: any) {
    // do anything with "pdf"
    // 
 }

 onError(error: any) {
  // do anything
  
}

textLayerRendered(e: CustomEvent) {
  // 
}


  mouseEvent(event) {
    
    if ((this.userEmail === localStorage.getItem('email'))) {

      if (!this.showPopup) {
        console.log('event.type:', event.type)
  
        if (event.type === 'mousemove') {
          this.mousePosition = {
            x: event.clientX - this.pagePosition.x,
            y: event.clientY - this.pagePosition.y
          };
  
          if (this.mouseDownFlag) {
            let width = this.mousePosition.x - this.lastMousePosition.x;
            let height = this.mousePosition.y - this.lastMousePosition.y;
            this.rect = {
              x1: this.lastMousePosition.x,
              y1: this.lastMousePosition.y,
              x2: this.mousePosition.x,
              y2: this.mousePosition.y,
              width: width,
              height: height,
              page_no: this.dataPageNumber

            };
  
            if (this.element != null) {
              // 
              this.element.style.width = width + 'px';
              this.element.style.height = height + 'px';
              if (this.rect.width > 0 && this.rect.height > 0) {
                this.path[0].appendChild(this.element);
              }
            }
          }
        }
  
        if (event.type === 'mousedown') {
          this.mouseDownFlag = true;
          this.path = this.composedPath(event.target);
          let eventPath = this.path.find(p => p.className == 'page');
          console.log('eventPath:', eventPath)
  
          if (typeof eventPath !== 'undefined') {
            this.dataPageNumber = parseInt(eventPath.getAttribute('data-page-number')); // get id of page
            let toDrawRectangle = document.getElementsByClassName('to-draw-rectangle');
            let pageOffset = this.path[0].getBoundingClientRect();
            this.pagePosition = {
              x: pageOffset.left,
              y: pageOffset.top
            };
  
            this.lastMousePosition = {
              x: event.clientX - this.pagePosition.x,
              y: event.clientY - this.pagePosition.y
            }
  
            let rectId = document.getElementsByClassName('rectangle').length + 1;
            this.element = document.createElement('div');
            this.element.className = 'rectangle';
            this.element.id = 'rectangle-' + rectId;
            this.element.style.position = 'absolute';
            this.element.style.border = '2px solid #0084FF';
            this.element.style.borderRadius = '3px';
            this.element.style.left = this.lastMousePosition.x + 'px';
            this.element.style.top = this.lastMousePosition.y + 'px';
          }
        }
  
        if (event.type === 'mouseup') {
          this.mouseDownFlag = false;
          if ((this.userRole === this.thesisAdvisorType &&
            this.userEmail === localStorage.getItem('email') &&
            this.thesisCurrentStatus !== 'submitted')
           ) {  
            if (this.rect.height > 0 && this.rect.width > 0) {
              let popper = document.querySelector('.js-popper');
              new Popper(this.element, popper, {
                placement: 'top-end'
              });
              this.showPopup = true;
            }
          }
        }
      }
    }
  }

   // added new div with rects when pages rendered
   indexOfPage: number;
  pageRendered(event) {
      this.indexOfPage = event.pageNumber;
      let elem = document.createElement('div');
      elem.className = 'to-draw-rectangle';
      elem.style.position = 'absolute';
      elem.style.left = 0 + 'px';
      elem.style.top = 0 + 'px';
      elem.style.right = 0 + 'px';
      elem.style.bottom = 0 + 'px';
      
    elem.style.cursor = ((this.userRole === this.thesisAdvisorType &&
      this.userEmail === localStorage.getItem('email') &&
      this.thesisCurrentStatus !== 'submitted')) ? 'crosshair' : 'default';
      // elem.style.background = 'red';
      // elem.style.opacity = '0.4';
      let path = this.composedPath(event.source.div);
      path.find(p => p.className == 'page').appendChild(elem);
      $('.textLayer').addClass('disable-textLayer');
      if(this.role === 'student') {
        this.studentAreaInfo.forEach(element => {
          this.rectElem = this.studentAreaInfo.filter(f => f.pageNumber == this.indexOfPage);
        })
       } else if ((this.userRole === this.thesisAdvisorType &&
        this.userEmail === localStorage.getItem('email'))) {
            this.areaInfo.forEach(element => {
              this.rectElem = this.areaInfo.filter(f => f.pageNumber == this.indexOfPage);
            })
       } else {
            this.areaInfo.forEach(element => {
            this.rectElem = this.areaInfo.filter(f => f.pageNumber == this.indexOfPage);
            })
       }
     
     this.rectElem.forEach(o => {
       if (typeof o !== 'undefined') {
         let rectId = document.getElementsByClassName('rectangle').length + 1;
         let rect = document.createElement('div');
         rect.className = 'rectangle';
         rect.id = o.comments[0].rectangleId;  
         rect.style.position = 'absolute';
         rect.style.border = '2px solid #0084FF';
         rect.style.borderRadius = '3px';
         rect.style.left = parseFloat(o.rect.x1) + 'px';
         rect.style.top = parseFloat(o.rect.y1) + 'px';
         rect.style.width = o.rect.width + 'px';
         rect.style.height = o.rect.height + 'px';
         path.find(p => p.className == 'page').children[2].appendChild(rect);
  
       }
     })
   }
 
   composedPath(el) {
     let path = [];
     while (el) {
       path.push(el);
       if (el.tagName === 'HTML') {
         path.push(document);
         path.push(window);
         return path;
       }
       el = el.parentElement;
     }
   }
 
   showPopup: boolean = false;
   getStyle() {
    // alert(this.showPopup)
     if (this.showPopup) {
       return "block";
     } else {
       return "none";
     }
   }
 
   save() {
     this.AddCommentMetaData = {
       thesis_id: this.thesisId,
       comment_id: null,
       thread_id: null,
       action: 'initial',
       version: this.thesisVersion,
       isEdit: false,
       comment: this.addComment,
       email: localStorage.getItem('email'),
       rect: this.rect
     };
     this.homeService.callAddComment(this.AddCommentMetaData).subscribe(data => {
       if (data.status_code !== '01') {
         alert('error');
         this.blockUIService.display(false);
       }
       if (data.status_code === '01') {
         this.showPopup = false;
         this.rect = { x1: 0, y1: 0, x2: 0, y2: 0, width: 0, height: 0, page_no: null };
         this.addComment = '';
         this.getComments();
       }
     }, (err) => {
       this.blockUIService.display(false);
       alert('error');
     });
   }
 
   cancel() {
     let rectId = this.element.getAttribute('id');
     $('#' + rectId).remove();
     this.showPopup = false;
     this.rect = { x1: 0, y1: 0, x2: 0, y2: 0, width: 0, height: 0, page_no: null };
     this.addComment = '';
   }
 
   listRectangleId: string = '';
   moveTo(list: AreaInfo) {
     console.log('move to list:', list)
     console.log('listRectangleId:', this.listRectangleId)
     
     this.pageVariable = list.pageNumber;
     setTimeout(() => {
      if (this.listRectangleId != '') {
        if (document.getElementById(this.listRectangleId)) {
          document.getElementById(this.listRectangleId).style.background = 'transparent';
          document.getElementById(this.listRectangleId).style.opacity = '1';
        }
      }
      
       if (this.listRectangleId !== list.comments[0].rectangleId) {
         document.getElementById(list.comments[0].rectangleId).scrollIntoView({ block: 'start', behavior: 'smooth' });
         document.getElementById(list.comments[0].rectangleId).style.background = 'yellow';
         document.getElementById(list.comments[0].rectangleId).style.opacity = '0.4';
         this.listRectangleId = list.comments[0].rectangleId;
      }
    }, 900);
   }
}


interface Position {
  x: number;
  y: number;
}

interface Rectangle {
  x1: any;
  y1: any;
  x2: any;
  y2: any;
  width: number;
  height: number;
  page_no: number;
  // comment: string;
}

interface AreaInfo {
  rect: Rectangle;
  pageNumber: any;
  isDelete?: boolean;
  comment?: string;
  comments?: any;
  markReply?: any;
  reply?: string;
  isMandatoryReply?: boolean;
  commentReply?: string;
  commentReplyHolder?: string;
  selectedChip?: string;
  status?: string;
  mandatoryComment?: any;
  mandatoryCommentSuccess?: any;
}

interface AreaInfoStudent {
  // rectangleId: string;
  pageNumber: number;
  rect: Rectangle;
  isDelete?: boolean;
  comment?: string;
  comments?: any;
  markReply?: any;
  reply?: string;
  isMandatoryReply?: boolean;
  commentReply?: string;
  commentReplyHolder?: string;
  selectedChip?: string;
  status?: string;
  mandatoryComment?: any;
  mandatoryCommentSuccess?: any;
}
