import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ResetPasswordService } from '../service/reset-password.service';
import { ResetPasswordModel } from '../model/reset-password.model';
import { Messages, RouteUrls } from 'src/app/shared/constants';
import { BlockUIService } from 'src/app/shared/services/block-ui.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  messages: any;
  errmessage: string;
  successmessage: string;
  resetPasswordForm: FormGroup;
  status: boolean;
  showResetSuccessModal: boolean;
  passwordResponseMessage: string;

  email: any;
  idObj: any;
  allowResetPassword = false;
  resetPassBody: { auth: any; email: any; password: any; };

  // @Input() password: any;

  constructor(private fb: FormBuilder,
              private resetPasswordService: ResetPasswordService,
              private router: Router,
              private route: ActivatedRoute,
              private blockUIService: BlockUIService,
              private sharedService: SharedService) { }

  ngOnInit() {

    this.messages = Messages;
    this.route.params.subscribe( params => {
      if(params.id) {
        this.idObj = { params };
        this.getTransactionType();
      }
    });
    this.createForm();
  }

  createForm() {
    this.resetPasswordForm = this.fb.group({
      password: [
        null,
        Validators.compose([
          Validators.required,          
          Validators.maxLength(100)
        ])
      ],
      confirmPassword: [null, Validators.compose([Validators.required])]
    }, {
      // validator: CustomValidators.passwordMatchValidator
    });
  }

  filterKeyEvent(event) {
    var e =  event;  // get event object
    var key = e.keyCode // get key cross-browser
    if (((key>=33)
          &&(key<=44)||(key==94)||(key==61)||(key==91)||(key==92)||(key==93)||(key==47)||(key==123)
          ||(key==124)||(key==125)||(key==96)||(key==126)||(key>=58)&&(key<=63))) {
        if (e.preventDefault) e.preventDefault(); //normal browsers
        e.returnValue = false; //IE
    }
  }

  get f() { return this.resetPasswordForm.controls; }

  getTransactionType() {

    this.blockUIService.display(true);
    const externalLandingModel: ResetPasswordModel = { ...this.idObj.params };
    if (externalLandingModel) {
      this.resetPasswordService.default(externalLandingModel).subscribe(data => {
        this.blockUIService.display(false);
        
        if ( data.status_code === '01') {
          this.email = data.email;
          this.allowResetPassword = true;
        }
      }, (err) => {
        this.blockUIService.display(false);
        this.errmessage = this.messages.PASSWORDRESETERROR;
        alert('error occured.');
      });
    } else {
      this.errmessage = this.messages.PASSWORDRESETERROR;
    }
  }

  resetPassword() {
    if (this.resetPasswordForm.get('password').errors) {
       this.errmessage = 'Need a password'; return;
      }
    if (this.resetPasswordForm.get('confirmPassword').errors) {
      this.errmessage = 'Need a confirm password'; return;
    }
    if (this.resetPasswordForm.get('password').value !== this.resetPasswordForm.get('confirmPassword').value) {
      this.errmessage = 'Passwords should be same';
      return;
    }
    if(/((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,30})/.test(this.resetPasswordForm.get('password').value) == false) {
      this.errmessage = 'Password should contain atleast one uppercase character, one lower case character, one number and atleast 8 characters long.';
      return;
      }

    this.blockUIService.display(true);
    const resetPasswordModel = { ...this.resetPasswordForm.value };

    if (this.email && resetPasswordModel.password) {
      this.resetPassBody = {auth: this.idObj.params.id, email: this.email, password: resetPasswordModel.password}
      
      this.resetPasswordService.resetPassword(this.resetPassBody).subscribe(data => {
        this.blockUIService.display(true);
        
        if (data.status === 'success') {
          this.passwordResponseMessage = this.messages.RESERPASSWORDSUCCESS;
          this.errmessage = '';
          this.resetPasswordForm.reset();
          this.router.navigate([RouteUrls.LOGIN]);
        }
        if (data.responseType === 'Error message') {
          this.passwordResponseMessage = this.messages.RESETPASSWORDERROR;
          this.resetPasswordForm.reset();
        }
      }, (err) => {
        alert('reset pass api err');
        this.blockUIService.display(false);
        this.passwordResponseMessage = this.messages.RESETPASSWORDERROR;
      });
    } else {
      alert('reset pass error');
      this.passwordResponseMessage = this.messages.RESETPASSWORDERROR;
    }

  }
  closeResetSuccessModal() {
    this.showResetSuccessModal = false;
  }

  openResetSuccessModal() {
    this.showResetSuccessModal = true;
  }

}
