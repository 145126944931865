import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  // { path: '', loadChildren: () => import(`./modules/login/login.module`).then(m => m.LoginModule)},
  { path: '', loadChildren: './modules/login/login.module#LoginModule'},
  { path: 'load-me', loadChildren: () => import(`./modules/test/test.module`).then(m => m.TestModule)}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
