<app-header></app-header>
  <div class="container">

    <div nz-row>
      <!-- <div nz-col nzSpan="12">
        <div class="message">
          Message from the VC to the students, internal members and external members about the Thesis Evaluation System, 
          why this has been put together, how it will help in contactless evaluation of project reports/theses.
        </div>
      </div> -->
      <div class="signInForm" nz-col nzSpan="24">
     
        <div class="form-container">
          <div class="form-header">
            Reset Password
          </div>
          <div class="form-content login" *ngIf="allowResetPassword">
            <form role="form" method="POST" [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()">
              <input class="input-field"  (keypress)="filterKeyEvent($event)" autocomplete="no-autoresetpass" 
              id="password"  type="password"  formControlName="password" placeholder="New Password" required>
              <br />
        
              <input class="input-field" type="password" id="confirmpassword" formControlName="confirmPassword" placeholder="Confirm Password" required
              >
              <div *ngIf="errmessage">
                <div class="invalid-password-text">{{errmessage}}</div>
              </div> 
              <mat-grid-list cols="2">
                <mat-grid-tile class="grid-title">
                  <button class="green login-btn" type="submit">Submit</button>
                </mat-grid-tile>
                <mat-grid-tile class="grid-title">
                </mat-grid-tile>
              </mat-grid-list>          
            </form>
          </div>
          <div class="form-content login" *ngIf="!allowResetPassword">
            <div class="forgotpassword-error-label">Error occured.</div>
          </div>           
                    
        </div>
      </div>
    </div>
    </div>
  