import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ExternalLandingService } from '../service/external-landing.service';
import { ExternalLandingModel } from '../model/external-landing.model';
import { Messages, RouteUrls } from 'src/app/shared/constants';
import { BlockUIService } from 'src/app/shared/services/block-ui.service';
import 'rxjs/add/operator/filter';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-external-landing',
  templateUrl: './external-landing.component.html',
  styleUrls: ['./external-landing.component.scss']
})
export class ExternalLandingComponent implements OnInit {

  messages: any;
  errmessage: string;
  successmessage: string;
  status: boolean;
  showResetSuccessModal: boolean;
  idObj: any;

  constructor(
              private externalLandingService: ExternalLandingService,
              private router: Router,
              private blockUIService: BlockUIService,
              private route: ActivatedRoute,
              private sharedService: SharedService) { }

  ngOnInit() {
    this.messages = Messages;
    this.route.params.subscribe( params => {
      
      if(params.id) {
        
        this.idObj = { params };
        
        this.getTransactionType();
      }
    });
    // this.route.queryParams
    //   .filter(params => params.id)
    //   .subscribe(params => {
    //     
    //     this.idObj = { params };
    //     
    //     this.getTransactionType();
    //   });
  }

  getTransactionType() {

    this.router.navigateByUrl(RouteUrls.RESET_PASSWORD);

    // this.blockUIService.display(true);
    // const externalLandingModel: ExternalLandingModel = { ...this.idObj };
    // if (externalLandingModel) {
    //   
    //   this.externalLandingService.default(externalLandingModel).subscribe(data => {
    //     this.blockUIService.display(false);
    //     
    //     if ( data.status === 'success') {
    //       // this.sharedService.storeUsername(data.email);
    //       this.router.navigateByUrl(RouteUrls.RESET_PASSWORD);
    //     }
    //   }, (err) => {
    //     this.blockUIService.display(false);
    //     this.errmessage = this.messages.PASSWORDRESETERROR;
    //   });
    // } else {
    //   this.errmessage = this.messages.PASSWORDRESETERROR;
    // }
  }
}
