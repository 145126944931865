import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { AlertModalComponent } from '../modals/alert-modal/alert-modal.component';
// import { ConfirmModalComponent } from '../modals/confirm-modal/confirm-modal.component';
import { Observable } from 'rxjs';
// import { ShortPaymentModalComponent } from '../modals/short-payment-modal/short-payment-modal.component';
import { isDate } from 'util';

@Injectable({ providedIn: 'root' })
export class HelperService {

  bsModalRef: BsModalRef;
  shortPaybsModalRef: BsModalRef;
  constructor(
    private modalService: BsModalService,
    private shortPayModalService: BsModalService) { }

  /* Order table by column name desc or asc
    @param isDesc : true for decreasing, false for increasing
    @param tableData : target table to sort
    @param colName : column name
    @return : a sorted of target table
  */
  sortTable(isDesc: boolean, tableData: any, colName: string) {
    return tableData.sort((a, b) => {
      if (isDesc) {
        if (Date.parse(a[colName])) {
          return new Date(a[colName]) < new Date(b[colName])  ? 1 : new Date(a[colName])  > new Date(b[colName])  ? -1 : 0;
        } else {
          return a[colName] < b[colName]  ? 1 : a[colName]  > b[colName]  ? -1 : 0;
        }
      } else {
        if (Date.parse(a[colName])) {
          return new Date(a[colName]) > new Date(b[colName])  ? 1 : new Date(a[colName])  < new Date(b[colName])  ? -1 : 0;
        } else {
          return a[colName] > b[colName]  ? 1 : a[colName]  < b[colName]  ? -1 : 0;
        }
      }
    });
  }



  openAlertModal(title: any, message: any, redirectTo: any) {
    const initialState = {
      modalTitle: title,
      modalMsg: message,
      ModalRedirect: redirectTo
    };
    this.bsModalRef = this.modalService.show(AlertModalComponent, { initialState, ignoreBackdropClick: true });
  }



}
