import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable()
export class SideNavService {
    public sideNavToggleSubject: BehaviorSubject<any> = new BehaviorSubject(null);
    public sideNavToggleDomainSubject: BehaviorSubject<any> = new BehaviorSubject(null);
    public profileToggleSubject: BehaviorSubject<any> = new BehaviorSubject(null);
    public onHeaderMenuSelect: BehaviorSubject<any> = new BehaviorSubject(null);
    public onDomainSettingSelect: BehaviorSubject<any> = new BehaviorSubject(null);
    HeaderMenuValue = this.onHeaderMenuSelect.asObservable();
    DomainSettingValue = this.onDomainSettingSelect.asObservable();

    constructor() { }

    public toggle(value, action?) {
        return this.sideNavToggleSubject.next(value);
    }
    public domainToggle(value, action) {
        return this.sideNavToggleDomainSubject.next(value);
    }

    public toggleProfile(value, action) {
        return this.profileToggleSubject.next(value);
    }

    selectedHeaderMenu(headerMenu) {
        return this.onHeaderMenuSelect.next(headerMenu);
    }
    selectedDomainSetting(domainSetting) {
        return this.onDomainSettingSelect.next(domainSetting);
    }
}
