import { Injectable } from '@angular/core';
import { LoginModel, User, ForgotPasswordModel } from '../model/login.model';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { RoutePaths, RouteUrls } from '../../../shared/constants/constants';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject';
import { BehaviorSubject } from 'rxjs';
import 'rxjs/add/observable/of';
import { ServiceHandlerService } from '../../../shared/services/service-handler.service';
import { CookieService } from 'ngx-cookie-service';


@Injectable()
export class LoginService {
  footSubject: Subject<User> = new BehaviorSubject<User>(null);
  user: any;
  selectedRoutePath: any;

  constructor(private serviceHandlerService: ServiceHandlerService, private router: Router, private cookieService: CookieService) {
  }

  login(loginDetails: any): Observable<User> {
    this.selectedRoutePath = RoutePaths.LOGIN;
    let loginData = {cred: loginDetails}
    return this.serviceHandlerService.loginPost<User>(`${this.selectedRoutePath}`, loginData)
      .pipe(
        map((res: User) => {
          if (res) {
            this.user = res;
            this.footSubject.next(res);
          }
          return res;
        })
      );
  }

  getSecretOtp(loginDetails: any): Observable<User> {
    this.selectedRoutePath = RoutePaths.VERIFY_LOGIN;
    let loginData = {cred: loginDetails}
    return this.serviceHandlerService.loginPost<User>(`${this.selectedRoutePath}`, loginData)
      .pipe(
        map((res: any) => {
          if (res) {
            this.user = res;
            this.footSubject.next(res);
          }
          return res;
        })
      );
  }

  callCloseterms(termsDetails: any): Observable<User> {
    this.selectedRoutePath = RoutePaths.T_AND_C_STATUS;
    return this.serviceHandlerService.post<User>(`${this.selectedRoutePath}`, termsDetails)
      .pipe(
        map((res: User) => {
          if (res) {
            this.user = res;
            this.footSubject.next(res);
          }
          return res;
        })
      );
  }

  logout() {
    localStorage.clear();
    sessionStorage.clear();
    this.selectedRoutePath = RoutePaths.LOGOUT;
    this.serviceHandlerService.get<User>(`${this.selectedRoutePath}`)
    // document.cookie = 'Token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    this.router.navigateByUrl('/');
  }
  checkEmail(userID) {
    // tslint:disable-next-line: max-line-length
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(userID).toLowerCase());
  }

  refreshToken() {
  }

  forgotPassword(forgotPasswordDetails: ForgotPasswordModel): Observable<User> {
    return this.serviceHandlerService.loginPost<User>(`${RoutePaths.FORGOT_PASSWORD}`, forgotPasswordDetails)
      .pipe(
        map((res: User) => {
          if (res) {
            
          }
          return res;
        })
      );
  }

}
