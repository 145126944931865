import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HomeModel} from '../model/home.model';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject';
import { BehaviorSubject } from 'rxjs';
import 'rxjs/add/observable/of';
import { ServiceHandlerService } from '../../../shared/services/service-handler.service';
import { RoutePaths } from 'src/app/shared/constants';

@Injectable()
export class HomeService {
  footSubject: Subject<HomeModel> = new BehaviorSubject<HomeModel>(null);

  constructor(private serviceHandlerService: ServiceHandlerService, private router: Router) { }

  default(homeModelDetails: any): Observable<HomeModel> {
    return this.serviceHandlerService.post<HomeModel>(`${RoutePaths.REGISTER_USER}`, homeModelDetails)
      .pipe(
        map((res: HomeModel) => {
          if (res) {
            this.footSubject.next(res);
          }
          return res;
        })
      );
  }

  regMultiUser(formDataDetails: any) {
    return this.serviceHandlerService.formdataPost(`${RoutePaths.REGISTER_MULTI_USER}`, formDataDetails)
      .pipe(
        map((res: HomeModel) => {
          if (res) {
            this.footSubject.next(res);
          }
          return res;
        })
      );
  }

  getStudents(studentMeadata: any) {
    return this.serviceHandlerService.post(`${RoutePaths.ALL_STUDENT_LIST}`, studentMeadata)
      .pipe(
        map((res: HomeModel) => {
          if (res) {
            this.footSubject.next(res);
          }
          return res;
        })
      );
  }

  getApprovers(apprroversMeadata: any) {
    return this.serviceHandlerService.post(`${RoutePaths.ALL_APPROVER_LIST}`, apprroversMeadata)
      .pipe(
        map((res: HomeModel) => {
          if (res) {
            this.footSubject.next(res);
          }
          return res;
        })
      );
  }

  getStudentApprovers(studentApprroversMeadata: any) {
    return this.serviceHandlerService.post(`${RoutePaths.STUDENT_APPROVER_LIST}`, studentApprroversMeadata)
      .pipe(
        map((res: HomeModel) => {
          if (res) {
            this.footSubject.next(res);
          }
          return res;
        })
      );
  }

  assignApprovers(assignApprroversMeadata: any) {
    return this.serviceHandlerService.post(`${RoutePaths.ASSIGN_APPROVER}`, assignApprroversMeadata)
      .pipe(
        map((res: HomeModel) => {
          if (res) {
            this.footSubject.next(res);
          }
          return res;
        })
      );
  }

  UploadThesis(uploadThesisMeadata: any) {
    return this.serviceHandlerService.postUpload(`${RoutePaths.UPLOAD_THESIS}`, uploadThesisMeadata)
      .pipe(
        map((res: HomeModel) => {
          if (res) {
            this.footSubject.next(res);
          }
          return res;
        })
      );
  }

  UploadPlagiarismReport(UploadPlagiarismReportMeadata: any) {    
    return this.serviceHandlerService.postUpload(`${RoutePaths.UPLOAD_PLAGIARISM_REPORT}`, UploadPlagiarismReportMeadata)
      .pipe(
        map((res: HomeModel) => {
          if (res) {
            this.footSubject.next(res);
          }
          return res;
        })
      );
  }

  UploadReviewedDoc(UploadPlagiarismReportMeadata: any) {    
    return this.serviceHandlerService.postUpload(`${RoutePaths.UPLOAD_REVIEW}`, UploadPlagiarismReportMeadata)
      .pipe(
        map((res: HomeModel) => {
          if (res) {
            this.footSubject.next(res);
          }
          return res;
        })
      );
  }

  getUploadedThesis(getuploadThesisMeadata: any) {    
    return this.serviceHandlerService.post(`${RoutePaths.GET_UPLOADED_THESIS}`, getuploadThesisMeadata)
      .pipe(
        map((res: HomeModel) => {
          if (res) {
            this.footSubject.next(res);
          }
          return res;
        })
      );
  }

  getAdvisorThesisList(getAdvisorThesisMeadata: any) {    
    return this.serviceHandlerService.post(`${RoutePaths.GET_THESIS_LIST}`, getAdvisorThesisMeadata)
      .pipe(
        map((res: HomeModel) => {
          if (res) {
            this.footSubject.next(res);
          }
          return res;
        })
      );
  }

  getAdvisorPendingThesisList(getAdvisorThesisMeadata: any) {    
    return this.serviceHandlerService.post(`${RoutePaths.THESIS_FOR_REVIEW}`, getAdvisorThesisMeadata)
      .pipe(
        map((res: HomeModel) => {
          if (res) {
            this.footSubject.next(res);
          }
          return res;
        })
      );
  }

  approveThesisByPG(approveThesisMeadata: any) {    
    return this.serviceHandlerService.post(`${RoutePaths.THESIS_FINAL_ACTION}`, approveThesisMeadata)
      .pipe(
        map((res: HomeModel) => {
          if (res) {
            this.footSubject.next(res);
          }
          return res;
        })
      );
  }

  submitAdvisorReview(submitAdvisorreviewMeadata: any) {    
    return this.serviceHandlerService.post(`${RoutePaths.SUBMIT_REVIEW}`, submitAdvisorreviewMeadata)
      .pipe(
        map((res: HomeModel) => {
          if (res) {
            this.footSubject.next(res);
          }
          return res;
        })
      );
  }

  getDocDetails(getDocDetailsMeadata: any) {
    
    return this.serviceHandlerService.post(`${RoutePaths.GET_DOCUMENT_DETAILS}`, getDocDetailsMeadata)
      .pipe(
        map((res: HomeModel) => {
          if (res) {
            this.footSubject.next(res);
          }
          return res;
        })
      );
  }

  getTimelineDetails(getTimelineDetailsMeadata: any) {    
    return this.serviceHandlerService.post(`${RoutePaths.GET_TIMELINE_DETAILS}`, getTimelineDetailsMeadata)
      .pipe(
        map((res: HomeModel) => {
          if (res) {
            this.footSubject.next(res);
          }
          return res;
        })
      );
  }

  getTimelineListDetails(getTimelineListDetailsMeadata: any) {    
    return this.serviceHandlerService.post(`${RoutePaths.GET_TIMELINE_LIST_DETAILS}`, getTimelineListDetailsMeadata)
      .pipe(
        map((res: HomeModel) => {
          if (res) {
            this.footSubject.next(res);
          }
          return res;
        })
      );
  }

  getThesisDetails(getThesisDetailsMeadata: any) {    
    return this.serviceHandlerService.post(`${RoutePaths.GET_THESIS_DETAILS}`, getThesisDetailsMeadata)
      .pipe(
        map((res: HomeModel) => {
          if (res) {
            this.footSubject.next(res);
          }
          return res;
        })
      );
  }

  getAdvisorInfoList(getThesisAdvisorInfoMeadata: any) {    
    return this.serviceHandlerService.post(`${RoutePaths.GET_THESIS_ADVISOR_INFO}`, getThesisAdvisorInfoMeadata)
      .pipe(
        map((res: HomeModel) => {
          if (res) {
            this.footSubject.next(res);
          }
          return res;
        })
      );
  }

  callSubmitToIM(submitIMMeadata: any) {    
    return this.serviceHandlerService.post(`${RoutePaths.LEVEL_SUBMIT}`, submitIMMeadata)
      .pipe(
        map((res: HomeModel) => {
          if (res) {
            this.footSubject.next(res);
          }
          return res;
        })
      );
  }

  callOverride(OverrideMeadata: any) {
    return this.serviceHandlerService.post(`${RoutePaths.SUBMIT_OVERRIDE}`, OverrideMeadata)
      .pipe(
        map((res: HomeModel) => {
          if (res) {
            this.footSubject.next(res);
          }
          return res;
        })
      );
  }

  getTimeLineDetails(getTimeLineDataMeadata: any) {    
    return this.serviceHandlerService.post(`${RoutePaths.GET_TIMELINE_DATA}`, getTimeLineDataMeadata)
      .pipe(
        map((res: HomeModel) => {
          if (res) {
            this.footSubject.next(res);
          }
          return res;
        })
      );
  }

  getExternalsListDetails(getexternalsDataMeadata: any) {    
    return this.serviceHandlerService.post(`${RoutePaths.GET_EXTERNALS}`, getexternalsDataMeadata)
      .pipe(
        map((res: HomeModel) => {
          if (res) {
            this.footSubject.next(res);
          }
          return res;
        })
      );
  }
  
  getAllExternalsQuestionsListDetails(getexternalquestionsDataMeadata: any) {    
    return this.serviceHandlerService.post(`${RoutePaths.GET_ALL_EXTERNAL_QUESTIONS}`, getexternalquestionsDataMeadata)
      .pipe(
        map((res: HomeModel) => {
          if (res) {
            this.footSubject.next(res);
          }
          return res;
        })
      );
  }
  assignExternalsListDetails(assignexternalsDataMeadata: any) {    
    return this.serviceHandlerService.post(`${RoutePaths.SET_EXTERNALS}`, assignexternalsDataMeadata)
      .pipe(
        map((res: HomeModel) => {
          if (res) {
            this.footSubject.next(res);
          }
          return res;
        })
      );
  }

  markCompleteDetails(markCompleteMeadata: any) {    
    return this.serviceHandlerService.post(`${RoutePaths.MARK_AS_COMPLETE}`, markCompleteMeadata)
      .pipe(
        map((res: HomeModel) => {
          if (res) {
            this.footSubject.next(res);
          }
          return res;
        })
      );
  }

  updateThesisTitle(updateThesisTitleMeadata: any) {    
    return this.serviceHandlerService.post(`${RoutePaths.UPDATE_THESIS_TITLE}`, updateThesisTitleMeadata)
      .pipe(
        map((res: HomeModel) => {
          if (res) {
            this.footSubject.next(res);
          }
          return res;
        })
      );
  }

  callAddQuestions(addQuestionMeadata: any) {    
    return this.serviceHandlerService.post(`${RoutePaths.ADD_QUESTION}`, addQuestionMeadata)
      .pipe(
        map((res: HomeModel) => {
          if (res) {
            this.footSubject.next(res);
          }
          return res;
        })
      );
  }

  handleArchiveThesis(archiveMeadata: any) {    
    return this.serviceHandlerService.post(`${RoutePaths.ARCHIVE_THESIS}`, archiveMeadata)
      .pipe(
        map((res: HomeModel) => {
          if (res) {
            this.footSubject.next(res);
          }
          return res;
        })
      );
  }

  getReportsCount(getReportsCountMeadata: any) {    
    return this.serviceHandlerService.post(`${RoutePaths.REPORT_COUNTS}`, getReportsCountMeadata)
      .pipe(
        map((res: HomeModel) => {
          if (res) {
            this.footSubject.next(res);
          }
          return res;
        })
      );
  }

  callUpdateQuestions(updateQuestionMeadata: any) {    
    return this.serviceHandlerService.post(`${RoutePaths.UPDATE_QUESTION}`, updateQuestionMeadata)
      .pipe(
        map((res: HomeModel) => {
          if (res) {
            this.footSubject.next(res);
          }
          return res;
        })
      );
  }

  callUpdateUser(updateUserMeadata: any) {    
    return this.serviceHandlerService.post(`${RoutePaths.UPDATE_USER}`, updateUserMeadata)
      .pipe(
        map((res: HomeModel) => {
          if (res) {
            this.footSubject.next(res);
          }
          return res;
        })
      );
  }

  callGetQuestions(getQuestionsMeadata: any) {    
    return this.serviceHandlerService.post(`${RoutePaths.GET_QUESTIONS}`, getQuestionsMeadata)
      .pipe(
        map((res: HomeModel) => {
          if (res) {
            this.footSubject.next(res);
          }
          return res;
        })
      );
  }
  callExternalGetQuestions(getExtQuestionsMeadata: any) {    
    return this.serviceHandlerService.post(`${RoutePaths.GET_EXTERNAL_QUESTIONS}`, getExtQuestionsMeadata)
      .pipe(
        map((res: HomeModel) => {
          if (res) {
            this.footSubject.next(res);
          }
          return res;
        })
      );
  }
  callRejectThesis(getRejectThesisMeadata: any) {    
    return this.serviceHandlerService.post(`${RoutePaths.REJECT_THESIS}`, getRejectThesisMeadata)
      .pipe(
        map((res: HomeModel) => {
          if (res) {
            this.footSubject.next(res);
          }
          return res;
        })
      );
  }

  answerExternalQuestions(getExtQuestionsMeadata: any) {    
    return this.serviceHandlerService.post(`${RoutePaths.ANSWER_QUESTION}`, getExtQuestionsMeadata)
      .pipe(
        map((res: HomeModel) => {
          if (res) {
            this.footSubject.next(res);
          }
          return res;
        })
      );
  }

  getAllThesisList(thesisListMeadata: any) {
    return this.serviceHandlerService.post(`${RoutePaths.ALL_THESIS_LIST}`, thesisListMeadata)
      .pipe(
        map((res: HomeModel) => {
          if (res) {
            this.footSubject.next(res);
          }
          return res;
        })
      );
  }

  handleUpdatePlagiarismScore(updateScoreMeadata: any) {
    return this.serviceHandlerService.post(`${RoutePaths.UPDATE_PLAGIARISM_SCORE}`, updateScoreMeadata)
      .pipe(
        map((res: HomeModel) => {
          if (res) {
            this.footSubject.next(res);
          }
          return res;
        })
      );
  }

  handleGetPlagiarismScore(getScoreMeadata: any) {
    return this.serviceHandlerService.post(`${RoutePaths.FETCH_PLAGIARISM_SCORE}`, getScoreMeadata)
      .pipe(
        map((res: HomeModel) => {
          if (res) {
            this.footSubject.next(res);
          }
          return res;
        })
      );
  }

  callGetExternalQuestions(getQuestionsMeadata: any) {    
    return this.serviceHandlerService.post(`${RoutePaths.GET_EXTERNAL_QUESTIONS}`, getQuestionsMeadata)
      .pipe(
        map((res: HomeModel) => {
          if (res) {
            this.footSubject.next(res);
          }
          return res;
        })
      );
  }

  callsaveAnswer(saveAnswerMeadata: any) {    
    return this.serviceHandlerService.post(`${RoutePaths.SAVE_ANSWER}`, saveAnswerMeadata)
      .pipe(
        map((res: HomeModel) => {
          if (res) {
            this.footSubject.next(res);
          }
          return res;
        })
      );
  }

  callAddComment(addCommentMeadata: any) {    
    return this.serviceHandlerService.post(`${RoutePaths.ADD_COMMENT}`, addCommentMeadata)
      .pipe(
        map((res: HomeModel) => {
          if (res) {
            this.footSubject.next(res);
          }
          return res;
        })
      );
  }

  getCommentsDetails(getCommentMeadata: any) {    
    return this.serviceHandlerService.post(`${RoutePaths.GET_COMMENT}`, getCommentMeadata)
      .pipe(
        map((res: HomeModel) => {
          if (res) {
            this.footSubject.next(res);
          }
          return res;
        })
      );
  }

  handleThesisInfo(thesisInfoMeadata: any) {    
    return this.serviceHandlerService.post(`${RoutePaths.GET_THESIS_INFO}`, thesisInfoMeadata)
      .pipe(
        map((res: HomeModel) => {
          if (res) {
            this.footSubject.next(res);
          }
          return res;
        })
      );
  }

  canUserComment(canCommentMeadata: any) {    
    return this.serviceHandlerService.post(`${RoutePaths.CAN_COMMENT}`, canCommentMeadata)
      .pipe(
        map((res: HomeModel) => {
          if (res) {
            this.footSubject.next(res);
          }
          return res;
        })
      );
  }

}
