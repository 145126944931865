import { Component, OnInit, ViewChild, Input, Output, EventEmitter, OnChanges, ElementRef } from '@angular/core';
import { HomeService } from '../service/home.service';
import { BlockUIService } from 'src/app/shared/services/block-ui.service';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { Router } from '@angular/router';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit, OnChanges  {
	@Input() permission: any;
	@Input() userType: any;
	@Input() thesisID: any;
	@Input() ThesisVersion: any;
	@Input() thesisName: any;
	@Input() triggerByReport: any;
	@Input() triggerBysubmitIM: any;
	@Input() StudentName: any;
	@Input() StudentID: any;
	@Output() currentTimeLineState = new EventEmitter<any>()
	@ViewChild('file') file;
	@ViewChild('inputElement', { static: false }) inputElement?: ElementRef;
	@ViewChild('Reportfile') Reportfile;
	@ViewChild('ReviewFile') ReviewFile;
	@ViewChild('ReviewFile_2') ReviewFile_2;

	files: File[] = [];
	reUploadfiles: File[] = [];
	reviewThesisfiles: File[] = [];
	currentState: any;
	keepGoing: boolean = true;
	plagiarismDownloadPath: string;
	timelineDataTest : any;
	timelineStageData: any = [];
	PlagReportfiles: File[] = [];
	thesisPlagiarismScore = '';
	plagiarismTooltip = 'Enter Plagiarism Score';
	disableUploadPlgReport = true;
	plagiarismErrorScore = false;
	plagiarismCheckComplete = false;
	modalTitle: string;
	confirmModal?: NzModalRef;
	revisedVersionUploaded: boolean = false;
	uploadRevisedVersionMsg = false;
	updatedTitle: boolean = false;
	isRevisedVerIM = true;
	failureMsg: any;
	uploadReportMsg = false;
	plagiarismErrorEntered: boolean = false;
	sessionEmail: string;
	sessionUserId: any;
	thesis_s1_url: any;
	thesis_s2_url: any;
	thesis_s3_url: any;
	selectedAdv_id: any;
	selectedReviewDoc_id: any;
	showUploadReviewSuccess: boolean = false;
	externalData: any = [];
	showExternalData: boolean = false;
	externalSelected: any;
	listOfExternalValue: string[] = [];
	listOfExternalQuestionsValue: string[] = [];
	listOfExternals: [{email: string, name: string, id: number, ref_no: string}];
	listOfSelectedValue: string[] = [];
	listOfExternalValueModel: any;
	is_s2_url_valid: any;
	is_s3_url_valid: any;

	listOfAllQuestions: string[] = [];
  	listOfSelectedValue123 = [];
	stage2Status: any;
	showAllExternalQuesData: boolean = false;
	questions_data: any;
	isAnswerVisible: boolean = false;
	displayQuestions: boolean = false;
	showExternalAssign = false;
	plagiarismFileError: string = '';
	thesisApproved: string = '';
	print_questions_data: any;
	displayPrintQuestions: boolean = false;

	constructor(private homeService: HomeService,
				private router: Router,
				private blockUIService: BlockUIService,
				private modal: NzModalService) { }

  public timelineData: Array<any> = []

  setTimelineClass(data, index) {
  	if (data.status === "complete") {
  		if (index < this.timelineData.length - 1 ) {
  			if (this.timelineData[index + 1]["status"] === "pending") {
  				return "on-going"
  			}
  		}
  		return "completed"
  	}
  }

	selectFile() {
		this.file.nativeElement.click()
	}

 // tslint:disable-next-line:typedef
 onSelect(event) {
		const uploadedfiles: { [key: string]: File } = this.file.nativeElement.files;
		for (let key in uploadedfiles) {
			if (!isNaN(parseInt(key))) {
				this.files.push(uploadedfiles[key])
			}
		}
	 
	}

	selectFileRevisedThesis() {
		this.reUploadfiles = [];
		this.file.nativeElement.click()
	  }

 onReUploadThesis(event, submission_type) {

	 const reUploadedfiles: { [key: string]: File } = this.file.nativeElement.files;
	 for (let key in reUploadedfiles) {
			if (!isNaN(parseInt(key))) {
				this.reUploadfiles.push(reUploadedfiles[key])
			}
		}
		this.showReuploadThesisConfirm('revised', this.reUploadfiles[0].name, submission_type);
	}

 onUploadReviewedThesis(event, adv_id, review_docid, item, submission_type) {
	 const reviewedUploadfiles: { [key: string]: File } = this.ReviewFile.nativeElement.files;
	 for (let key in reviewedUploadfiles) {
			if (!isNaN(parseInt(key))) {
				this.reviewThesisfiles.push(reviewedUploadfiles[key])
			}
		}
		
		this.uploadReviewDoc(submission_type, this.reviewThesisfiles[0].name, this.selectedAdv_id, this.selectedReviewDoc_id);

	}
	
 onUploadReviewedThesis_2(event, adv_id, review_docid, item, submission_type) {
	 const reviewedUploadfiles: { [key: string]: File } = this.ReviewFile_2.nativeElement.files;
	 for (let key in reviewedUploadfiles) {
			if (!isNaN(parseInt(key))) {
				this.reviewThesisfiles.push(reviewedUploadfiles[key])
			}
		}
		
		this.uploadReviewDoc(submission_type, this.reviewThesisfiles[0].name, this.selectedAdv_id, this.selectedReviewDoc_id);

	}

  ngOnInit(): void {
	  this.sessionEmail = localStorage.getItem('id');
	  this.sessionUserId = localStorage.getItem('id');
	  this.getTimeLineData();
	  if(this.userType === 'dean_pgs') {
		this.getexternalsListData();
		this.getexternalsQuestionsListData();
	  }




	//   const children: string[] = [
	// 	  'Does this thesis explain every chapter clearly in detail and ho;ld good in every case? If yes please elaborate on your thoughts. If not, please let the student know where they can improve/ fix any errors.',
	// 	  'queston 2',
	//   ];
	//   this.listOfAllQuestions = children;
  }

	ngOnChanges() {
		
		if (this.triggerBysubmitIM || this.triggerByReport) {
			this.getTimeLineData();
		}
	};

	getTimeLineData(): void {

	 this.blockUIService.display(true);
	 this.timelineStageData = [];
	 const selectedThesisId = {
		email: localStorage.getItem('email'),
		thesis_id: this.thesisID,
		user_type: this.userType
	 };
	 this.homeService.getTimeLineDetails(selectedThesisId).subscribe(data => {
	 
	 this.blockUIService.display(false);
	 if (data.status_code === '01') {
		 this.timelineData = data.timeline_info;
		this.timelineData.forEach(element => {
			if(element.stage_name === 'Submission 1') {
				if(element.details[0].download_url !== '') {
					this.thesis_s1_url = element.details[0].download_url;
				}
				this.timelineStageData.push(element);
			}
			if(element.stage_name === 'Submission 2') {
				this.stage2Status = element.status; 
				if(element.details[0].download_url !== '') {
					this.thesis_s2_url = element.details[0].download_url;
					this.is_s2_url_valid = element.details[0].status;
				}
				this.timelineStageData.push(element);
			}
			if(element.stage_name === 'Submission 3') {
				if(element.details[0].download_url !== '') {
					this.thesis_s3_url = element.details[0].download_url;
					this.is_s3_url_valid = element.details[0].status;
				}
				this.timelineStageData.push(element);
			}
		});
		if (data.approved === 'yes') {
			this.thesisApproved = 'yes';
		} else if(data.approved === 'no') {
			this.thesisApproved = 'no';
		} else {
			this.thesisApproved = 'pending';
		}
		
	 }
	 }, (err) => {
		this.blockUIService.display(false);
	 });
	}


	getexternalsListData(): void {

	 this.blockUIService.display(true);
	 const getExternalListMetadata = {
		email: localStorage.getItem('email')
	 };
	 this.homeService.getExternalsListDetails(getExternalListMetadata).subscribe(data => {
	 
	 this.blockUIService.display(false);
	 if (data.status_code === '01') {
		 this.listOfExternals = data.externals;
		 this.showExternalData = true;

	 }
	 }, (err) => {
		this.blockUIService.display(false);
	 });
	}


	getexternalsQuestionsListData(): void {

	 this.blockUIService.display(true);
	 const getExternalListMetadata = {
		email: localStorage.getItem('email')
	 };
	 this.homeService.getAllExternalsQuestionsListDetails(getExternalListMetadata).subscribe(data => {
	 this.blockUIService.display(false);
	 if (data.status_code === '01') {
		 this.listOfAllQuestions = data.questions;
		 this.showAllExternalQuesData = true;

	 }
	 }, (err) => {
		this.blockUIService.display(false);
	 });
	}


	assignExternals(thesis_id,student_id): void {
	 this.blockUIService.display(true);
	 const assignExternalListMetadata = {
		email: localStorage.getItem('email'),
		thesis_id: thesis_id,
		student_id: student_id,
		externals: this.listOfExternalValue,
		questions: this.listOfExternalQuestionsValue,
	 };
	 this.homeService.assignExternalsListDetails(assignExternalListMetadata).subscribe(data => {
	 
	 this.blockUIService.display(false);
	 if (data.status_code === '01') {
		this.getTimeLineData();

	 }
	 }, (err) => {
		this.blockUIService.display(false);
	 });
	}

	isNotSelected(value: string): boolean {
		return this.listOfSelectedValue.indexOf(value) === -1;
	  }


	externalSelect(data): void  {
		this.externalSelected = data;
	  }

	onChangePlgScore(value: string): void {
		if (value === '') {
		  this.disableUploadPlgReport = true;
		  this.plagiarismErrorScore = false;
		  this.plagiarismTooltip = 'Enter plagiarism score';
		  return
		}
		if (parseInt(value) > 100) {
		  this.plagiarismErrorScore = true;
		  this.disableUploadPlgReport = true;
		//   this.hiderevisedUpload = false;
		} else {
		  this.plagiarismErrorScore = false;
		  this.disableUploadPlgReport = false;
		//   this.hiderevisedUpload = false;
		}
		
		this.updateValue(value);
	  }

	onBlurPlgScore(): void {
		if (this.thesisPlagiarismScore.charAt(this.thesisPlagiarismScore.length - 1) === '.' || this.thesisPlagiarismScore === '-') {
		  this.updateValue(this.thesisPlagiarismScore.slice(0, -1));
		}
	  }
	
	  updateValue(value: string): void {
		const reg = /^-?(0|[1-9][0-9]*)?$/;
		if ((!isNaN(+value) && reg.test(value)) || value === '' || value === '-') {
		  this.thesisPlagiarismScore = value;
		}
		this.inputElement!.nativeElement.value = this.thesisPlagiarismScore;
		this.updateTitle();
	  }
	
	  updateTitle(): void {
		
		this.plagiarismTooltip = this.thesisPlagiarismScore ? this.thesisPlagiarismScore + ' %' : 'Enter plagiarism score';
	  }

	  selectFileplagReport() {
		this.PlagReportfiles = [];
		this.Reportfile.nativeElement.click()
	  }

	  selectFileUploadReviewDoc(data) {
		  this.selectedAdv_id = data.adv_id;
		  this.selectedReviewDoc_id = data.review_docid;
		this.reviewThesisfiles = [];
		this.ReviewFile.nativeElement.click()
	  }
	  selectFileUploadReviewDoc_2(data) {
		  this.selectedAdv_id = data.adv_id;
		  this.selectedReviewDoc_id = data.review_docid;
		this.reviewThesisfiles = [];
		this.ReviewFile_2.nativeElement.click()
	  }

	  onPlagReport(event, submission_type) {    
		const PlagRepedfiles: { [key: string]: File } = this.Reportfile.nativeElement.files;
		for (let key in PlagRepedfiles) {
		  if (!isNaN(parseInt(key))) {
			this.PlagReportfiles.push(PlagRepedfiles[key])
		  }
		}
		this.showReuploadThesisConfirm('report', this.PlagReportfiles[0].name, submission_type);
		
	  }


	  showReuploadThesisConfirm(eventType, docName, submissionType): void {
		if (eventType === 'revised') {
		  this.modalTitle = 'Are you sure you want to upload the revised version of the thesis?';
		} else {
		  this.modalTitle = 'Are you sure you want to upload Plagiarism report of the thesis?';
		}
		this.confirmModal = this.modal.confirm({
		  nzTitle: this.modalTitle,
		  nzContent: 'Selected file: ' + docName,
		  nzOkText: 'Upload',
		  nzCancelText: 'Cancel',
		  nzOnCancel: () => {
			this.reUploadfiles = [];
			this.PlagReportfiles = [];        
		  },
		  nzOnOk: () => {
			if (eventType === 'revised') {
			  const formData: FormData = new FormData()
			  formData.append('file', this.reUploadfiles[0]);
			  formData.append('email', localStorage.getItem('email'));
			  formData.append('thesis_id', this.thesisID);
			  formData.append('submission', submissionType);
			  this.blockUIService.display(true);
			  if (this.thesisID) {
				this.homeService.UploadThesis(formData).subscribe(data => {
				  this.blockUIService.display(false);              
				  if (data.status_code === '01') {
					this.uploadRevisedVersionMsg = true;
					this.updatedTitle = true;
					this.thesisPlagiarismScore = '';
					this.plagiarismErrorScore = false;
					this.reUploadfiles = [];
					this.getTimeLineData();
				  } else if (data.status_code === '03') {
					this.failureMsg = 'Invalid file uploaded. Please upload only .docx files';
				  } else {
					this.failureMsg = data.message;
				  }
				}, (err) => {
				  this.blockUIService.display(false);
				  this.failureMsg = 'Something went wrong. Please Try again';
				});
			  } else {
				this.failureMsg = 'Thesis is required.';
			  }
			} else {
			  const formData: FormData = new FormData()
			  formData.append('file', this.PlagReportfiles[0]);
			  formData.append('email', localStorage.getItem('email'));
			  formData.append('score', this.thesisPlagiarismScore);
			  formData.append('thesis_id', this.thesisID);
			  formData.append('submission', submissionType);
	  
			  this.blockUIService.display(true);
	  
			  this.homeService.UploadPlagiarismReport(formData).subscribe(data => {
				this.blockUIService.display(false);
				
				if (data.status_code === '01') {
				  this.uploadReportMsg = true;
				  this.triggerByReport = this.triggerByReport + 1;
				  this.getTimeLineData();
				  // this.getThesisAdvisorInfo();
				} else if (data.status_code === '03') {
					this.plagiarismFileError = 'Invalid file uploaded. Please upload only .pdf files';
				  } else {
					this.plagiarismErrorEntered = true;
				  }
			  }, (err) => {
				this.blockUIService.display(false);
				alert('Error');
			  });
			}
	
	
		  
		  }
		});
	  }

	  onActionReport(event, submission_type) {    
		const PlagRepedfiles: { [key: string]: File } = this.Reportfile.nativeElement.files;
		for (let key in PlagRepedfiles) {
		  if (!isNaN(parseInt(key))) {
			this.PlagReportfiles.push(PlagRepedfiles[key])
		  }
		}
		this.showUploadActionReportConfirm(this.PlagReportfiles[0].name, submission_type);
		
	  }

	  showUploadActionReportConfirm(docName, submissionType): void {
		
		this.modalTitle = 'Are you sure you want to upload Action report of the thesis?';
		
		this.confirmModal = this.modal.confirm({
		  nzTitle: this.modalTitle,
		  nzContent: 'Selected file: ' + docName,
		  nzOkText: 'Upload',
		  nzCancelText: 'Cancel',
		  nzOnCancel: () => {
			this.reUploadfiles = [];
			this.PlagReportfiles = [];        
		  },
		  nzOnOk: () => {
			 
			const formData: FormData = new FormData()
			formData.append('file', this.PlagReportfiles[0]);
			formData.append('email', localStorage.getItem('email'));
			formData.append('score', this.thesisPlagiarismScore);
			formData.append('thesis_id', this.thesisID);
			formData.append('submission', submissionType);
	
			this.blockUIService.display(true);
	
			this.homeService.UploadPlagiarismReport(formData).subscribe(data => {
			this.blockUIService.display(false);
			
			if (data.status_code === '01') {
				this.uploadReportMsg = true;
				this.triggerByReport = this.triggerByReport + 1;
				this.getTimeLineData();
				// this.getThesisAdvisorInfo();
			} else if (data.status_code === '03') {
				this.plagiarismFileError = 'Invalid file uploaded. Please upload only .pdf files';
				} else {
				this.plagiarismErrorEntered = true;
				}
			}, (err) => {
			this.blockUIService.display(false);
			alert('Error');
			});		  
		  }
		});
	  }


	  uploadReviewDoc(stage, docName, adv_id, review_docid): void {
		this.modalTitle = 'Are you sure you want to upload the reviewed document for the thesis?';
		this.confirmModal = this.modal.confirm({
		  nzTitle: this.modalTitle,
		  nzContent: 'Selected file: ' + docName,
		  nzOkText: 'Upload',
		  nzCancelText: 'Cancel',
		  nzOnCancel: () => {
			this.reUploadfiles = [];
			this.PlagReportfiles = [];        
		  },
		  nzOnOk: () => {
			  
			const formData: FormData = new FormData()
			formData.append('file', this.reviewThesisfiles[0]);
			formData.append('submission', stage);
			formData.append('user_id', adv_id);
			formData.append('thesis_id', this.thesisID);
			formData.append('email', localStorage.getItem('email'));
			if(review_docid) {
				formData.append('review_docid', review_docid);
			}
			this.blockUIService.display(true);
			if (this.thesisID) {
			  this.homeService.UploadReviewedDoc(formData).subscribe(data => {
				this.blockUIService.display(false);              
				if (data.status_code === '01') {
					this.reviewThesisfiles = [];
				  this.getTimeLineData();
				  this.showUploadReviewSuccess = true;
				  
				}else if(data.status_code === '03') {
					this.modal.warning({
					  nzTitle: 'Invalid file type',
					  nzContent: 'Only .docx files can be uploaded'
					});
				  } else {
				  this.failureMsg = data.message;
				}
			  }, (err) => {
				this.blockUIService.display(false);
				this.failureMsg = 'Something went wrong. Please Try again';
			  });
			} else {
			  this.failureMsg = 'Thesis is required.';
			}
	
	
		  
		  }
		});
	  }


	  markAsComplete(data, submission_type): void {
		
		this.modalTitle = 'Are you sure you want to submit your review and mark as complete?';
		this.confirmModal = this.modal.confirm({
		  nzTitle: this.modalTitle,
		  nzContent: 'Thesis title: ' + this.thesisName,
		  nzOkText: 'Mark as complete',
		  nzCancelText: 'Cancel',
		  nzOnOk: () => {
			  
			this.blockUIService.display(true);
			const markCompleteMetadata = {
			email: localStorage.getItem('email'),
			thesis_id: this.thesisID,
			user_type: this.userType,
			user_id: data.adv_id,
			review_docid: data.review_docid,
			submission: submission_type,
			stage_type: data.stage_type
			};
			this.homeService.markCompleteDetails(markCompleteMetadata).subscribe(data => {
			
			this.blockUIService.display(false);
			if (data.status_code === '01') {
				this.getTimeLineData();	
			}
			}, (err) => {
			this.blockUIService.display(false);
			});
		  
		  }
		});
	  }


	  rejectThesis(data, submission_type): void {
		
		this.modalTitle = 'Are you sure you want to reject the thesis?';
		this.confirmModal = this.modal.confirm({
		  nzTitle: this.modalTitle,
		  nzContent: 'Thesis title: ' + this.thesisName,
		  nzOkText: 'Reject',
		  nzCancelText: 'Cancel',
		  nzOnCancel: () => {
			       
		  },
		  nzOnOk: () => {

			this.blockUIService.display(true);
			const rejectthesisMetadata = {
			email: localStorage.getItem('email'),
			thesis_id: this.thesisID,
			stage_type: submission_type
			};
			this.homeService.callRejectThesis(rejectthesisMetadata).subscribe(data => {
			this.blockUIService.display(false);
			if (data.status_code === '01') {
				this.getTimeLineData();			
			}
			}, (err) => {
			this.blockUIService.display(false);
			});
		  
		  }
		});
	  }



	  showExternalAnswers(info: any) {
        this.blockUIService.display(true);
        const getextquesMetadata = {
        email: localStorage.getItem('email'),
        thesis_id: this.thesisID,
        user_id: info.adv_id
        };
        this.homeService.callExternalGetQuestions(getextquesMetadata).subscribe(data => {
        this.blockUIService.display(false);
        // if (data.status_code === '01') {
        //   this.questions_data = data.questions_data;
        //   this.isAnswerVisible = true;
        //   this.displayQuestions = true;
          
        // }
		if (data.status_code === '01') {
			this.print_questions_data = data.questions_data;
			this.displayPrintQuestions = true;
			if (this.print_questions_data) {
			  
			  var styles = `<style>
						  .wrapper {
							  font-family: sans-serif;
							  padding: 0 20px;
						  }
						  h3 {
							  padding-bottom: 9px;
							  border-bottom: 1px solid #c5c5c5;
						  }
						  .names {
							  font-weight: bold;
							  margin-bottom: 10px;
						  }
						  .names span {
							  font-weight: normal;
						  }
						  .qa-wrapper {
							  padding-top: 25px;
							  border-top: 1px solid #c5c5c5;
							  margin-top: 15px;
						  }
						  .each-qa {
							  margin-bottom: 25px;
						  }
						  .q {
							  font-weight: bold;
						  }
						  .q span {
							  display: inline-block;
							  width: 15px;
						  }
						  .a {
							  margin-left: 17px;
							  margin-top: 7px;
						  }
					  </style>`;
			  var qa_json = this.print_questions_data;
			  var thesisInfo = `
					  <div class="names">
						  Student Name: <span>`+this.StudentName+`</span>
					  </div>
					  <div class="names">
						  Thesis Name: <span>`+this.thesisName+`</span>
					  </div>
					  <div class="names">
						  Student ID: <span>`+this.StudentID+`</span>
					  </div>
			  `
			  generatePrintElement();
			  function generatePrintElement() {
				  let wrapper = document.createElement("div");
				  wrapper.innerHTML = styles;
				  wrapper.innerHTML += `<h3>Thesis Evaluation System</h3>`;
				  wrapper.innerHTML += thesisInfo;
  
				  let qaWrapper = document.createElement("div");
				  qaWrapper.classList.add("qa-wrapper");
  
				  qa_json.forEach((item, id) => {
					  let question = item['question_text'];
					  let answer = item['answer_text'];
					  let eachQA = `
						  <div class="each-qa">
							  <div class="q">
								  <span>`+(id+1).toString()+`.</span>`+question+`
							  </div>
							  <div class="a">
								  `+answer+`
							  </div>
						  </div>
					  `
					  qaWrapper.innerHTML += eachQA;
				  })
				  wrapper.appendChild(qaWrapper);
				  printPage(wrapper);
			  }
  
			  function printPage(printContent) {
				  const WindowPrt = window.open(
				  "",
				  "",
				  "left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0"
				  );
				  WindowPrt.document.write(printContent.innerHTML);
				  WindowPrt.document.close();
				  WindowPrt.focus();
				  WindowPrt.print();
			  }
  
			}
		  }
        }, (err) => {
        this.blockUIService.display(false);
        });
	  }


	  handleAnswerOk() {
		this.isAnswerVisible = false;
	  }
	  handleAnswerCancel() {
		this.isAnswerVisible = false;
	  }

	  viewDocument(data) {
		this.router.navigate(['/view', { id: data.adv_id, role: localStorage.getItem('role'), thesis: this.thesisID, ver: '2' }]);
	  }

	  downloadFiles(report_url: string, thesisName: string) {
		var element = document.createElement('a');
		element.setAttribute('href', report_url);
		element.setAttribute('download', thesisName);
		element.setAttribute('target', '_self');
		document.body.appendChild(element);
		element.click();
		document.body.removeChild(element)
	  }

}
