<mat-sidenav-container class='example-container' [hasBackdrop]="true" (backdropClick)="close('backdrop')">
  <mat-sidenav #sidenav mode="over" position="end" opened="false" (keydown.escape)="close('escape')">
    <div nz-col nzSpan="24">
    
      <div nz-row>
        <div nz-col nzSpan="18" class="DrawerTitle">
          <h4><b>Profile Settings</b></h4>
        </div>
        <div nz-col nzSpan="2">
    
        </div>
        <div nz-col nzSpan="4" class="drawerClose">
          <span class="icon-hover" style="font-size: 2rem;">
            <i class="fa fa-times close-icon" aria-hidden="true" (click)="close('escape')"></i>
          </span>
        </div>
      </div>
      <div nz-row>
            
              <div nz-col nzSpan="8" style="padding: 0px;">
                <div class="user-profile text-center"> <img class="user fa-icon" src="../../assets/images/user-img-1.png" /></div>
              </div>
              <div nz-col nzSpan="14">
                <div class="font-weight-bold text-left text-ellipsis user-name">{{UserName}}</div>
                <div class="text-left text-ellipsis email">{{UserEmail}}</div>
                <div class="text-left text-ellipsis email">{{UserRole}}</div>
              </div>
      </div>
    </div>

  </mat-sidenav>
</mat-sidenav-container>