
import { Injectable } from '@angular/core';
import { AlertSetting } from '../models/alert-settings';

@Injectable({ providedIn: 'root' })
export class SessionService {

  constructor() { }

  getLoginErrorMessage(): AlertSetting {
    return JSON.parse(sessionStorage.getItem('loginError'));
  }

  setLoginErrorMessage(error: AlertSetting) {
    sessionStorage.setItem('loginError', JSON.stringify(error));
  }

  clearLoginErrorMessage() {
    sessionStorage.setItem('loginError', JSON.stringify(null));
  }

  getUser() {
    return JSON.parse(sessionStorage.getItem('user'));
  }

  clearLocalSession() {
    sessionStorage.setItem('user', null);
  }

}
