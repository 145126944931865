import { NgModule } from '@angular/core';
import { ResetPasswordComponent } from './component/reset-password.component';
import { RouterModule, Routes } from '@angular/router';

export const resetPasswordRoutes: Routes = [
  { path: 'resetpass/:id', component: ResetPasswordComponent }
];

@NgModule({
  imports: [RouterModule.forChild(resetPasswordRoutes)],
  exports: [RouterModule]
})
export class ResetPasswordRoutingModule { }
