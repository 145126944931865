export const RoutePaths = {
    LOGIN: '/login',
    VERIFY_LOGIN: '/verify_login',
    LOGOUT: '/logout',
    FORGOT_PASSWORD: '/forgot_password',
    TRANSACTION: '/validate_resetpass_link',
    RESET_PASSWORD: '/reset_password',
    REGISTER_USER: '/single_user_reg',
    REGISTER_MULTI_USER: '/multiple_user_reg',
    ALL_STUDENT_LIST: '/all_student_list',
    ALL_APPROVER_LIST: '/all_approver_list',
    ASSIGN_APPROVER: '/assign_approver',
    STUDENT_APPROVER_LIST: '/student_approver_list',
    UPLOAD_THESIS: '/upload_thesis',
    GET_UPLOADED_THESIS: '/my_thesis',
    GET_ADVISOR_THESIS: '/advisor_thesis',
    GET_THESIS_LIST: '/thesis_list',
    THESIS_FOR_REVIEW: '/theses_for_review',
    GET_DOCUMENT_DETAILS: '/uploaded_thesis',
    GET_TIMELINE_DETAILS: '/uploaded_thesis',
    GET_TIMELINE_LIST_DETAILS: '/uploaded_thesis',
    GET_THESIS: '/get_thesis',
    GET_THESIS_DETAILS: '/thesis_details',
    T_AND_C_STATUS: '/t_and_c_status',
    GET_TIMELINE_DATA: '/get_timeline',
    GET_EXTERNALS: '/get_externals',
    GET_ALL_EXTERNAL_QUESTIONS: '/get_all_questions',
    SET_EXTERNALS: '/set_externals',
    MARK_AS_COMPLETE: '/mark_as_complete',
    ADD_QUESTION: '/add_question',
    UPDATE_QUESTION: '/update_question',
    UPDATE_USER: '/update_user',
    GET_QUESTIONS: '/get_question',
    ANSWER_QUESTION: '/answer_question',
    GET_EXTERNAL_QUESTIONS: '/get_external_question',
    REJECT_THESIS: '/reject_thesis',
    SAVE_ANSWER: '/add_answer',
    ADD_COMMENT: '/add/comment',
    GET_COMMENT: '/get/comment',
    UPLOAD_PLAGIARISM_REPORT: '/upload_plagiarism_report',
    UPLOAD_REVIEW: '/upload_review',
    LEVEL_SUBMIT: '/level_submit',
    GET_THESIS_ADVISOR_INFO: '/thesis_advisor_info',
    SUBMIT_REVIEW: '/submit_review',
    GET_THESIS_INFO: '/get_thesis_info',
    SUBMIT_OVERRIDE: '/override/submit',
    THESIS_FINAL_ACTION: '/thesis_final_action',
    REPORT_COUNTS: '/report_counts',
    ALL_THESIS_LIST: '/all_thesis_list',
    CAN_COMMENT: '/can_comment',
    ARCHIVE_THESIS: '/archive_thesis',
    UPDATE_PLAGIARISM_SCORE: '/update_plagiarism_score',
    FETCH_PLAGIARISM_SCORE: '/fetch_plagiarism_score',
    UPDATE_THESIS_TITLE: '/modify_thesis_title',
    VALIDATE_TOKEN: '/validate_token'
};

export const Messages = {
    ASSISTMSG: 'For assistance please contact the service desk.',
    VALIDEMAILID: 'Please check your email',
    INVALIDEMAILID: 'Email ID incorrect',
    INFORMMESSAGE: 'Your new password has been updated. Please Login',
    PASSWORDRESETERROR: 'Something Went wrong. Please try again.',
    RESERPASSWORDSUCCESS: 'Your password has been updated.',
    RESETPASSWORDERROR: 'Password reset unsucessful.Please try again later.',
};

export const Roles = {
};


export const RouteUrls = {
    LOGIN: '/',
    DASHBOARD: '/dashboard',
    HOME: '/home',
    RESET_PASSWORD: '/resetPassword'
};
