import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs/Subject';
@Component({
  selector: 'app-session-timeout-modal',
  templateUrl: './session-timeout-modal.component.html',
  styleUrls: ['./session-timeout-modal.component.scss']
})
export class SessionTimeoutModalComponent implements OnInit {
  remainingSeconds: number;
  header: string;
  onClose: Subject<boolean>;
  constructor(public modal: BsModalRef) { }

  ngOnInit() {
    this.remainingSeconds = 0;
    this.onClose = new Subject<boolean>();
  }

  showConfirmationPopUp(countDown: number) {
    this.remainingSeconds = countDown;
  }

  onConfirm(): void {
    this.onClose.next(true);
    this.close();
  }

  onCancel(): void {
    this.onClose.next(false);
    this.close();
  }

  close() {
    this.modal.hide();
  }

}
