import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ExternalLandingComponent } from './component/external-landing.component';
import { ExternalLandingService } from './service/external-landing.service';
import { ExternalLandingRoutingModule } from './external-landing.routing.module';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  imports: [
    CommonModule,
    ExternalLandingRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule
  ],
  declarations: [ExternalLandingComponent],
   providers: [ExternalLandingService]
})
export class ExternalLandingModule { }
