<div class="full-width">
    <div class="header" *ngIf="!validSession"> 
        <div>   
            <img src="assets/images/gkvk_logo.png" id="logoHome">
        </div> 
    </div>

    <div class="sessionHeader" *ngIf="validSession"> 
        <div nz-row class="sessionHeaderRow">
            <div nz-col nzSpan="5" class="logo sessionHeaderRow">
                <img (click)="routeTo()" class="sessionLogo" src="assets/images/gkvk_logo.png" id="logoHome">
            </div>  
            <div nz-col nzSpan="14" class="app-title sessionHeaderRow">
                <div class="app-title-text">Thesis Evaluation System</div>
            </div>

            <div nz-col nzSpan="5" class="profile-options sessionHeaderRow">
                <div *ngIf="UserName" class="user-name">
                    <span>{{UserName}}</span>
                </div>
                <div class="user-action" 
                nz-tooltip nzTooltipTitle="My profile" 
                nz-popover 
                nzPopoverTitle="My profile" 
                [(nzPopoverVisible)]="visible" 
                nzPopoverTrigger="click" 
                nzPopoverPlacement="bottomRight"
                [nzPopoverContent]="contentTemplate">
                        <i style="font-size: 1.3rem;margin-right: 30%;margin-top: 2.5vh" nz-icon nzType="user" nzTheme="outline"></i>
                </div>
                <!-- <i nz-icon nzType="user" nzTheme="outline"></i> -->
                <ng-template class="profile-template" #contentTemplate>
                    <div nz-col nzSpan="24">
                        <div class="profile-row" nz-row>
                            <span nz-col nzSpan="6"><i nz-icon nzType="user" nzTheme="outline"></i></span>
                            <span nz-col nzSpan="18">{{UserName}}</span>
                        </div>
                        <div class="profile-row" nz-row>
                            <span nz-col nzSpan="6"><i nz-icon nzType="mail" nzTheme="outline"></i></span>
                            <span nz-col nzSpan="18">{{UserEmail}}</span>
                        </div>
                        <div class="profile-row" nz-row>
                            <span nz-col nzSpan="6"><i nz-icon nzType="idcard" nzTheme="outline"></i></span>
                            <span nz-col nzSpan="18">{{UserReference}}</span>
                        </div>
                        <div class="profile-row" nz-row>
                            <span nz-col nzSpan="6"><i nz-icon nzType="tags" nzTheme="outline"></i></span>
                            <span nz-col nzSpan="18">{{UserRole}}</span>
                        </div>
                      </div>
                  </ng-template>

                <!-- <div class="logout-action" nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
                        <i nz-tooltip nzTooltipTitle="Signout" nzTooltipPlacement="bottomRight" style="font-size: 2rem;" class="fa fa-power-off mt-m5" aria-hidden="true"></i>
                        <nz-dropdown-menu #menu="nzDropdownMenu">
                            <ul nz-menu>
                                <li nz-menu-item (click)="logout()">Signout</li>
                            </ul>
                        </nz-dropdown-menu>
                </div> -->
                <div class="logout-action">
                        <i nz-tooltip nzTooltipTitle="Signout" (click)="logout()" nzTooltipPlacement="bottomRight" style="font-size: 1.3rem;margin-right: 30%;margin-top: 2.5vh" nz-icon nzType="poweroff" nzTheme="outline"></i>
                </div>
            </div>            
        </div> 
    </div>
    
</div>
