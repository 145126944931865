import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject';
import { BehaviorSubject } from 'rxjs';
import { ServiceHandlerService } from '../services/service-handler.service';
import { RoutePaths } from '../constants'

@Injectable({ providedIn: 'root' })
export class SharedService {
  apiBody: { email: any; };
  footSubject: Subject<any> = new BehaviorSubject<any>(null);
  selectedRoutePath: string;
  constructor(private serviceHandlerService: ServiceHandlerService) {}
  getUserName = '';


  storeUsername(name) {
    
    this.getUserName = name;
  }

  TransferUsername() {
    return this.getUserName;
  }

  validate_token(params) {
    
    this.apiBody = {email: params};
    return this.serviceHandlerService.post(`${RoutePaths.VALIDATE_TOKEN}`, this.apiBody)
      .pipe(
        map((res: any) => {
          if (res) {
            this.footSubject.next(res);
          }
          return res;
        })
      );
  }

  logout() {
    localStorage.clear();
    sessionStorage.clear();
    this.selectedRoutePath = RoutePaths.LOGOUT;
    this.serviceHandlerService.get(`${this.selectedRoutePath}`)
    // document.cookie = 'Token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  }
}
