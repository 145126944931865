import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import { BlockUIService } from './block-ui.service';
import { environment } from '../../../environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { RoutePaths, RouteUrls } from '../constants/constants';

@Injectable({ providedIn: 'root' })
export class ServiceHandlerService {

  private baseUrl: string;
  private urlVersion: string;
  // private requestOptions: any;

  constructor(private httpClient: HttpClient, private router: Router, private blockUIService: BlockUIService) {
    this.redirectExpiredSessionOnly = this.redirectExpiredSessionOnly.bind(this);
    // this.baseUrl = environment.api;
    this.baseUrl = '/api';
  }
  get requestOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8'
      }),
      withCredentials: true
    };
  }

  get formdataRequestOptions() {
    return {
      headers: new HttpHeaders({
        'Authorization': localStorage.getItem('id')
      }),
      withCredentials: true
    };
  }

  get uploadformdataRequestOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'multipart/form-data',
        'Authorization': localStorage.getItem('id')
      }),
      withCredentials: true

      // const headers = new HttpHeaders();
      // headers.append('Content-Type', 'multipart/form-data');
    };
  }

  get loginRequestOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8'
      }),
      withCredentials: true
    };
  }

  requestResetOptions(id: any) {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': id
      }),
      withCredentials: true
    };
  }

  /**
   * @param url - relative path of the resourse
   * @param body - http request body for the POST request.
   */
  post<T>(url, body, params?: HttpParams): Observable<T> {
    this.blockUIService.display(true);

    return this.httpClient
      .post<T>(`${this.baseUrl}${url}`, body || {}, { ...this.requestOptions, params })
      .pipe(
        catchError(this.redirectExpiredSessionOnly),
        finalize(() => this.blockUIService.display(false))
      );
  }

  postUpload<T>(url, body, params?: HttpParams): Observable<T> {

    
    
    this.blockUIService.display(true);
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');

    return this.httpClient
      .post<T>(`${this.baseUrl}${url}`, body, { headers: headers, withCredentials: true })
      .pipe(
        catchError(this.redirectExpiredSessionOnly),
        finalize(() => this.blockUIService.display(false))
      );
  }

  formdataPost<T>(url, body, params?: HttpParams): Observable<T> {

    
    
    this.blockUIService.display(true);

    return this.httpClient
      .post<T>(`${this.baseUrl}${url}`, body, { ...this.formdataRequestOptions, params })
      .pipe(
        catchError(this.redirectExpiredSessionOnly),
        finalize(() => this.blockUIService.display(false))
      );
  }

  loginPost<T>(url, body, params?: HttpParams): Observable<T> {   
    
    this.blockUIService.display(true);

    return this.httpClient
      .post<T>(`${this.baseUrl}${url}`, body || {}, { ...this.loginRequestOptions, params })
      .pipe(
        catchError(this.redirectExpiredSessionOnly),
        finalize(() => this.blockUIService.display(false))
      );
  }

  resetPost<T>(url, body, params?: HttpParams): Observable<T> {

    
    
    
    this.blockUIService.display(true);

    return this.httpClient
      .post<T>(`${this.baseUrl}${url}`, body || {}, { ...this.requestResetOptions(body.auth), params })
      .pipe(
        catchError(this.redirectExpiredSessionOnly),
        finalize(() => this.blockUIService.display(false))
      );
  }
  postParam<T>(url, body, params?: HttpParams): Observable<T> {

    
    this.blockUIService.display(true);

    return this.httpClient
      .get<T>(`${this.baseUrl}${url}?id=${body.params.id}`, { ...this.requestOptions, params })
      .pipe(
        catchError(this.redirectExpiredSessionOnly),
        finalize(() => this.blockUIService.display(false))
      );
  }

  getParams<T>(url, body): Observable<T> {

    
    this.blockUIService.display(true);

    return this.httpClient
      .get<T>(`${this.baseUrl}${url}?id=${body}`, { ...this.requestOptions })
      .pipe(
        catchError(this.redirectExpiredSessionOnly),
        finalize(() => this.blockUIService.display(false))
      );
  }

  /**
   * @param url - relative path of the resourse
   * @param body - http request body for the PUT request.
   */
  put<T>(url, body, params?: HttpParams): Observable<T> {

    

    return this.httpClient
      .put<T>(`${this.baseUrl}${url}`, body || {}, { ...this.requestOptions, params })
      .pipe(
        catchError(this.redirectExpiredSessionOnly),
        finalize(() => this.blockUIService.display(false))
      );
  }

  /**
   * @param url - relative path of the resourse
   * @param errorHandlingOption - Consumer decides how errors are handled. See HttpErrorHandlingOptions enumeration for the options.
   */
  get<T>(url, params?: HttpParams): Observable<T> {

    
    this.blockUIService.display(true);


    return this.httpClient
      .get<T>(`${this.baseUrl}${url}`, { ...this.requestOptions, params })
      .pipe(
        catchError(this.redirectExpiredSessionOnly),
        finalize(() => this.blockUIService.display(false))
      );
  }

  /**
   * @param errror - error response received from the api request
   * @returns empty Observable
   * @description This error handler will navigate the user to the login page if 401 error occurs.
   * If the error response has status code 401, then login page will tell user that their session has expired.
   * If the error response has any other status code, then Observable will throw an error, to let the consumer decide how to handle.
   */
  private redirectExpiredSessionOnly(error: Response | any) {
    this.blockUIService.display(false);
    console.error(error);
    if (error.status && error.status === 401) {
      this.router.navigateByUrl('/');
      return throwError(error);
    } else {
      return throwError(error);
    }
  }

}
