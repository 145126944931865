import { NgModule } from '@angular/core';
import { LoginComponent } from './component/login.component';
import { RouterModule, Routes } from '@angular/router';

export const loginRoutes: Routes = [
  { path: '', component: LoginComponent }
];

@NgModule({
  imports: [RouterModule.forChild(loginRoutes)],
  exports: [RouterModule]
})
export class LoginRoutingModule { }
