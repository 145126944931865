<!-- <app-header></app-header> -->
<div class="wrapper" [ngClass]="isLoginPage() ? 'wrapper' : 'wrapper-white'" >

  <span *ngIf="blockUI" class="spin">
    <ccportal-loading-icon></ccportal-loading-icon>
  </span>

  <router-outlet></router-outlet>

</div>

<footer>
  <div class="login-footer" [ngClass]="isLoginPage() ? 'login-footer' : 'login-footer-dark'">
    <p>© University of Agricultural Sciences, GKVK, Bangalore - 560 065</p>
  </div>
</footer>
