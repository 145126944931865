import { NgModule } from '@angular/core';
import { NotFoundComponent } from './component/not-found.component';
import { RouterModule, Routes } from '@angular/router';

export const notFoundRoutes: Routes = [
  { path: 'error', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forChild(notFoundRoutes)],
  exports: [RouterModule]
})
export class NotFoundRoutingModule { }
