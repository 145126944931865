<div nz-row class="home-header-row">
    <div nz-col nzSpan="24">
        <app-header></app-header>
    </div>
  </div>
<div nz-row class="home-content-row">
    <div nz-col nzSpan="4">
        <app-admin-sidebar (onSelect)="selectIndexmenu($event)"></app-admin-sidebar>
    </div>
    <div nz-col nzSpan="20">
        <div class="content-container" *ngIf="selectedMenu === 0">
            <div nz-row class="content-header">
                <span class="heading">
                    Register Single User
                </span>
            </div>
            <div class="content-body">
                <div nz-row>
                    <div nz-col nzSpan="4">
                    </div>
                    <div nz-col nzSpan="14">
                        <div class="text-left">
                            <form role="form" method="POST" [formGroup]="registerForm" (ngSubmit)="registerUser()">
                              <div nz-row>
                                <span nz-col nzSpan="4" style="margin-top: 20px;">Role*</span>                                
                                <nz-select nz-col nzSpan="15" class="select-field" nzShowSearch nzAllowClear nzPlaceHolder="Select a role" id="user_role"
                                  formControlName="user_role">
                                  <nz-option nzLabel="Student" nzValue="student"></nz-option>
                                  <!-- <nz-option nzLabel="Admin" nzValue="admin"></nz-option> -->
                                  <nz-option nzLabel="Member" nzValue="advisor"></nz-option>
                                  <nz-option nzLabel="External" nzValue="external_member"></nz-option>
                                  <nz-option nzLabel="Dean PGS" nzValue="dean_pgs"></nz-option>
                                </nz-select>
                                <div nz-col nzSpan="5"></div>
                                <br />
                              </div> 
                              <div nz-row>
                                <span nz-col nzSpan="4" style="margin-top: 20px;">Name*</span>
                                <input nz-col nzSpan="15" class="input-field red-border" nz-input [ngClass]="{'red-border': errorMsg}" type="text" 
                                  formControlName="user_name" (keypress)="filterKeyEvent($event)" autocomplete="no-autoname" placeholder="Enter name" required>
                                  <div nz-col nzSpan="5"></div>
                                  <br />    
                              </div>   
                              <div nz-row>
                                <span nz-col nzSpan="4" style="margin-top: 20px;">Email*</span>
                                <input nz-col nzSpan="15" class="input-field" nz-input [ngClass]="{'red-border': errorMsg}" type="text" 
                                  formControlName="user_email" (keypress)="filterKeyEvent($event)" autocomplete="no-autoemail" placeholder="Enter email" required>
                                  <div nz-col nzSpan="5"></div>
                                <br />
                              </div>
                              <div nz-row>
                                <span nz-col nzSpan="4" style="margin-top: 20px;">Mobile</span>
                                <input nz-col nzSpan="15" class="input-field" nz-input [ngClass]="{'red-border': errorMsg}" type="text" 
                                  formControlName="mob_number" (keypress)="filterKeyEvent($event)" autocomplete="no-autophone" placeholder="Enter mobile" required>
                                  <div nz-col nzSpan="5"></div>
                                <br />
                              </div>  
                              <div *ngIf="registerForm.get('user_role').value === 'student'" nz-row>
                                <span nz-col nzSpan="4" style="margin-top: 20px;">ID</span>
                                <input nz-col nzSpan="15" class="input-field" nz-input [ngClass]="{'red-border': errorMsg}" type="text" 
                                  formControlName="ref_number" (keypress)="filterKeyEvent($event)" autocomplete="no-autoid" placeholder="Enter ID" required>
                                  <div nz-col nzSpan="5"></div>
                                <br />
                              </div>
                              <div *ngIf="registerForm.get('user_role').value === 'student'" nz-row>
                                <span nz-col nzSpan="4" style="margin-top: 20px;">Department</span>
                                <input nz-col nzSpan="15" class="input-field" nz-input [ngClass]="{'red-border': errorMsg}" type="text"
                                 (keypress)="filterKeyEvent($event)" autocomplete="no-autodep" formControlName="department" placeholder="Enter Department" required>
                                <div nz-col nzSpan="5"></div>
                                <br />
                              </div>
                              <div *ngIf="registerForm.get('user_role').value === 'student'" nz-row>
                                <span nz-col nzSpan="4" style="margin-top: 20px;">Section</span>
                                <input nz-col nzSpan="15" class="input-field" nz-input [ngClass]="{'red-border': errorMsg}" type="text"
                                  (keypress)="filterKeyEvent($event)" autocomplete="no-autosec" formControlName="section" placeholder="Enter Section" required>
                                <div nz-col nzSpan="5"></div>
                                <br />
                              </div>
                              <div *ngIf="registerForm.get('user_role').value === 'student'" nz-row>
                                <span nz-col nzSpan="4" style="margin-top: 20px;">College Name</span>
                                <input nz-col nzSpan="15" class="input-field" nz-input [ngClass]="{'red-border': errorMsg}" type="text"
                                 (keypress)="filterKeyEvent($event)" autocomplete="no-autoclgname" formControlName="college_name" placeholder="Enter College Name" required>
                                <div nz-col nzSpan="5"></div>
                                <br />
                              </div>
                                                             
                                 <div *ngIf="registerSucces" class="user-success">
                                    <span>User registered successfully. An email with a link to set password is sent to the email provided.</span>
                                 </div>
                                 <div *ngIf="userExists" class="user-exists">
                                    <span>User already exists</span>
                                 </div>
                                 <div *ngIf="errorMsg" class="user-exists">
                                    <span>Error occured. Please try again</span>
                                 </div>
                                 <button class="register reg-btn" type="submit">Register</button>
                              </form>
                        </div>
                    </div>
                    <div nz-col nzSpan="6">
                    </div>
                </div>                
            </div>
        </div>
        <div class="content-container" *ngIf="selectedMenu === 1">            
            <div nz-row class="content-header">
                <span class="heading">
                    Register Multiple Users
                </span>
            </div>
            <div class="content-body">
                <div nz-row >
                
                    <div nz-col nzSpan="4">
                    </div>
                    <div nz-col nzSpan="14">
                        <div class="text-left">
                            Please download, fill the template and upload the file to register multiple users
                        </div>
                        <div class="download-template-btn">
                          <a href="/api/static/register-users-template.csv" target="_self" download="register_users_template.csv"><button nz-button nzType="primary"
                              class="download-template reg-btn"><i nz-icon nzType="download"></i>Download Template</button> </a>
                        </div>
                        <div class="upload-message mt-5">
                            Note: Upload file format should be .csv file
                        </div>
                        <div class="text-left mt-3">
                          <div nz-row>
                            <span nz-col nzSpan="3" style="margin-top: 20px;">Role*</span>
                            <nz-select nz-col nzSpan="16" class="select-field" nzShowSearch nzAllowClear nzPlaceHolder="Select a role"
                              id="role" [(ngModel)]="multi_upload_role">
                              <nz-option nzLabel="Student" nzValue="student"></nz-option>
                              <nz-option nzLabel="Admin" nzValue="admin"></nz-option>
                              <nz-option nzLabel="Member" nzValue="advisor"></nz-option>
                              <nz-option nzLabel="External" nzValue="external_member"></nz-option>                              
                            </nz-select>
                            <div nz-col nzSpan="5"></div>
                            <br />
                          </div>
                        </div>
                          <div class="upload-file text-left mt-3">
                            <div nz-row>
                              <nz-input-group  [nzAddOnBefore]="browseFiles">
                                <input  type="text" nz-input
                                  [(ngModel)]="listFileName">                                
                              </nz-input-group>
                              <ng-template  #browseFiles class="addedBtn">
                                <input  class="register file-choose" color="primary" type="button" value="Choose File..." id="fakeBrowse" (click)="HandleBrowseClick()" />
                            </ng-template>
                              <input id="fileinput" class="form-browse" style="display: none;" type="file" name="file" (change)="handleChange($event.target.files);" file-limit="1" accept=".csv" #fileInp />
                              <div ></div>
                            </div>                        
                        </div>
                          <div class="text-left mt-3">
                            <button class="register reg-btn" type="button" (click)="registerMultiUsers()">Submit</button>
                          </div>
                    </div>
                    <div nz-col nzSpan="6">
                    </div>    
                </div>
            </div>
        </div>
        <div class="content-container" *ngIf="selectedMenu === 2">
            <div nz-row class="content-header">
                <span class="heading">
                    Manage Approvers
                </span>
            </div>
            <div class="content-body">
                <div nz-row>
                    <div nz-col nzSpan="1">
                    </div>
                    <div nz-col nzSpan="22">
                      <div nz-row>
                        <div nz-col nzSpan="18" style="margin-bottom: 1%; text-align: left;">                         
                          <div class="search-filter">                            
                            <input style="width: 35%" type="text" nz-input placeholder="Search Student Name/Email/ID"
                              [(ngModel)]="searchFilterValue" (keypress)="filterKeyEvent($event)" autocomplete="no-autosearchhome" />
                            <button nz-button nzSize="small" nzType="primary" (click)="filterApproversData()" class="search-button ml-2">
                              Search
                            </button>
                            <button nz-button nzSize="small" (click)="resetFilter()">Reset</button>                          
                          </div>
                        </div>
                      </div>
                        <div class="">
                            <nz-table #nzTable [nzPageSize]="[6]"  [nzData]="listOfDisplayData" nzTableLayout="fixed">
                                <thead>
                                  <tr>
                                    <th nzWidth="30%">Name</th>
                                    <th nzWidth="30%">Email</th>
                                    <th nzWidth="20%">ID</th>
                                    <th nzWidth="20%">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr *ngFor="let data of nzTable.data">
                                    <td>{{ data.name }}</td>
                                    <td>{{ data.email }}</td>
                                    <td>{{ data.ref_no }}</td>
                                    <td>
                                        <button nz-button nzSize='small' nzType="primary" (click)="showModal(data)">Assign Approvers</button>
                                    </td>
                                  </tr>
                                </tbody>
                              </nz-table>
                              <nz-dropdown-menu #menu="nzDropdownMenu">
                                <div class="ant-table-filter-dropdown">
                                  <div class="search-box">
                                    <input type="text" nz-input placeholder="Search name" [(ngModel)]="searchValue" (keypress)="filterKeyEvent($event)" autocomplete="no-autosearchtwo" />
                                    <button nz-button nzSize="small" nzType="primary" (click)="search()" class="search-button">
                                      Search
                                    </button>
                                    <button nz-button nzSize="small" (click)="reset()">Reset</button>
                                  </div>
                                </div>
                              </nz-dropdown-menu>
                        </div>
                        <nz-modal [(nzVisible)]="isVisible" nzWidth='60%' [nzStyle]="{ top: '20px' }" [nzTitle]="selectedName +' - ' + selectedEmail" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()">
                         
                              <div nz-row style="margin-top: 4%;">
                              <div nz-col nzSpan="11" class="select-row">
                                <div>
                                  <span><b>Major Advisor</b></span>
                                </div>
                                <div>
                                  <nz-select class="model-select width80" nzMode="default" nzPlaceHolder="Search for Major Advisor" (ngModelChange)="majorAdvisorSelect($event)" [(ngModel)]="listOfMajorValue">
                                    <nz-option *ngFor="let major of listOfMajorAdvisors" [nzLabel]="major.name +' ('+ major.email +')'" [nzValue]="major.email" [nzHide]="!isNotSelected(major)"></nz-option>
                                  </nz-select>
                                </div> 
                              </div>
                              <div nz-col nzSpan="11" class="select-row" >
                                <div>
                                  <span> <b>Advisory committee </b> </span>
                                </div>
                                <div>
                                  <nz-select class="model-select width80" nzMode="multiple" nzPlaceHolder="Search for Advisory committee" (ngModelChange)="advisorSelect($event)" [(ngModel)]="listOfAdvisorValue">
                                    <nz-option *ngFor="let advisor of listOfAdvisors" [nzLabel]="advisor.name +' ('+ advisor.email +')'" [nzValue]="advisor.email" [nzHide]="!isNotSelected(advisor)"></nz-option>
                                  </nz-select>
                                </div> 
                              </div>
                              </div>
                              <div nz-row>
                              <div nz-col nzSpan="11" class="select-row">
                                <div>
                                  <span><b>Dean</b></span>
                                </div>
                                <div>
                                  <nz-select class="model-select width80" nzMode="default" nzPlaceHolder="Search for Dean" (ngModelChange)="deanSelect($event)" [(ngModel)]="listOfDeanValue">
                                    <nz-option *ngFor="let dean of listOfDean" [nzLabel]="dean.name +' ('+ dean.email +')'" [nzValue]="dean.email" [nzHide]="!isNotSelected(dean)"></nz-option>
                                  </nz-select>
                                </div> 
                              </div>
                              <div nz-col nzSpan="11" class="select-row">
                                <div>
                                  <span> <b>HOD</b> </span>
                                </div>
                                <div>
                                  <nz-select class="model-select width80" nzMode="default" nzPlaceHolder="Search for HOD" (ngModelChange)="hodSelect($event)"  [(ngModel)]="listOfHodValue">
                                    <nz-option *ngFor="let hod of listOfHod" [nzLabel]="hod.name +' ('+ hod.email +')'" [nzValue]="hod.email" [nzHide]="!isNotSelected(hod)"></nz-option>
                                  </nz-select>
                                </div> 
                              </div>
                              </div>
                              <div  nz-row>
                              <div nz-col nzSpan="11" class="select-row">
                                <div>
                                  <span><b>Dean PGS</b></span>
                                </div>
                                <div>
                                  <nz-select class="model-select width80" nzMode="default" nzPlaceHolder="Search for Dean PGS" (ngModelChange)="deanPgsSelect($event)" [(ngModel)]="listOfDeanPgsValue">
                                    <nz-option *ngFor="let pgs of listOfdean_pgs" [nzLabel]="pgs.name +' ('+ pgs.email +')'" [nzValue]="pgs.email" [nzHide]="!isNotSelected(pgs)"></nz-option>
                                  </nz-select>
                                </div> 
                              </div>
                              <!-- <div nz-col nzSpan="11" class="select-row">
                                <div>
                                  <span><b>External Members</b></span>
                                </div>
                                <div>
                                  <nz-select class="model-select width80" nzMode="multiple" nzPlaceHolder="Search for External member" (ngModelChange)="externalSelect($event)" [(ngModel)]="listOfExternalValue">
                                    <nz-option *ngFor="let external of listOfExternals" [nzLabel]="external.name +' ('+ external.email +')'" [nzValue]="external.email" [nzHide]="!isNotSelected(external)"></nz-option>
                                  </nz-select>
                                </div> 
                              </div> -->
                              <!-- <div nz-col nzSpan="11" class="select-row">
                                <div>
                                  <span><b>Dean PGS</b></span>
                                </div>
                                <div>
                                  <nz-select class="model-select width80" nzMode="default" nzPlaceHolder="Search for Dean PGS" (ngModelChange)="deanPgsSelect($event)" [(ngModel)]="listOfDeanPgsValue">
                                    <nz-option *ngFor="let pgs of listOfdean_pgs" [nzLabel]="pgs.name +' ('+ pgs.email +')'" [nzValue]="pgs.email" [nzHide]="!isNotSelected(pgs)"></nz-option>
                                  </nz-select>
                                </div> 
                              </div> -->
                              </div>
                          <div *nzModalFooter>
                            <button nz-button nzType="default" (click)="handleCancel()">Cancel</button>
                            <button nz-button nzType="primary" (click)="assignApproversCall()" >Submit</button>
                          </div>
                        </nz-modal>
                    </div>
                    <div nz-col nzSpan="1">
                    </div>
                </div>                
            </div>
        </div>

        <div class="content-container" *ngIf="selectedMenu === 7">
            <div nz-row class="content-header">
                <span class="heading">
                    Manage Members
                </span>
            </div>
            <div class="content-body">
                <div nz-row>
                    <div nz-col nzSpan="1">
                    </div>
                    <div nz-col nzSpan="22">
                      <div nz-row>
                        <div nz-col nzSpan="18" style="margin-bottom: 1%; text-align: left;">                         
                          <div class="search-filter">                            
                            <input style="width: 35%" type="text" nz-input placeholder="Search Student Name/Email/ID"
                              [(ngModel)]="searchFilterValue" (keypress)="filterKeyEvent($event)" autocomplete="no-autosearchmember" />
                            <button nz-button nzSize="small" nzType="primary" (click)="filterApproversData()" class="search-button ml-2">
                              Search
                            </button>
                            <button nz-button nzSize="small" (click)="resetFilter()">Reset</button>                          
                          </div>
                        </div>
                      </div>
                        <div class="">
                            <nz-table #nzTable [nzPageSize]="[6]"  [nzData]="listOfDisplayData" nzTableLayout="fixed">
                                <thead>
                                  <tr>
                                    <th nzWidth="25%">Name</th>
                                    <th nzWidth="25%">Email</th>
                                    <th nzWidth="20%">Mobile</th>
                                    <th nzWidth="15%">Role</th>
                                    <th nzWidth="15%">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr *ngFor="let data of nzTable.data">
                                    <td>{{ data.name }}</td>
                                    <td>{{ data.email }}</td>
                                    <td>{{ data.ref_no }}</td>
                                    <td>{{ data.ref_no }}</td>
                                    <td>
                                        <button nz-button nzSize='small' nzType="primary" (click)="showMemberAssignModal(data)">Assign</button>
                                       
                                    </td>
                                  </tr>
                                </tbody>
                              </nz-table>
                              
                        </div>
                        <nz-modal [(nzVisible)]="isMemberVisible" nzWidth='60%' [nzStyle]="{ top: '20px' }" [nzTitle]="selectedMemberName +' - ' + selectedMemberEmail" (nzOnCancel)="handleMemberCancel()" (nzOnOk)="handleOk()">
                         
                              <div nz-row style="margin-top: 4%;">
                              <div nz-col nzSpan="24" class="select-row">
                                <div nz-row style="margin-top: 4%;">
                                  <div nz-col nzSpan="11" class="select-row">
                                    <div>
                                      <span><b>Subject</b></span>
                                    </div>
                                    <div>
                                      <nz-select class="model-select width80" nzMode="default" nzPlaceHolder="Assign Member" (ngModelChange)="majorAdvisorSelect($event)" [(ngModel)]="listOfMajorValue">
                                        <nz-option  nzLabel="Subject 1" nzValue="subject_1"></nz-option>
                                        <nz-option  nzLabel="Subject 2" nzValue="subject_2"></nz-option>
                                        <nz-option  nzLabel="Subject 3" nzValue="subject_3"></nz-option>
                                        <nz-option  nzLabel="Subject 4" nzValue="subject_4"></nz-option>
                                      </nz-select>
                                    </div> 
                                  </div>
                                  <div nz-col nzSpan="11" class="select-row" >
                                    <div>
                                      <span> <b>Member Type</b> </span>
                                    </div>
                                    <div>
                                      <nz-select class="model-select width80" nzMode="default" nzPlaceHolder="Assign Member" (ngModelChange)="majorAdvisorSelect($event)" [(ngModel)]="listOfMajorValue">
                                        <nz-option  nzLabel="HOD" nzValue="hod"></nz-option>
                                        <nz-option  nzLabel="Dean" nzValue="dean"></nz-option>
                                        <nz-option  nzLabel="Dean PGS" nzValue="dean_pgs"></nz-option>
                                        <!-- <nz-option  nzLabel="PG Coordinator" nzValue="pg_coordinator"></nz-option> -->
                                      </nz-select>
                                    </div> 
                                  </div>
                                  </div>
                              </div>
                              
                              </div>
                              
                          <div *nzModalFooter>
                            <button nz-button nzType="default" (click)="handleMemberCancel()">Cancel</button>
                            <button nz-button nzType="primary" (click)="assignApproversCall()" >Submit</button>
                          </div>
                        </nz-modal>
                    </div>
                    <div nz-col nzSpan="1">
                    </div>
                </div>                
            </div>
        </div>
        <div class="content-container" *ngIf="selectedMenu === 3">
            <div nz-row class="content-header">
                <span class="heading">
                    Manage Questions
                </span>
            </div>
            <div class="content-body">
                <div nz-row>
                    <div nz-col nzSpan="1">
                    </div>
                    <div nz-col nzSpan="22">
                      <div nz-row>
                        <div nz-col nzSpan="19" style="margin-bottom: 1%; text-align: left;">
                          Note: These questions are for external members.
                        </div>
                        <div nz-col nzSpan="5" style="margin-bottom: 1%; text-align: right;">
                          <button nz-button (click)="toggleAddQuestion()" nzType="primary">Add Question</button> 
                        </div>
                      </div>
                      <div nz-row *ngIf="showAddQuestion">
                            <div class="align-left" nz-col nzSpan="2"> Question
                            </div>
                            <div class="align-left" nz-col nzSpan="22">
                              <textarea  rows="4" nz-input [(ngModel)]="enteredQuestion" placeholder="Enter Question Here.."></textarea> <br>
                              <button style="margin-top: 1%;" (click)="handleAddQuestion()" nz-button nzType="primary">Add</button>
                              <button style="margin-top: 1%; margin-left: 1%;" (click)="toggleAddQuestion()" nz-button nzType="default">Cancel</button>
                            </div>
                      </div>
                        <div class="questions-table">
                        <nz-table #editRowTable nzBordered [nzData]="listOfDisplayQuestions" nzTableLayout="fixed">
                          <thead>
                            <tr>
                              <th nzWidth="60%">Question</th>
                              <th nzWidth="20%">Date</th>
                              <th nzWidth="20%">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let data of editRowTable.data">
                              <ng-container *ngIf="!editCache[data.question_id].edit; else editTemplate">
                                <td>{{ data.question_text }}</td>
                                <td>{{ data.created_date | date: 'longDate' }}</td>
                                <td><a (click)="startEdit(data.question_id)">Edit</a></td>
                              </ng-container>
                              <ng-template #editTemplate>
                                <td><textarea rows="3" nz-input [(ngModel)]="editCache[data.question_id].data.question_text"></textarea></td>
                                <td>{{ data.created_date | date: 'longDate' }}</td>
                                <td>
                                  <a (click)="saveEdit(data.question_id)" class="save">Save</a>
                                  <a (click)="cancelEdit(data.question_id)" class="save">Cancel</a>
                                </td>
                              </ng-template>
                            </tr>
                          </tbody>
                        </nz-table>
                        </div>
                    </div>
                    <div nz-col nzSpan="1">
                    </div>
                </div>                
            </div>
        </div>

        <div class="content-container" *ngIf="selectedMenu === 4">
          <div nz-row class="content-header">
            <span class="heading">
              Dashboard
            </span>
          </div>
          <div class="content-body">
            <div nz-row>
              <div nz-col nzSpan="1">
              </div>
              <div nz-col nzSpan="22">                
              <div nz-row>
                <div nz-col nzSpan="6" >
                  <nz-card class="dashboard-cards" >
                    <div nz-row nzJustify="space-around" nzAlign="middle">
                      <div nz-col nzSpan="8">
                        <i style="font-size: 3em; color: #fbc658;" nz-icon nzType="user" nzTheme="outline"></i>
                      </div>
                      <div nz-col nzSpan="16">
                        <span ><h4 style="color: #9a9a9a;font-size: 1rem;">Total students</h4></span><br>
                        <span><h4>{{total_students}}</h4></span>
                      </div>
                    </div>
                  </nz-card>
                </div>
                <div nz-col nzSpan="6" >
                  <nz-card class="dashboard-cards">
                    <div nz-row nzJustify="space-around" nzAlign="middle">
                      <div nz-col nzSpan="8">
                        <i style="font-size: 3em; color: #6bd098;" nz-icon nzType="file-done" nzTheme="outline"></i>
                      </div>
                      <div nz-col nzSpan="16">
                        <span>
                          <span class="cardTitle">Total thesis submitted</span>
                        </span><br>
                        <span>
                          <h4>{{total_thesis_submitted}}</h4>
                        </span>
                      </div>
                    </div>
                  </nz-card>
                </div>
                  <div nz-col nzSpan="6">
                    <nz-card class="dashboard-cards">
                      <div nz-row nzJustify="space-around" nzAlign="middle">
                        <div nz-col nzSpan="8">
                          <i style="font-size: 3em; color: #6bd098;" nz-icon nzType="file-done" nzTheme="outline"></i>
                        </div>
                        <div nz-col nzSpan="16">
                          <span>
                            <span class="cardTitle">Total thesis approved</span>
                          </span><br>
                          <span>
                            <h4>{{total_thesis_approved}}</h4>
                          </span>
                        </div>
                      </div>
                    </nz-card>
                  </div>
                <div nz-col nzSpan="6" >
                  <nz-card class="dashboard-cards">
                    <div nz-row nzJustify="space-around" nzAlign="middle">
                      <div nz-col nzSpan="8">
                        <i style="font-size: 3em; color: #6bd098;" nz-icon nzType="file-done" nzTheme="outline"></i>
                      </div>
                      <div nz-col nzSpan="16">
                        <span>
                          <span class="cardTitle">Total thesis &nbsp; pending</span>
                        </span><br>
                        <span>
                          <h4>{{total_thesis_pending}}</h4>
                        </span>
                      </div>
                    </div>
                  </nz-card>
                </div>
                <div nz-col nzSpan="6" >
                  <nz-card class="dashboard-cards">
                    <div nz-row nzJustify="space-around" nzAlign="middle">
                      <div nz-col nzSpan="8">
                        <i style="font-size: 3em; color: #6bd098;" nz-icon nzType="file-done" nzTheme="outline"></i>
                      </div>
                      <div nz-col nzSpan="16">
                        <span>
                          <span class="cardTitle">Total thesis &nbsp; rejected</span>
                        </span><br>
                        <span>
                          <h4>{{total_thesis_rejected}}</h4>
                        </span>
                      </div>
                    </div>
                  </nz-card>
                </div>
                <div nz-col nzSpan="6">
                  <nz-card class="dashboard-cards">
                    <div nz-row nzJustify="space-around" nzAlign="middle">
                      <div nz-col nzSpan="8">
                        <i style="font-size: 3em; color: #ef8157;" nz-icon nzType="field-time" nzTheme="outline"></i>
                      </div>
                      <div nz-col nzSpan="16">
                        <span>
                          <span class="cardTitle">Avg. age of thesis</span>
                        </span><br>
                        <span>
                          <h4>{{thesis_avg_age ? thesis_avg_age : '--'}}</h4>
                        </span>                       
                      </div>
                    </div>
                  </nz-card>
                </div>
              </div>    
 
              
              
              </div>
              <div nz-col nzSpan="1">
              </div>
            </div>        
          </div>        
        </div>
        <div class="content-container" *ngIf="selectedMenu === 5">
          <div nz-row class="content-header">
            <span class="heading">
              Archive Thesis
            </span>
          </div>
          <div class="content-body">
            <div nz-row>
              <div nz-col nzSpan="1">
              </div>
              <div nz-col nzSpan="22">

                <div nz-row>
                  <div nz-col nzSpan="18" style="margin-bottom: 1%; text-align: left;">
                   
                    <div class="search-filter">
                      <input style="width: 35%" type="text" nz-input placeholder="Search Thesis title/Student Name/ID"
                        [(ngModel)]="searchFilterValue" (keypress)="filterKeyEvent($event)" autocomplete="no-autosearcharchive" />
                      <button nz-button nzSize="small" nzType="primary" (click)="filterInternalData()" class="search-button ml-2">
                        Search
                      </button>
                      <button nz-button nzSize="small" (click)="resetFilter()">Reset</button>                    
                    </div>
                  </div>
                  <div nz-col nzSpan="6" style="margin-bottom: 1%; text-align: right;">
                    <span style="margin-right: 2%;"> {{ setOfCheckedId.length }} thesis selected</span>
                    <button nz-button nzType="primary" (click)="handleArchive()" [disabled]="setOfCheckedId.length === 0" [nzLoading]="loading">
                      Archive
                    </button>
                  </div>
                </div>
                <nz-table *ngIf="showThesisList" #rowSelectionTable nzShowPagination [nzPageSize]="[4]"  [nzData]="listOfTotalThesis">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Thesis Title</th>
                      <th>Thesis Status</th>
                      <th>Student Name</th>
                      <th>Student ID</th>
                      <th>Submitted Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let data of rowSelectionTable.data">
                      <td [nzChecked]="setOfCheckedId.includes(data.thesis_id)" [nzDisabled]="data.disabled"
                        (nzCheckedChange)="onItemChecked(data.thesis_id, $event)"></td>
                      <td>{{ data.thesis_name }}</td>
                      <td>{{ data.thesis_status }}</td>
                      <td>{{ data.student_name }}</td>
                      <td>{{ data.ref_no }}</td>
                      <td>{{ data.upload_datetime | date: 'longDate' }}</td>
                    </tr>
                  </tbody>
                </nz-table>        
              </div>
              <div nz-col nzSpan="1">
              </div>
            </div>        
          </div>        
        </div>

        <div class="content-container" *ngIf="selectedMenu === 6">
          <div nz-row class="content-header">
              <span class="heading">
                  Manage Users
              </span>
          </div>
          <div class="content-body">
              <div nz-row>
                  <div nz-col nzSpan="1">
                  </div>
                  <div nz-col nzSpan="22">
                    <div nz-row>
                      <div nz-col nzSpan="18" style="margin-bottom: 1%; text-align: left;">                         
                        <div class="search-filter">                            
                          <input style="width: 35%" type="text" nz-input placeholder="Search Student Name/Email/ID"
                            [(ngModel)]="searchFilterValue" (keypress)="filterKeyEvent($event)" autocomplete="no-autodep" />
                          <button nz-button nzSize="small" nzType="primary" (click)="filterApproversData()" class="search-button ml-2">
                            Search
                          </button>
                          <button nz-button nzSize="small" (click)="resetFilter()">Reset</button>                          
                        </div>
                      </div>
                    </div>                
                      <div>
                      <nz-table #editRowTable [nzPageSize]="[5]" [nzData]="listOfDisplayData" nzTableLayout="fixed">
                        <thead>
                          <tr>
                            <th >Name</th>
                            <th >Email</th>
                            <th >ID</th>
                            <th >Start Date</th>
                            <th >End Date</th>
                            <th >Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let data of editRowTable.data">
                            <ng-container *ngIf="!editUser[data.id].edit; else editUserTemplate">
                              <td>{{ data.name }}</td>
                              <td>{{ data.email}}</td>
                              <td>{{ data.ref_no }}</td>
                              <td>{{ data.reg_date | date: 'longDate' }}</td>
                              <td>{{ data.end_date | date: 'longDate' }}</td>
                              <td><a (click)="startEditUser(data.id)">Edit</a></td>
                            </ng-container>
                            <ng-template #editUserTemplate>
                              <!-- <td><textarea rows="3" nz-input [(ngModel)]="editUser[data.id].data.question_text"></textarea></td> -->
                              <td>{{ data.name }}</td>
                              <td>{{ data.email }}</td>
                              <td>{{ data.ref_no }}</td>
                              <td>{{ data.reg_date | date: 'longDate' }}</td>
                              <td><nz-date-picker [(ngModel)]="editUser[data.id].data.end_date" (ngModelChange)="onEndDateChange($event)"></nz-date-picker></td>
                              <td>
                                <a (click)="saveEditUSer(data.id)" class="save">Save</a>
                                <a (click)="cancelEditUser(data.id)" class="save">Cancel</a>
                              </td>
                            </ng-template>
                          </tr>
                        </tbody>
                      </nz-table>
                      </div>
                  </div>
                  <div nz-col nzSpan="1">
                  </div>
              </div>                
          </div>
      </div>

    </div>
</div>
