import { Component, OnInit, OnDestroy, OnChanges } from '@angular/core';
import { Router, ActivatedRoute, Event, NavigationStart, NavigationEnd, NavigationError, NavigationCancel } from '@angular/router';
import { BlockUIService } from './shared/services/block-ui.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SessionTimeoutModalComponent } from './shared/modals';
import { Keepalive } from '@ng-idle/keepalive';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { LoginService } from './modules/login/service/login.service';
import { environment } from '../environments/environment';
import { SessionService } from './shared/services/session.service';
import { CookieService } from 'ngx-cookie-service';
import { RouteUrls } from './shared/constants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Thesis Evaluation System';
  blockUI: boolean;
  modal: BsModalRef;
  cookieValue: any;
  islogin: boolean;

  constructor(public router: Router,
              // private idle: Idle,
              private keepalive: Keepalive,
              private session: SessionService,
              private loginService: LoginService,
              private modalService: BsModalService,
              private blockUIService: BlockUIService,
              private cookieService: CookieService) {

    // Time (sec) The user must be idle to get the pop up message
    // this.idle.setIdle(environment.SessionTimeout.IdleTime);
    // this.idle.setIdle(3600);

    // Time(sec) before the user is automatically logged out

    // this.idle.setTimeout(environment.SessionTimeout.WarningTime);
    // this.idle.setTimeout(120);

    // this.idle.onIdleStart.subscribe(() => {
      
    //   if (this.isLoginPage()) {
    //     return;
    //   } else {
    //     this.idle.setInterrupts([]);
    //     if (!this.modal) {
    //       this.openModal();
    //       (this.modal.content).onClose.subscribe(isExtend => {
    //         if (isExtend) {
    //           this.resetIdle();
    //         } else {
    //           this.logout();
    //         }
    //       });
    //     }
    //   }
    // });

    // Gets called every second after the user is idle
    // this.idle.onTimeoutWarning.subscribe((secondsRemaining) => {
      
    //   if (this.isLoginPage()) {
    //     return;
    //   } else {
    //     if (!this.modal) {
    //       this.openModal();
    //     }
    //     (this.modal.content).remainingSeconds = secondsRemaining;
    //     (this.modal.content).header = 'Session Timeout';
    //   }
    // });


    // This is called when user takes no action after the warning screen is up
    // this.idle.onTimeout.subscribe(() => {
    //   this.session.setLoginErrorMessage({
    //     alertType: 'sessiontimeout',
    //     alertMessage: 'inactivitytimeout'
    //   });
    //   this.logout();
    // });

    // Call extend token after every n seconds
    // this.keepalive.interval(environment.SessionTimeout.RefreshTokenTime);
    // this.keepalive.interval(1200);

    // Called everytime the extend token is triggerred, Regardless of whether the user is idle or not
    // this.keepalive.onPing.subscribe(() => {

    //   if (this.isLoginPage()) {
    //     return;
    //   }
    // });

    // Start the idle and keepAlive timers
    // this.resetIdle();

  }

  ngOnInit(): void {
    this.cookieValue = this.cookieService.get('token');
    
    this.router.events.subscribe((routerEvent: Event) => {
      this.checkRouterEvent(routerEvent);
    });

    this.blockUIService.status.subscribe((val: boolean) => {
      setTimeout(() => {
        this.blockUI = val;
      }, 10);
    });
  }

  checkRouterEvent(routerEvent: Event): void {
    if (routerEvent instanceof NavigationStart) {
      this.blockUIService.display(true);
    }
    if (routerEvent instanceof NavigationEnd ||
      routerEvent instanceof NavigationCancel ||
      routerEvent instanceof NavigationError) {
      this.blockUIService.display(false);
    }
  }

  extendToken() {
  }

  openModal() {
    // this.modal = this.modalService.show(SessionTimeoutModalComponent, {});
  }

  // closeModal() {
  //   if (this.modal) {
  //     this.modal.hide();
  //     (this.modal.content).onClose.unsubscribe();
  //   }
  //   delete this.modal;
  // }

  logout() {
    // this.resetIdle();
    this.loginService.logout();
  }

  // resetIdle() {
  //   this.closeModal();
  //   this.idle.stop();
  //   this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
  //   this.idle.watch();
  //   this.keepalive.start();
  // }

  isLoginPage(): boolean {
    this.islogin = localStorage.getItem('id') ? true : false;
    return (
      this.router.url === '/#'
    );
  }

}
