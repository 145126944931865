import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ExternalLandingModel} from '../model/external-landing.model';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject';
import { BehaviorSubject } from 'rxjs';
import 'rxjs/add/observable/of';
import { ServiceHandlerService } from '../../../shared/services/service-handler.service';
import { RoutePaths } from 'src/app/shared/constants';

@Injectable()
export class ExternalLandingService {
  footSubject: Subject<ExternalLandingModel> = new BehaviorSubject<ExternalLandingModel>(null);

  constructor(private serviceHandlerService: ServiceHandlerService, private router: Router) { }

  default(externalLandingDetails: ExternalLandingModel): Observable<ExternalLandingModel> {
    return this.serviceHandlerService.postParam<ExternalLandingModel>(`${RoutePaths.TRANSACTION}`, externalLandingDetails)
      .pipe(
        map((res: ExternalLandingModel) => {
          if (res) {
            this.footSubject.next(res);
          }
          return res;
        })
      );
  }

}
