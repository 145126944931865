import { Component, OnInit, TemplateRef, ElementRef, ViewChild, ViewEncapsulation  } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { HomeService } from '../service/home.service';
import { HomeModel } from '../model/home.model';
import { BlockUIService } from 'src/app/shared/services/block-ui.service';
import 'rxjs/add/operator/filter';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  @ViewChild('inputElement', { static: false }) inputElement?: ElementRef;
  selectedMenu = 0;
  registerForm: FormGroup;
  nameError = false;
  emailError = false;
  errorMsg: any;
  registerSucces = false;
  registerData: { mob_number: any;
                  ref_number: any;
                  user_email: any;
                  user_name: any;
                  user_role: any;
                  email: string;
                  department: string;
                  section: string;
                  college_name: string;
                };
  file_uploaded: any;
  multi_upload_role: string;
  listFileName = '';
  searchValue = '';
  visible = false;
  isVisible = false;
  listOfSelectedValue: string[] = [];
  listOfMajorValue: string[] = [];
  listOfAdvisorValue: string[] = [];
  listOfPGCoValue: string[] = [];
  listOfHodValue: string[] = [];
  listOfDeanValue: string[] = [];
  listOfExternalValue: string[] = [];
  listOfDeanPgsValue: string[] = [];
  studentMetaData: { email: string; };
  listOfData: Person[];
  listOfDisplayQuestions: Question[];
  editCache: { [key: string]: { edit: boolean; data: Question } } = {};
  editUser: { [key: string]: { edit: boolean; data: Person } } = {};
  listOfDisplayData: Person[];
  selectedName: any;
  selectedEmail: any;
  selectedRefNo: any;
  enteredQuestion: string;

  modalRef: BsModalRef;
  appoverListMetaData: { email: string; };
  listOfAdvisors: [{email: string, name: string, id: number, ref_no: string}];
  listOfMajorAdvisors: [{email: string, name: string, id: number, ref_no: string}];
  listOfDean: [{email: string, name: string, id: number, ref_no: string}];
  listOfHod: [{email: string, name: string, id: number, ref_no: string}];
  listOfPGCo: [{email: string, name: string, id: number, ref_no: string}];
  listOfExternals: [{email: string, name: string, id: number, ref_no: string}];
  listOfdean_pgs: [{email: string, name: string, id: number, ref_no: string}];
  majorAdvisorSelected: any;
  majorAdvisorRemoved: any[];
  // tslint:disable-next-line: max-line-length
  assignAppoverMetaData: { email: string; student_email: string; approver_info: { major_advisor: { removed: any[]; inserted: any[]; }; internal_member: { removed: any[]; inserted: any[]; }; hod: { removed: any[]; inserted: any[]; }; dean: { removed: any[]; inserted: any[]; }; dean_pgs: { removed: any[]; inserted: any[]; }; external_member: { removed: any[]; inserted: any[]; };}; };
  advisorSelected: any;
  pgCoSelected: any;
  hodSelected: any;
  deanSelected: any;
  externalSelected: any;
  advisorRemoved: any[];
  hodRemoved: any[];
  deanRemoved: any[];
  externalRemoved: any[];
  dean_pgsRemoved: any[];
  pgCoRemoved: any[];
  studentAppoverListMetaData: { email: string; student_email: any; };
  listOfMajorValueModel: any;
  listOfAdvisorValueModel: any;
  listOfHodValueModel: any;
  listOfDeanValueModel: any;
  listOfExternalValueModel: any;
  listOfDeamPgsValueModel: any;
  listOfPGCoValueModel: any;
  showAddQuestion: boolean;
  addQuestionMetaData: { email: string; question: string; };
  getQuestionMetaData: { email: string; };
  updateQuestionMetaData: { question_id: string; question: string; };

  checked = false;
  loading = false;
  indeterminate = false;
  listOfCurrentPageData: Data[] = [];
  setOfCheckedId = [];
  searchFilterValue = '';

  listOfTotalThesisData: Data[] = [];
  userExists: boolean;
  getReportsMetaData: { email: string; };
  thesis_avg_age: any;
  total_comments: any;
  total_students: any;
  total_thesis_approved: any;
  total_thesis_pending: any;
  total_thesis_rejected: any;
  total_thesis_submitted: any;
  getAllThesisMetaData: { email: string; };
  showThesisList: boolean = false;
  listOfTotalThesis: Data[];
  plagiarismTooltip: string;
  thesisPlagiarismScore = '';
  currentPlagiarismScore: any;
  updated_end_date: string;
  selectedMemberName: any;
  selectedMemberEmail: any;
  selectedMemberRefNo: any;
  isMemberVisible: boolean = false;
  deanpgsSelected: any;

  constructor(private fb: FormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              private homeService: HomeService,
              private modalService: BsModalService,
              private blockUIService: BlockUIService) { }

  // tslint:disable-next-line: typedef
  ngOnInit() {
    this.createForm();
    // this.getPlagiarismScore();
    this.getDashboardData();
    this.getStudentsList();
    this.getApproversList();
    this.handleGetQuestion();
    this.getAllThesisList();
  }

  isNotSelected(value: string): boolean {
    return this.listOfSelectedValue.indexOf(value) === -1;
  }

  advisorSelect(data): void  {
    this.advisorSelected = data;
  }

  majorAdvisorSelect(data): void  {
    this.majorAdvisorSelected = data;
    
  }
  PgCoSelect(data): void  {
    this.pgCoSelected = data;
    
  }
  hodSelect(data): void  {
    this.hodSelected = data;
    
  }
  deanSelect(data): void  {
    this.deanSelected = data;
    
  }
  externalSelect(data): void  {
    this.externalSelected = data;
  }

  deanPgsSelect(data): void  {
    this.deanpgsSelected = data;
  }

  createForm(): void {
    this.registerForm = this.fb.group(
      {
        user_name: [null, Validators.compose([Validators.required])],
        user_email: [null, Validators.compose([Validators.required, Validators.email])],
        mob_number: [null],
        department: [null],
        section: [null],
        college_name: [null],
        user_role: [null, [Validators.required]],
        ref_number: [null, [Validators.required]]
      }
    );

  }

  selectIndexmenu(value): void {
    this.selectedMenu = value;
  }

  reset(): void {
    this.searchValue = '';
    this.search();
  }

  search(): void {
    this.visible = false;
    // tslint:disable-next-line: max-line-length
    this.listOfDisplayData = this.listOfData.filter((item: Person) => item.name.toLowerCase().indexOf(this.searchValue.toLowerCase()) !== -1);
  }

  getStudentsList(): void {
    this.studentMetaData = {
      email: localStorage.getItem('email'),
    };
    this.homeService.getStudents(this.studentMetaData).subscribe(data => {
      
      if (data.status_code !== '01') {
        this.blockUIService.display(false);
        if(data.message == 'Invalid Request') {
          this.router.navigate(['/']);
        } else {
          alert('error');
        }
      }
      if (data.status_code === '01') {
        this.blockUIService.display(false);
        this.listOfData = data.student_data;
        this.listOfDisplayData = [...this.listOfData];
        this.updateEditCacheUser();
      }
    }, (err) => {
      this.blockUIService.display(false);
      alert('error');
    });
  }


  getApproversList(): void {
    this.appoverListMetaData = {
      email: localStorage.getItem('email'),
    };
    this.homeService.getApprovers(this.appoverListMetaData).subscribe(data => {
      
      if (data.status_code !== '01') {
        this.blockUIService.display(false);
        if(data.message == 'Invalid Request') {
          this.router.navigate(['/']);
        } else {
          alert('error');
        }
      }
      if (data.status_code === '01') {
        this.blockUIService.display(false);
        this.listOfAdvisors = data.advisor_data;
        this.listOfPGCo = data.advisor_data;
        this.listOfMajorAdvisors = data.advisor_data;
        this.listOfHod = data.advisor_data;
        this.listOfDean = data.advisor_data;
        this.listOfExternals = data.external_data;
        this.listOfdean_pgs = data.dean_pgs_data;
      }
    }, (err) => {
      this.blockUIService.display(false);
      alert('error');
    });
  }


  getStudentApproversCall(): void {
    this.studentAppoverListMetaData = {
      email: localStorage.getItem('email'),
      student_email: this.selectedEmail,
    };
    this.homeService.getStudentApprovers(this.studentAppoverListMetaData).subscribe(data => {
      if (data.status_code !== '01') {
        this.blockUIService.display(false);
        if(data.message == 'Invalid Request') {
          this.router.navigate(['/']);
        } else {
          alert('error');
        }
      }
      if (data.status_code === '01') {
        this.blockUIService.display(false);
        this.listOfMajorValue = data.advisor_info.major_advisor[0];
        this.listOfMajorValueModel = data.advisor_info.major_advisor;

        this.listOfAdvisorValue = data.advisor_info.internal_member;
        this.listOfAdvisorValueModel = data.advisor_info.internal_member;

        // this.listOfPGCoValue = data.advisor_info.pg_coordinator[0];
        // this.listOfPGCoValueModel = data.advisor_info.pg_coordinator;

        this.listOfHodValue = data.advisor_info.hod[0];
        this.listOfHodValueModel = data.advisor_info.hod;

        this.listOfDeanValue = data.advisor_info.dean[0];
        this.listOfDeanValueModel = data.advisor_info.dean;

        this.listOfExternalValue = data.advisor_info.external_member;
        this.listOfExternalValueModel = data.advisor_info.external_member;

        this.listOfDeanPgsValue = data.advisor_info.dean_pgs[0];
        this.listOfDeamPgsValueModel = data.advisor_info.dean_pgs;

        this.isVisible = true;
      }
    }, (err) => {
      this.blockUIService.display(false);
      alert('error');
    });
  }

  assignApproversCall(): void {
    this.assignAppoverMetaData = {
      email: localStorage.getItem('email'),
      student_email: this.selectedEmail,
      approver_info: {  major_advisor: {removed: [], inserted: []},
                        internal_member: {removed: [], inserted: []},
                        hod: {removed: [], inserted: []},
                        dean: {removed: [], inserted: []},
                        external_member: {removed: [], inserted: []},
                        dean_pgs: {removed: [], inserted: []},
                        // pg_coordinator: {removed: [], inserted: []},
                      }
    };

    this.majorAdvisorRemoved = [];
    let majorAdRm = this.listOfMajorValueModel;
    let majorAdIn = [this.listOfMajorValue];
    this.assignAppoverMetaData.approver_info.major_advisor.removed = majorAdRm;
    this.assignAppoverMetaData.approver_info.major_advisor.inserted = majorAdIn;

    this.advisorRemoved = [];
    let advisorRm = this.listOfAdvisorValueModel.filter(item => this.listOfAdvisorValue.indexOf(item) < 0 );
    let advisorIn = this.listOfAdvisorValue.filter(item => this.listOfAdvisorValueModel.indexOf(item) < 0 );
    this.assignAppoverMetaData.approver_info.internal_member.removed = advisorRm;
    this.assignAppoverMetaData.approver_info.internal_member.inserted = advisorIn;

    this.hodRemoved = [];
    let hodRm = this.listOfHodValueModel;
    let hodIn = [this.listOfHodValue];
    this.assignAppoverMetaData.approver_info.hod.removed = hodRm;
    this.assignAppoverMetaData.approver_info.hod.inserted = hodIn;

    this.deanRemoved = [];
    let deanRm = this.listOfDeanValueModel;
    let deanIn = [this.listOfDeanValue];
    this.assignAppoverMetaData.approver_info.dean.removed = deanRm;
    this.assignAppoverMetaData.approver_info.dean.inserted = deanIn;

    this.externalRemoved = [];
    let externalRm = this.listOfExternalValueModel.filter(item => this.listOfExternalValue.indexOf(item) < 0 );
    let externalIn = this.listOfExternalValue.filter(item => this.listOfExternalValueModel.indexOf(item) < 0 );
    this.assignAppoverMetaData.approver_info.external_member.removed = externalRm;
    this.assignAppoverMetaData.approver_info.external_member.inserted = externalIn;
    
    this.dean_pgsRemoved = [];
    let deanpgsRm = this.listOfDeamPgsValueModel
    let deanpgsIn = [this.listOfDeanPgsValue]
    this.assignAppoverMetaData.approver_info.dean_pgs.removed = deanpgsRm;
    this.assignAppoverMetaData.approver_info.dean_pgs.inserted = deanpgsIn;

    this.pgCoRemoved = [];
    let pgcoRm = this.listOfPGCoValueModel;
    let pgcoIn = [this.listOfPGCoValue];
    // this.assignAppoverMetaData.approver_info.pg_coordinator.removed = pgcoRm;
    // this.assignAppoverMetaData.approver_info.pg_coordinator.inserted = pgcoIn;



    
    this.homeService.assignApprovers(this.assignAppoverMetaData).subscribe(data => {
      if (data.status_code !== '01') {
        this.blockUIService.display(false);
        if(data.message == 'Invalid Request') {
          this.router.navigate(['/']);
        } else {
          alert('error');
        }
      }
      if (data.status_code === '01') {
        this.blockUIService.display(false);
        this.isVisible = false;
        alert('Approvers assigned successfully')
        this.listOfMajorValue = [];
        this.listOfAdvisorValue = [];
        this.listOfHodValue = [];
        this.listOfDeanValue = [];
        this.listOfExternalValue = [];
        this.listOfDeanPgsValue = [];
        this.listOfPGCoValue = [];
      }
    }, (err) => {
      this.blockUIService.display(false);
      alert('error');
    });
  }

  registerUser(): void {
    
    if (this.registerForm.get('user_name').errors) { this.nameError = true; return; }
    if (this.registerForm.get('user_email').errors) { this.emailError = true; return; }

    this.blockUIService.display(true);
    const homeModel: HomeModel = { ...this.registerForm.value };
    if (homeModel.user_email) {
      this.registerData = {
                            mob_number: homeModel.mob_number,
                            ref_number: homeModel.ref_number,
                            user_email: homeModel.user_email,
                            user_name: homeModel.user_name,
                            user_role: homeModel.user_role,
                            department: homeModel.department,
                            section: homeModel.section,
                            college_name: homeModel.college_name,
                            email: localStorage.getItem('email'),
                          };
      this.homeService.default(this.registerData).subscribe(data => {
        if (data.status_code !== '01') {
          this.blockUIService.display(false);
          this.userExists = false;
          this.errorMsg = true;
        }
        if (data.message === 'Email already registered') {
          this.blockUIService.display(false);
          this.errorMsg = false;
          this.userExists = true;
          }
        
        if (data.status === 'success') {
          this.blockUIService.display(false);
          this.registerForm.reset();
          this.errorMsg = false;
          this.userExists = false;
          this.registerSucces = true;
        }
      }, (err) => {
        this.blockUIService.display(false);
        alert('error');
      });
    }
  }

  resetFilter(): void {
    this.searchFilterValue = '';
    this.filterInternalData();
    this.filterApproversData();
  }

  filterInternalData(): void {
    this.listOfTotalThesis = this.listOfTotalThesisData.filter((item: Data) => {
      return item.student_name.toLowerCase().indexOf(this.searchFilterValue.toLowerCase()) !== -1 ||
        item.ref_no && item.ref_no.toLowerCase().indexOf(this.searchFilterValue.toLowerCase()) !== -1 ||
        item.thesis_name.toLowerCase().indexOf(this.searchFilterValue.toLowerCase()) !== -1;
    });
  }

  filterApproversData(): void {
    this.listOfDisplayData = this.listOfData.filter((item: Person) => {
      return item.name.toLowerCase().indexOf(this.searchFilterValue.toLowerCase()) !== -1 ||
        item.email.toLowerCase().indexOf(this.searchFilterValue.toLowerCase()) !== -1 ||
        item.ref_no && item.ref_no.toLowerCase().indexOf(this.searchFilterValue.toLowerCase()) !== -1;
    });
  }

  disableKey(e): void {
    e.stopPropagation()
    e.preventDefault()
  }

  HandleBrowseClick(): void {

    this.file_uploaded = null;
    var fileinput = document.getElementById('fileinput');
    var fileclick=fileinput.click();
    this.listFileName = '';
  }

  handleChange(files: FileList): void {
    this.file_uploaded = null;
    var re = /(?:\.([^.]+))?$/;
    if(files.item(0)){
        var x = re.exec(files.item(0).name)[1];
        if (x != 'csv') {
          alert('Only CSV files are allowed.');
          this.file_uploaded = null;
        }
        else {
          this.listFileName=files.item(0).name
          this.file_uploaded = files.item(0);
        }
    }
  }



  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.push(id);
    } else {
      const index = this.setOfCheckedId.indexOf(id);
      if (index > -1) {
        this.setOfCheckedId.splice(index, 1);
      }
    }
  }

  onCurrentPageDataChange(listOfCurrentPageData: Data[]): void {
    this.listOfCurrentPageData = listOfCurrentPageData;
  }

  refreshCheckedStatus(): void {
   }

  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  showModal(data): void {
    this.selectedName = data.name;
    this.selectedEmail = data.email;
    this.selectedRefNo = data.ref_no;
    this.getStudentApproversCall()
  }
  showMemberAssignModal(data): void {
    this.selectedMemberName = data.name;
    this.selectedMemberEmail = data.email;
    this.selectedMemberRefNo = data.ref_no;
    this.isMemberVisible = true;
  }

  handleOk(): void {
    this.isVisible = false;
  }

  handleCancel(): void {
    this.isVisible = false;
    this.listOfMajorValue = [];
    this.listOfAdvisorValue = [];
    this.listOfHodValue = [];
    this.listOfDeanValue = [];
    this.listOfExternalValue = [];
    this.listOfDeanPgsValue = [];
    this.listOfPGCoValue = [];
  }

  handleMemberCancel(): void {
    this.isMemberVisible = false;
  }

  filterKeyEvent(event) {
    var e =  event;  // get event object
    var key = e.keyCode // get key cross-browser
    if (((key>=33)
          &&(key<=44)||(key==94)||(key==61)||(key==91)||(key==92)||(key==93)||(key==47)||(key==123)
          ||(key==124)||(key==125)||(key==96)||(key==126)||(key>=58)&&(key<=63))) {
        if (e.preventDefault) e.preventDefault(); //normal browsers
        e.returnValue = false; //IE
    }
  }

  registerMultiUsers(): void {
    this.blockUIService.display(true);
    const formData: any = new FormData();
    formData.append('file', this.file_uploaded);
    formData.append('role', this.multi_upload_role);
    formData.append('email', localStorage.getItem('email'));
    
    if (this.file_uploaded) {
      this.homeService.regMultiUser(formData).subscribe(data => {
        
        if (data.status_code !== '01') {
          this.blockUIService.display(false);
          if(data.message == 'Invalid Request') {
            this.router.navigate(['/']);
          } else {
            alert('error');
          }
        }
        if (data.status === 'success') {
          this.blockUIService.display(false);
          this.registerSucces = true;
        }
      }, (err) => {
        this.blockUIService.display(false);
        alert('error');
      });
    }
  }

  startEdit(id: string): void {
    this.editCache[id].edit = true;
  }

  startEditUser(id: string): void {
    this.editUser[id].edit = true;
  }

  cancelEdit(id: string): void {
    const index = this.listOfDisplayQuestions.findIndex(item => item.question_id === id);
    this.editCache[id] = {
      data: { ...this.listOfDisplayQuestions[index] },
      edit: false
    };
  }

  cancelEditUser(id: string): void {
    const index = this.listOfDisplayData.findIndex(item => item.id === id);
    this.editUser[id] = {
      data: { ...this.listOfDisplayData[index] },
      edit: false
    };
  }

  saveEdit(id: string): void {
    this.updateQuestionMetaData = {
      question_id: id,
      question: this.editCache[id].data.question_text,
    };
    this.homeService.callUpdateQuestions(this.updateQuestionMetaData).subscribe(data => {
      
      if (data.status_code !== '01') {
        this.blockUIService.display(false);
        if(data.message == 'Invalid Request') {
          this.router.navigate(['/']);
        } else {
          alert('error');
        }
      }
      if (data.status_code === '01') {
        this.handleGetQuestion();
      }
    }, (err) => {
      this.blockUIService.display(false);
      alert('error');
    });
    
  }

  saveEditUSer(id: string): void {
    // const utcDate = this.editUser[id].data.end_date.toUTCString() // this.updated_end_date,  //this.editUser[id].data.end_date,
    const updateQuestionMetaData = {
      user_id: id,
      end_date: this.editUser[id].data.end_date,
    };
    this.homeService.callUpdateUser(updateQuestionMetaData).subscribe(data => {
      
      if (data.status_code !== '01') {
        this.blockUIService.display(false);
        if(data.message == 'Invalid Request') {
          this.router.navigate(['/']);
        } else {
          alert('error');
        }
      }
      if (data.status_code === '01') {
        this.getStudentsList();
      }
    }, (err) => {
      this.blockUIService.display(false);
      alert('error');
    });
    
  }

  updateEditCache(): void {
    this.listOfDisplayQuestions.forEach(item => {
      this.editCache[item.question_id] = {
        edit: false,
        data: { ...item }
      };
    });
  }

  updateEditCacheUser(): void {
    this.listOfDisplayData.forEach(item => {
      this.editUser[item.id] = {
        edit: false,
        data: { ...item }
      };
    });
  }

  toggleAddQuestion(): void {
    this.showAddQuestion = this.showAddQuestion ? false : true;
  }

  handleAddQuestion(): void {
    this.addQuestionMetaData = {
      email: localStorage.getItem('email'),
      question: this.enteredQuestion,
    };
    this.homeService.callAddQuestions(this.addQuestionMetaData).subscribe(data => {
      
      if (data.status_code !== '01') {
        this.blockUIService.display(false);
        if(data.message == 'Invalid Request') {
          this.router.navigate(['/']);
        } else {
          alert('error');
        }
      }
      if (data.status_code === '01') {
        this.handleGetQuestion();
      }
    }, (err) => {
      this.blockUIService.display(false);
      alert('error');
    });
  }

  handleArchive(): void {
    const archiveMetaData = {
      email: localStorage.getItem('email'),
      thesis_id_list: this.setOfCheckedId,
    };
    this.homeService.handleArchiveThesis(archiveMetaData).subscribe(data => {
      
      if (data.status_code !== '01') {
        this.blockUIService.display(false);
        if(data.message == 'Invalid Request') {
          this.router.navigate(['/']);
        } else {
          alert('error');
        }
      }
      if (data.status_code === '01') {
        this.setOfCheckedId = [];
        this.getAllThesisList();
      }
    }, (err) => {
      this.blockUIService.display(false);
      alert('error');
    });
  }

  getDashboardData(): void {
    this.getReportsMetaData = {
      email: localStorage.getItem('email')
    };
    this.homeService.getReportsCount(this.getReportsMetaData).subscribe(data => {
      
      if (data.status_code !== '01') {
        this.blockUIService.display(false);      }
      if (data.status_code === '01') {
        this.thesis_avg_age = data.report_counts.thesis_avg_age;
        this.total_comments = data.report_counts.total_comments;
        this.total_students = data.report_counts.total_students;
        this.total_thesis_approved = data.report_counts.total_thesis_approved;
        this.total_thesis_pending = data.report_counts.total_thesis_pending;
        this.total_thesis_rejected = data.report_counts.total_thesis_rejected;
        this.total_thesis_submitted = data.report_counts.total_thesis_submitted;
      }
    }, (err) => {
      this.blockUIService.display(false);
      alert('error');
    });
  }

  handleGetQuestion(): void {
    this.getQuestionMetaData = {
      email: localStorage.getItem('email'),
    };
    this.homeService.callGetQuestions(this.getQuestionMetaData).subscribe(data => {
      
      if (data.status_code !== '01') {
        this.blockUIService.display(false);
        if(data.message == 'Invalid Request') {
          this.router.navigate(['/']);
        } else {
          alert('error');
        }
      }
      if (data.status_code === '01') {
        this.listOfDisplayQuestions = data.questions;
        this.updateEditCache();
      }
    }, (err) => {
      this.blockUIService.display(false);
      alert('error');
    });
  }

  getAllThesisList(): void {
    this.getAllThesisMetaData = {
      email: localStorage.getItem('email'),
    };
    this.homeService.getAllThesisList(this.getAllThesisMetaData).subscribe(data => {
      
      if (data.status_code !== '01') {
        this.blockUIService.display(false);
        if(data.message == 'Invalid Request') {
          this.router.navigate(['/']);
        } else {
          alert('error');
        }
      }
      if (data.status_code === '01') {
        this.listOfTotalThesisData = data.thesis_list;
        this.listOfTotalThesis = [...this.listOfTotalThesisData];
        this.showThesisList = true;
      }
    }, (err) => {
      this.blockUIService.display(false);
      alert('error');
    });
  }

  onChangePlgScore(value: string): void {
    if (value === '') {
      this.plagiarismTooltip = 'Enter plagiarism percentage';
      return
    }
    if (parseInt(value) > 20) {
    } else {
    }
    
    this.updateValue(value);
  }

  onBlurPlgScore(): void {
    if (this.thesisPlagiarismScore.charAt(this.thesisPlagiarismScore.length - 1) === '.' || this.thesisPlagiarismScore === '-') {
      this.updateValue(this.thesisPlagiarismScore.slice(0, -1));
    }
  }

  onfocusPlgScore() {
    console.log('');
  }

  updateValue(value: string): void {
    const reg = /^-?(0|[1-9][0-9]*)?$/;
    if ((!isNaN(+value) && reg.test(value)) || value === '' || value === '-') {
      this.thesisPlagiarismScore = value;
    }
    this.inputElement!.nativeElement.value = this.thesisPlagiarismScore;
    this.updateTitle();
  }

  updateTitle(): void {
    this.plagiarismTooltip = this.thesisPlagiarismScore ? this.thesisPlagiarismScore + ' %' : 'Enter plagiarism score';
  }

  handleUpdateScore(): void {
    if(!this.thesisPlagiarismScore) {return};
    const updateScoreMetaData = {
      email: localStorage.getItem('email'),
      score: this.thesisPlagiarismScore
    };
    this.homeService.handleUpdatePlagiarismScore(updateScoreMetaData).subscribe(data => {
      
      if (data.status_code !== '01') {
        this.blockUIService.display(false);
        if(data.message == 'Invalid Request') {
          this.router.navigate(['/']);
        } else {
          alert('error');
        }
      }
      if (data.status_code === '01') {
        //handle update plagiarism success
        this.getPlagiarismScore();
      }
    }, (err) => {
      this.blockUIService.display(false);
      alert('error');
    });
  }


  getPlagiarismScore(): void {
    const getPlagiarismScoreMetaData = {
      email: localStorage.getItem('email')
    };
    this.homeService.handleGetPlagiarismScore(getPlagiarismScoreMetaData).subscribe(data => {
      
      if (data.status_code !== '01') {
        this.blockUIService.display(false);
        if(data.message == 'Invalid Request') {
          this.router.navigate(['/']);
        } else {
          alert('error');
        }
      }
      if (data.status_code === '01') {
        this.currentPlagiarismScore = data.score;
      }
    }, (err) => {
      this.blockUIService.display(false);
      alert('error');
    });
  }

  onEndDateChange(result: Date): void {
    this.updated_end_date = result.toUTCString();
  }
  

}

interface Person {
  id: string;
  name: string;
  email: string;
  ref_no: string;
  college_name: string;
  department: string;
  section: string;
  end_date: any;
  reg_date: any;
}

interface Question {
  question_id: string;
  question_text: string;
  created_date: string;
}

export interface Data {
  thesis_id: number;
  thesis_name: string;
  student_name: string;
  ref_no: string;
  thesis_status: string;
  upload_datetime: string;
  disabled: boolean;
}
