import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HomeComponent } from './component/home.component';
import { AdminSidebarComponent } from './admin-sidebar/admin-sidebar.component';
import { UserHomeComponent } from './user-component/user-component.component';
import { DocDetailsComponent } from './doc-details/doc-details.component';
import { HomeService } from './service/home.service';
import { SideNavService } from './service/side-nav.service';
import { HomeRoutingModule } from './home.routing.module';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from 'src/app/shared/shared.module';
import {MatGridListModule} from '@angular/material/grid-list';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';



import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NzCardModule } from 'ng-zorro-antd/card';
import { TimelineComponent } from './timeline/timeline.component';
import { DrawerComponent } from './drawer/drawer.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ViewDocumentComponent } from './view-doc/view-doc.component';






@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    HomeRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    MatGridListModule,
    NzGridModule,
    NzButtonModule,
    NzLayoutModule,
    NzBreadCrumbModule,
    NzMenuModule,
    NzFormModule,
    NzSelectModule,
    NzIconModule,
    NzUploadModule,
    NzInputModule,
    NzDropDownModule,
    NzTableModule,
    NzModalModule,
    NzTabsModule,
    NzDrawerModule,
    NzToolTipModule,
    MatSidenavModule,
    NzAlertModule,
    NzDatePickerModule,
    PdfViewerModule,
    NzCardModule,
    NgxDropzoneModule
    // ModalModule
  ],
  declarations: [HomeComponent, AdminSidebarComponent, UserHomeComponent, DocDetailsComponent, TimelineComponent, DrawerComponent, ViewDocumentComponent],
  providers: [HomeService, SideNavService, MatSidenavModule]
})
export class HomeModule { }
