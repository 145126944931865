import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { SessionService } from '../services/session.service';
import { HelperService } from '../services/helper.service';
import { LoginService } from '../../../app/modules/login/service/login.service';
import { Observable } from 'rxjs';


@Injectable({providedIn: 'root'})
export class MasterGuard implements CanActivate {

    constructor(private router: Router,
                private sessionService: SessionService,
                private loginService: LoginService,
                private helperService: HelperService) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const url: string = state.url;
        return this.checkLogin(url);
    }

    checkLogin(url: string) {
        
        if (localStorage.getItem('id')) {
            return true
        } else {
            this.router.navigate(['/']);
            return false
        }
    }
}
