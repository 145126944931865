<div class="modal-header">
  <h4 class="modal-title pull-left">{{ header }}</h4>
  <button type="button" class="close pull-right" (click)="onCancel()" aria-label="Close">
    <span aria-hidden="true"><img src="assets/images/modal-close.png" alt="" width="12" height="13"></span>
  </button>
</div>
<div class="modal-body">
  <p>You are being signed out due to inactivity. You will be logged out in <span>{{ remainingSeconds }}</span> seconds. <br />
    Do you want to stay signed in? </p>
</div>
<div class="modal-footer">
  <button class="white" (click)="onCancel()">NO</button>
  <button class="green" (click)="onConfirm()">YES</button>
</div>