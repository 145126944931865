import { Component, OnInit, ElementRef, ViewChild, ViewEncapsulation  } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HomeService } from '../service/home.service';
import { BlockUIService } from 'src/app/shared/services/block-ui.service';
import 'rxjs/add/operator/filter';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-doc-details',
  templateUrl: './doc-details.component.html',
  styleUrls: ['./doc-details.component.scss']
})
export class DocDetailsComponent implements OnInit {
  role: string;
  enteredScore: any;
  permission: string;
  overrideAnswer: string;
  newThesisTitle: string;
  isOverrideVisible = false;
  isConfirmLoading = false;
  enableEditThesis = false;
  requireThesisName = false;
  plagiarismCheckComplete = false;
  thesisPlagiarismScore = '';
  plagiarismTooltip = 'Enter Plagiarism Score';
  reUploadfiles: File[] = [];
  PlagReportfiles: File[] = [];
  files: File[] = [];

  @ViewChild('inputElement', { static: false }) inputElement?: ElementRef;
  @ViewChild('file') file;
  @ViewChild('Reportfile') Reportfile;

  listOfData: Person[] = [];
  failureMsg: any;
  thesisId: any;
  thesisName: any;
  StudentName: any;
  StudentID: any;
  ThesisVersion: any;
  plagiarismErrorScore = false;
  disableUploadPlgReport = true;
  currentTimeLineStateValue: any;
  confirmModal?: NzModalRef;
  modalTitle: string;
  uploadReportMsg = false;
  uploadRevisedVersionMsg = false;
  currentTimeLineState: any;
  triggerByReport: number = 0;
  triggerBysubmitIM: number = 0;
  currentTimeLineLevel: any;
  currentTimeLineStatus: any;
  updatedTitle: boolean = false;
  tokenEmail: string;
  totComments: any;
  accpComments: any;
  rejtComments: any;
  pendComments: number;
  uploadRevisedToolTip: string;
  submitLevelToolTip: string;
  currentTimeLineSubmitted: any;
  uploadRevisedToolTipIM: string;
  submitLevelToolTipIM: string;
  isRevisedVerIM = true;
  uploadRevisedToolTipHOD: string;
  submitLevelToolTipHOD: string;
  uploadRevisedToolTipDe: string;
  submitLevelToolTipDe: string;
  submitLevelToolTipEM: string;
  uploadRevisedToolTipEM: string;
  selectedRole: any;
  selectedAdvisorType: any;
  selectedAdvisorEmail: any;
  currentTimeLineCurrentState: any;
  currentTimeLineComment: any;
  hiderevisedUpload: boolean = false;
  revisedVersionUploaded: boolean = false;
  plagiarismErrorEntered: boolean = false;
  isMajorAdvisorPending:  any;
  isInternalMemberPending: any;
  isExternalMemberPending: any;
  currentPlagiarismScore: any;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private homeService: HomeService,
              private blockUIService: BlockUIService,
              private modal: NzModalService) { }

  ngOnInit() {
    this.tokenEmail = localStorage.getItem('email');
    this.route.params.subscribe(params => {
      
      if (params.id) {
        if (params.id === '1') {
          this.role = 'major_advisor';
          this.thesisId = params.thesis;
        } else if (params.id === '2') {
          this.role = 'pg_coordinator';
          this.thesisId = params.thesis;
        } else if (params.id === '3') {
          this.role = 'student';
          this.thesisId = params.thesis;
        } else if (params.id === '4') {
          this.role = 'dean_pgs';
          this.thesisId = params.thesis;
        }
      } else {
        this.role = localStorage.getItem('role');
      }
    });
    // this.getPlagiarismScore();
    this.getThesisDetailsData();
    // this.getThesisAdvisorInfo();
  }


  handleUpdateThesis(): void {
    this.blockUIService.display(true);
    const selectedThesis = { thesis_id: this.thesisId, newTitle: this.newThesisTitle };
    if (this.newThesisTitle) {
      this.homeService.updateThesisTitle(selectedThesis).subscribe(data => {
        
        this.blockUIService.display(false);
        if (data.status_code === '01') {
          this.thesisName = this.newThesisTitle;
          this.enableEditThesis = false;
          this.requireThesisName = false;
        }
      }, (err) => {
        this.blockUIService.display(false);
        this.failureMsg = 'Something went wrong. Please Try again';
      });
    } else {
      this.blockUIService.display(false);
      this.requireThesisName = true;
    }
  }

  getDocumentDetails(): void {
    this.blockUIService.display(true);
    if (this.role) {
      // Call '' service
      this.homeService.getDocDetails('this.thesisTitle').subscribe(data => {
        this.blockUIService.display(false);
        if (data.status_code === '01') {
        } else {
          this.failureMsg = data.message;
        }
      }, (err) => {
        this.blockUIService.display(false);
        this.failureMsg = 'Something went wrong. Please Try again';
      });
    } else {
      this.failureMsg = 'Thesis Name is required.';
    }
  }


  handleTimelineData(): void {
    this.blockUIService.display(true);
    if (this.role) {
      this.homeService.getTimelineDetails('this.thesisTitle').subscribe(data => {
        this.blockUIService.display(false);
        if (data.status_code === '01') {
        } else {
          this.failureMsg = data.message;
        }
      }, (err) => {
        this.blockUIService.display(false);
        this.failureMsg = 'Something went wrong. Please Try again';
      });
    } else {
      this.failureMsg = 'Thesis Name is required.';
    }
  }

  handleTimelineListData(): void {
    this.blockUIService.display(true);
    if (this.role) {
      this.homeService.getTimelineListDetails('this.thesisTitle').subscribe(data => {
        this.blockUIService.display(false);
        if (data.status_code === '01') {
        } else {
          this.failureMsg = data.message;
        }
      }, (err) => {
        this.blockUIService.display(false);
        this.failureMsg = 'Something went wrong. Please Try again';
      });
    } else {
      this.failureMsg = 'Thesis Name is required.';
    }
  }

  getThesisDetailsData(): void {
    this.blockUIService.display(true);
    const selectedThesisId = { thesis_id: this.thesisId }
    this.homeService.getThesisDetails(selectedThesisId).subscribe(data => {
      
      this.blockUIService.display(false);
      if (data.status_code === '01') {
        this.thesisName = data.thesis_data.thesis_name;
        this.StudentName = data.thesis_data.name;
        this.StudentID = data.thesis_data.ref_no;
        this.ThesisVersion = data.thesis_data.version;
        this.newThesisTitle = data.thesis_data.thesis_name;

      }
    }, (err) => {
      this.blockUIService.display(false);
    });

  }

  getThesisAdvisorInfo(): void {
    this.blockUIService.display(true);
    const advisorInfoMetaData = { email: localStorage.getItem('email'), thesis_id: this.thesisId }
    this.homeService.getAdvisorInfoList(advisorInfoMetaData).subscribe(data => {
      
      this.blockUIService.display(false);
      if (data.status_code === '01') {
        this.listOfData = data.thesis_info.advisor_list;
        
        this.isMajorAdvisorPending = this.listOfData.filter(o => {
          if(o.advisor_type === 'major_advisor' && o.review_status === 'pending') {return true;}
        })
        this.isInternalMemberPending = this.listOfData.filter(o => {
          if(o.advisor_type === 'internal_member' && o.review_status === 'pending') {return true; }
        })
        this.isExternalMemberPending = this.listOfData.filter(o => {
          if(o.advisor_type === 'external_member' && o.review_status === 'pending') { return true;}
        })
        
        this.totComments = this.listOfData.map(o => o.total_comments).reduce((a, c) => { return a + c });
        this.accpComments = this.listOfData.map(o => o.accepted_comments).reduce((a, c) => { return a + c });
        this.rejtComments = this.listOfData.map(o => o.rejected_comments).reduce((a, c) => { return a + c });
        this.pendComments = this.totComments - (this.accpComments + this.rejtComments);

        if(this.isMajorAdvisorPending.length > 0 || this.isInternalMemberPending.length > 0) {
          this.uploadRevisedToolTipIM = 'You can upload only after all internal members have submitted their review.'
        }
        if(this.isExternalMemberPending.length > 0) {
          this.uploadRevisedToolTipEM = 'You can upload only after all external members have submitted their review.'
        }

        if(this.pendComments > 0) {
          this.uploadRevisedToolTip = 'Resolve all comments to upload'
          this.uploadRevisedToolTipIM = 'Resolve all comments from internal members to upload revised version'
          this.uploadRevisedToolTipHOD = 'Resolve all comments from HOD to upload revised version'
          this.uploadRevisedToolTipDe = 'Resolve all comments from dean to upload revised version'
          this.uploadRevisedToolTipEM = 'Resolve all comments from external members to upload revised version'
          this.submitLevelToolTip = 'Resolve all comments to submit'
          this.submitLevelToolTipIM = 'Resolve all comments from internal members and upload revised version of your thesis to submit'
          this.submitLevelToolTipHOD = 'Resolve all comments from HOD and upload revised version of your thesis to submit'
          this.submitLevelToolTipDe = 'Resolve all comments from dean and upload revised version of your thesis to submit'
          this.submitLevelToolTipEM = 'Resolve all comments from external members and upload revised version of your thesis to submit'
        }
        if(this.isRevisedVerIM === true) {
          this.submitLevelToolTipIM = 'Please upload revised version of the thesis to submit to the next level'
          this.submitLevelToolTipHOD = 'Please upload revised version of the thesis to submit to the next level'
          this.submitLevelToolTipDe = 'Please upload revised version of the thesis to submit to the next level'
          this.submitLevelToolTipEM = 'Please upload revised version of the thesis to submit to the next level'
        } else {
          this.submitLevelToolTipIM = '';
          this.submitLevelToolTipHOD = '';
          this.submitLevelToolTipDe = '';
          this.submitLevelToolTipEM = '';
        }
        
      }
    }, (err) => {
      this.blockUIService.display(false);
    });

  }

  getcurrentTimeLine(state): void {    
    this.currentTimeLineStateValue = state;
    this.currentTimeLineLevel = state.level_id;
    this.currentTimeLineStatus = state.status;
    this.currentTimeLineCurrentState = state.state;
    if(state?.submitted) {
      this.currentTimeLineSubmitted = state.submitted;
    } else {
      this.currentTimeLineSubmitted = false
    }

    if(state?.comments) {
      this.currentTimeLineComment = state.comments;
    } else {
      this.currentTimeLineComment = ''
    }

  }

  onChangePlgScore(value: string): void {
    if (value === '') {
      this.disableUploadPlgReport = true;
      this.plagiarismErrorScore = false;
      this.plagiarismTooltip = 'Enter plagiarism score';
      return
    }
    if (parseInt(value) > parseInt(this.currentPlagiarismScore)) {
      this.plagiarismErrorScore = true;
      this.disableUploadPlgReport = true;
      this.hiderevisedUpload = false;
    } else {
      this.plagiarismErrorScore = false;
      this.disableUploadPlgReport = false;
      this.hiderevisedUpload = false;
    }
    
    this.updateValue(value);
  }

  selectFileRevisedThesis() {
    this.reUploadfiles = [];
    this.file.nativeElement.click()
  }

  selectFileplagReport() {
    this.PlagReportfiles = [];
    this.Reportfile.nativeElement.click()
  }

  onReUploadThesis(event, purpose): void {
    const reUploadedfiles: { [key: string]: File } = this.file.nativeElement.files;
    for (let key in reUploadedfiles) {
      if (!isNaN(parseInt(key))) {
        this.reUploadfiles.push(reUploadedfiles[key])
      }
    }
    this.showReuploadThesisConfirm('revised', this.reUploadfiles[0].name, purpose);
    
  }

  onPlagReport(event, purpose) {    
    const PlagRepedfiles: { [key: string]: File } = this.Reportfile.nativeElement.files;
    for (let key in PlagRepedfiles) {
      if (!isNaN(parseInt(key))) {
        this.PlagReportfiles.push(PlagRepedfiles[key])
      }
    }
    this.showReuploadThesisConfirm('report', this.PlagReportfiles[0].name, purpose);
    
  }

  submitToIM(): void {
    this.blockUIService.display(true);
    const submitToIMMetadata = { thesis_id: this.thesisId, email: localStorage.getItem('email')};
    this.homeService.callSubmitToIM(submitToIMMetadata).subscribe(data => {
      
      this.blockUIService.display(false);
      if (data.status_code === '01') {
        this.uploadRevisedVersionMsg = false;
        this.triggerBysubmitIM = this.triggerBysubmitIM + 1;
        this.triggerByReport = this.triggerByReport + 1;
        this.getThesisAdvisorInfo();
      }
    }, (err) => {
      this.blockUIService.display(false);
    });
  }

  showReuploadThesisConfirm(eventType, docName, purpose): void {
    if (eventType === 'revised') {
      this.modalTitle = 'Are you sure you want to upload the revised version of the thesis?';
    } else {
      this.modalTitle = 'Are you sure you want to upload Plagiarism report of the thesis?';
    }
    this.confirmModal = this.modal.confirm({
      nzTitle: this.modalTitle,
      nzContent: 'Selected file: ' + docName,
      nzOkText: 'Upload',
      nzCancelText: 'Cancel',
      nzOnCancel: () => {
        this.reUploadfiles = [];
        this.PlagReportfiles = [];        
      },
      nzOnOk: () => {
        if (eventType === 'revised') {
          const formData: FormData = new FormData()
          formData.append('file', this.reUploadfiles[0]);
          formData.append('email', localStorage.getItem('email'));
          formData.append('thesis_id', this.thesisId);
          this.blockUIService.display(true);
          if (this.thesisId) {
            this.homeService.UploadThesis(formData).subscribe(data => {
              this.blockUIService.display(false);              
              if (data.status_code === '01') {
                if(purpose === 'plagiarismCheck') {
                  this.revisedVersionUploaded = true;
                }
                this.uploadRevisedVersionMsg = true;
                this.updatedTitle = true;
                this.thesisPlagiarismScore = '';
                this.plagiarismErrorScore = false;
                this.reUploadfiles = [];
                this.getThesisDetailsData();
                // this.getThesisAdvisorInfo();
                this.isRevisedVerIM = false
              } else {
                this.failureMsg = data.message;
              }
            }, (err) => {
              this.blockUIService.display(false);
              this.failureMsg = 'Something went wrong. Please Try again';
            });
          } else {
            this.failureMsg = 'Thesis is required.';
          }
        } else {
          const formData: FormData = new FormData()
          formData.append('file', this.PlagReportfiles[0]);
          formData.append('email', localStorage.getItem('email'));
          formData.append('score', this.thesisPlagiarismScore);
          formData.append('thesis_id', this.thesisId);
  
          this.blockUIService.display(true);
  
          this.homeService.UploadPlagiarismReport(formData).subscribe(data => {
            this.blockUIService.display(false);
            
            if (data.status_code === '01') {
              this.uploadReportMsg = true;
              this.triggerByReport = this.triggerByReport + 1;
              // this.getThesisAdvisorInfo();
            } else {
              this.plagiarismErrorEntered = true;
            }
          }, (err) => {
            this.blockUIService.display(false);
            alert('Error');
          });
        }


      
      }
    });
  }

  onBlurPlgScore(): void {
    if (this.thesisPlagiarismScore.charAt(this.thesisPlagiarismScore.length - 1) === '.' || this.thesisPlagiarismScore === '-') {
      this.updateValue(this.thesisPlagiarismScore.slice(0, -1));
    }
  }

  filterKeyEvent(event) {
    var e =  event;  // get event object
    var key = e.keyCode // get key cross-browser
    if (((key>=33)
          &&(key<=44)||(key==94)||(key==61)||(key==91)||(key==92)||(key==93)||(key==47)||(key==123)
          ||(key==124)||(key==125)||(key==96)||(key==126)||(key>=58)&&(key<=63))) {
        if (e.preventDefault) e.preventDefault(); //normal browsers
        e.returnValue = false; //IE
    }
  }

  updateValue(value: string): void {
    const reg = /^-?(0|[1-9][0-9]*)?$/;
    if ((!isNaN(+value) && reg.test(value)) || value === '' || value === '-') {
      this.thesisPlagiarismScore = value;
    }
    this.inputElement!.nativeElement.value = this.thesisPlagiarismScore;
    this.updateTitle();
  }

  updateTitle(): void {
    
    this.plagiarismTooltip = this.thesisPlagiarismScore ? this.thesisPlagiarismScore + ' %' : 'Enter plagiarism score';
  }

  enableEditThesisCall(): void {
    this.enableEditThesis = true;
    this.newThesisTitle = this.thesisName;
  }

  hideEditThesisCall(): void {
    this.enableEditThesis = false;
    this.requireThesisName = false;
  }

  viewDoc(data) {
    if(data.advisor_type === 'external_member') {
      localStorage.setItem('externalName', data.name);
    }
    if (data.review_status === 'submitted' || data.email === localStorage.getItem('email')) {
      this.router.navigate(['/view', { id: data.email, role: data.advisor_type, thesis: this.thesisId, ver: data.version }]);

    }
  }

  routeTo(): void {
    this.router.navigate(['/home']);
  }

  showOverrideModal(value): void {
    this.selectedRole = value.advisor_type_verbose;
    this.selectedAdvisorType = value.advisor_type;
    this.selectedAdvisorEmail = value.email;
    this.isOverrideVisible = true;
  }

  handleOverrideOk(): void {
    this.blockUIService.display(true);
    const handleOverrideMetadata = {  thesis_id: this.thesisId,
                                      advisor_type: this.selectedAdvisorType,
                                      advisor_email: this.selectedAdvisorEmail,
                                      email: localStorage.getItem('email')};
    this.homeService.callOverride(handleOverrideMetadata).subscribe(data => {
      this.blockUIService.display(false);
      if (data.status_code === '01') {
        this.overrideAnswer = '';
        this.selectedAdvisorType = '';
        this.selectedRole = '';
        this.selectedAdvisorEmail = '';
        this.uploadRevisedVersionMsg = false;
        this.triggerBysubmitIM = this.triggerBysubmitIM + 1;
        // this.getThesisAdvisorInfo();
        this.isOverrideVisible = false;
      }
    }, (err) => {
      this.blockUIService.display(false);
    });
  }
  

  handleOverrideCancel(): void {
    this.isOverrideVisible = false;
  }

  getPlagiarismScore(): void {
    const getPlagiarismScoreMetaData = {
      email: localStorage.getItem('email')
    };
    this.homeService.handleGetPlagiarismScore(getPlagiarismScoreMetaData).subscribe(data => {
      
      if (data.status_code !== '01') {
        alert('error');
        this.blockUIService.display(false);
      }
      if (data.status_code === '01') {
        this.currentPlagiarismScore = data.score;
      }
    }, (err) => {
      this.blockUIService.display(false);
      alert('error');
    });
  }

}

interface Person {
  key: string;
  name: string;
  email: string;
  advisor_type_verbose: string;
  advisor_type: string;
  review_status: string;
  total_comments: string;
  accepted_comments: string;
  rejected_comments: string;
  pending: string;
  version: number;
}
