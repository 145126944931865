<div class="modal-header">
  <h4 class="modal-title pull-left">{{modalTitle}}</h4>
  <button type="button" class="close pull-right" (click)="close()" aria-label="Close">
    <span aria-hidden="true"><img src="assets/images/modal-close.png" alt="" width="12" height="13"></span>
  </button>
</div>
<div class="modal-body" [innerHTML]="modalMsg">
  <!-- <p>{{modalMsg}}</p> -->
</div>
<div class="modal-footer">
  <button class="green" (click)="close()">OK</button>
</div>