<div nz-row class="home-header-row">
  <div nz-col nzSpan="24">
      <app-header></app-header>
  </div>
</div>
<div>

  <div nz-row class="home-content-row">
    <div nz-col nzSpan="1" class="">
  </div>
  <div nz-col nzSpan="22">
    <div nz-row >
      <div class="row-heading">
        <nz-breadcrumb>
          <nz-breadcrumb-item>
            <a (click)="routeTo()">Home</a>
          </nz-breadcrumb-item>
          <nz-breadcrumb-item>
            Thesis details
          </nz-breadcrumb-item>
        </nz-breadcrumb>
      </div>
    </div>
    <div nz-row >
    <div nz-col nzSpan="12">
       
        <p class="thesis-details"><span class="row-heading">Student:</span><span class="row-title"> {{StudentName}} ({{StudentID}})</span></p>       
        <p style="min-height: 35px;" ><span class="row-heading">Thesis:</span><span class="row-title">
           <span *ngIf="role !== 'student'"> {{thesisName}}</span>
           <span *ngIf="!enableEditThesis && role === 'student'" (click)="enableEditThesisCall()"> {{thesisName}}</span>
           <span *ngIf="enableEditThesis">
             <input nz-col nzSpan="11" class="input-field" [(ngModel)]="newThesisTitle" nz-input [ngClass]="{'red-border': requireThesisName}" type="text"
              placeholder="Enter Thesis Name" (keypress)="filterKeyEvent($event)" autocomplete="no-autothesistitle" style="margin-left: 5px" required></span>
            <button *ngIf="enableEditThesis" nzSize='small' nz-button nzType="primary" style="margin-left: 3%;" (click)="handleUpdateThesis()"><i nz-icon nzType="check"></i></button>
            <button *ngIf="enableEditThesis" nzSize='small' nz-button nzType="default" nzDanger style="margin-left: 1.5%;" (click)="hideEditThesisCall()"><i nz-icon nzType="close" nzTheme="outline"></i></button>
          </span></p>         
    </div>
    <div nz-col nzSpan="12">
        <p class="thesis-details alignR"><span class="row-heading">Current Version:</span><span class="row-title"> {{ThesisVersion}}</span></p>      
    </div>
    </div>
    
    <div class="thesis-timeline-wrapper">
      <app-timeline 
                    [triggerByReport]="triggerByReport" 
                    [triggerBysubmitIM]="triggerBysubmitIM" 
                    [thesisID]="thesisId" 
                    [permission]="permission" 
                    [userType]="role" 
                    [thesisName]="thesisName" 
                    [StudentName]="StudentName" 
                    [StudentID]="StudentID" 
                    [ThesisVersion]="ThesisVersion" 
                    (currentTimeLineState)="getcurrentTimeLine($event)">
        </app-timeline>
    </div>

    <div nz-row class="row-heading">
    </div>

    <div class="row-body-timeline" *ngIf="role === 'student' && currentTimeLineLevel === 3 && currentTimeLineStatus === 'pending' && currentTimeLineSubmitted === true">
     <div *ngIf="isMajorAdvisorPending.length === 0 && this.isInternalMemberPending.length === 0">
      Internal members have completed reviewing your thesis. <span *ngIf="pendComments > 0">Resolve all the pending comments to submit to the next level.</span> <br>
     </div>
     <div *ngIf="isMajorAdvisorPending.length > 0 || this.isInternalMemberPending.length > 0" >
      An Internal member has completed reviewing your thesis. <span *ngIf="pendComments > 0">Resolve all the pending comments to submit to the next level.</span> <br>
     </div>

     <br>
    <div>
      <span>Upload revised version of your thesis:</span>
      <span nz-tooltip [nzTooltipTitle]="uploadRevisedToolTipIM">
        <button [disabled]="pendComments > 0 || isMajorAdvisorPending.length > 0 || isInternalMemberPending.length > 0" class="ml-1" nz-button nzSize="small" nzType="primary"
          (click)="selectFileRevisedThesis()">Upload Revised Version</button>
        <input type="file" #file style="display: none" (change)="onReUploadThesis($event, 'InternalMember')" />
      </span>
      <div *ngIf="uploadRevisedVersionMsg" class="mt-1">
        <span style="color: green;">Revised version of the thesis is uploaded successfully.</span>
      </div>
    </div>
    <div class="mt-1">
      <span>Submit your thesis for HOD review: </span>
      <span nz-tooltip [nzTooltipTitle]="submitLevelToolTipIM">
        <button [disabled]="pendComments > 0 || isRevisedVerIM" class="ml-1" nz-button nzSize="small" nzType="primary" (click)="submitToIM()">Submit to next
          level</button>
      </span>
    </div>
    </div>
    <div class="row-body-timeline" *ngIf="role === 'student' && currentTimeLineLevel === 4 && currentTimeLineStatus === 'pending' && currentTimeLineSubmitted === true">
     <div>
      HOD has completed reviewing your thesis. <span *ngIf="pendComments > 0">Resolve all the pending comments to submit to the next level.</span><br>
     </div>
     <br>
     <div>
      <span>Upload revised version of your thesis:</span>
      <span nz-tooltip [nzTooltipTitle]="uploadRevisedToolTipHOD">
        <button [disabled]="pendComments > 0" class="ml-1" nz-button nzSize="small" nzType="primary"
          (click)="selectFileRevisedThesis()">Upload Revised Version</button>
        <input type="file" #file style="display: none" (change)="onReUploadThesis($event, 'hod')" />
      </span>
      <div *ngIf="uploadRevisedVersionMsg" class="mt-1">
        <span style="color: green;">Revised version of the thesis is uploaded successfully.</span>
      </div>
    </div>
    <div class="mt-1">
      <span>Submit your thesis for dean review: </span>
      <span nz-tooltip [nzTooltipTitle]="submitLevelToolTipHOD">
        <button [disabled]="pendComments > 0 || isRevisedVerIM" class="ml-1" nz-button nzSize="small" nzType="primary" (click)="submitToIM()">Submit to next
          level</button>
      </span>
    </div>
    </div>

    <div class="row-body-timeline" *ngIf="role === 'student' && currentTimeLineLevel === 5 && currentTimeLineStatus === 'pending' && currentTimeLineSubmitted === true">
     <div>
      Dean has completed reviewing your thesis. <span *ngIf="pendComments > 0">Resolve all the pending comments to submit to the next level.</span><br>
     </div>
     <br>
     <div>
      <span>Upload revised version of your thesis:</span>
      <span nz-tooltip [nzTooltipTitle]="uploadRevisedToolTipDe">
        <button [disabled]="pendComments > 0" class="ml-1" nz-button nzSize="small" nzType="primary"
          (click)="selectFileRevisedThesis()">Upload Revised Version</button>
        <input type="file" #file style="display: none" (change)="onReUploadThesis($event, 'dean')" />
      </span>
      <div *ngIf="uploadRevisedVersionMsg" class="mt-1">
        <span style="color: green;">Revised version of the thesis is uploaded successfully.</span>
      </div>
    </div>
    <div class="mt-1">
      <span>Submit your thesis for external members review: </span>{{isRevisedVerIM}}
      <span nz-tooltip [nzTooltipTitle]="submitLevelToolTipDe">
        <button [disabled]="pendComments > 0 || isRevisedVerIM" class="ml-1" nz-button nzSize="small" nzType="primary" (click)="submitToIM()">Submit to next
          level</button>
      </span>
    </div>
    </div>
    <div class="row-body-timeline" *ngIf="role === 'student' && currentTimeLineLevel === 6 && currentTimeLineStatus === 'pending' && currentTimeLineSubmitted === true">
     <div>
      External members have completed reviewing your thesis. <span *ngIf="pendComments > 0">Resolve all the pending comments to submit to the next level.</span><br>
     </div>
     <br>
     <div>
      <span>Upload revised version of your thesis:</span>
      <span nz-tooltip [nzTooltipTitle]="uploadRevisedToolTipEM">
        <button [disabled]="pendComments > 0 || isExternalMemberPending.length > 0" class="ml-1" nz-button nzSize="small" nzType="primary"
          (click)="selectFileRevisedThesis()">Upload Revised Version</button>
        <input type="file" #file style="display: none" (change)="onReUploadThesis($event, 'ExternalMember')" />
      </span>
      <div *ngIf="uploadRevisedVersionMsg" class="mt-1">
        <span style="color: green;">Revised version of the thesis is uploaded successfully.</span>
      </div>
    </div>
    <div class="mt-1">
      <span>Submit your thesis for plagiarism check: </span>
      <span nz-tooltip [nzTooltipTitle]="submitLevelToolTipEM">
        <button [disabled]="pendComments > 0 || isRevisedVerIM" class="ml-1" nz-button nzSize="small" nzType="primary" (click)="submitToIM()">Submit to next
          level</button>
      </span>
    </div>
    </div>
    <div class="row-body-timeline" *ngIf="role === 'student' && currentTimeLineLevel === 8 && currentTimeLineStatus === 'pending' && currentTimeLineSubmitted === true">
     <div *ngIf="pendComments > 0">
      PG Coordinator has completed reviewing your thesis. <span>Resolve all the pending comments.</span><br>
     </div>
     <br>
     <span *ngIf="pendComments === 0">
       All pending comments has been resolved. Please wait for further action from PG Coordinator.
     </span>
    </div>
    <div class="row-body-timeline" *ngIf="role === 'student' && currentTimeLineLevel === 7 && currentTimeLineStatus === 'pending' && currentTimeLineSubmitted === false">
   
     <div>
      <span *ngIf="!updatedTitle">
        Please upload the plagiarism report for your thesis. <br>
      </span>
      <span *ngIf="updatedTitle">
        Please upload the plagiarism report for the revised thesis. <br>
      </span>
     <div class="mt-1"> 
      Upload Plagiarism Report :
       <!-- <input #inputElement style="width: 120px" nz-input nz-tooltip nzTooltipTrigger="focus" nzTooltipPlacement="topLeft"
         nzOverlayClassName="numeric-input" [ngModel]="thesisPlagiarismScore" [nzTooltipTitle]="plagiarismTooltip" placeholder="Enter Score"
         (ngModelChange)="onChangePlgScore($event)" (blur)="onBlurPlgScore()" max="100" min="1" /> -->
 
         <i (click)="selectFileplagReport()" style="margin-left: 2%; color: #1890ff;" class="font15" nz-icon nzType="upload" nzTheme="outline" aria-hidden="true" nz-tooltip nzTooltipTitle="Upload Plagiarism Report" (click)="plagiarismCheckComplete = true"></i>
         <!-- <i *ngIf="disableUploadPlgReport" style="margin-left: 2%; color: gray; cursor: not-allowed;" class="font15" nz-icon nzType="upload" nzTheme="outline" aria-hidden="true" nz-tooltip nzTooltipTitle="Upload Plagiarism Report"></i> -->
         <input type="file" #Reportfile style="display: none" (change)="onPlagReport($event)" multiple />
       </div>

     <div *ngIf="plagiarismErrorScore" class="mt-1">
       <span style="color: red;">Plagiarism score should be less than 100% to upload the plagiarism report. Modify your thesis as per the suggestions in the plagiarism report.</span>
     </div>

     <div *ngIf="plagiarismErrorEntered" class="mt-1">
       <span style="color: red;">Please enter a valid plagiarism score.</span>
     </div>

     <div *ngIf="uploadRevisedVersionMsg" class="mt-1">
       <span style="color: green;">Revised version of the thesis is uploaded successfully.</span>
     </div>
     <br>
    </div>
    </div>



    <div class="row-body-timeline" *ngIf="role === 'student' && currentTimeLineLevel === 3 && currentTimeLineStatus === 'pending' && currentTimeLineSubmitted === false">
     <div>
      Your thesis is under review with: <b>Internal members</b><br>
     </div>     
    </div>

    <div class="row-body-timeline" *ngIf="role === 'student' && currentTimeLineLevel === 4 && currentTimeLineStatus === 'pending' && currentTimeLineSubmitted === false">
     <div>
      Your thesis is under review with: <b>HOD</b><br>
     </div>     
    </div>

    <div class="row-body-timeline" *ngIf="role === 'student' && currentTimeLineLevel === 5 && currentTimeLineStatus === 'pending' && currentTimeLineSubmitted === false">
     <div>
      Your thesis is under review with: <b>Dean</b><br>

     </div>     
    </div>

    <div class="row-body-timeline" *ngIf="role === 'student' && currentTimeLineLevel === 6 && currentTimeLineStatus === 'pending' && currentTimeLineSubmitted === false">
     <div>
      Your thesis is under review with: <b>External members</b><br>
     </div>     
    </div>

    <div class="row-body-timeline" *ngIf="role === 'student' && currentTimeLineLevel === 8 && currentTimeLineStatus === 'pending' && currentTimeLineSubmitted === false">
     <div>
      Your thesis is under review with: <b>PG coordinator</b> <br>
     </div>     
    </div>


    <div class="row-body-timeline" *ngIf="role === 'student' && currentTimeLineLevel === 2 && currentTimeLineStatus === 'pending' && currentTimeLineSubmitted === false">
     <div>
       <span *ngIf="!updatedTitle">
         Please upload the plagiarism report for your thesis. <br>
       </span>
       <span *ngIf="updatedTitle">
         Please upload the plagiarism report for the revised thesis. <br>
       </span>

      <div class="mt-1"> 
        Upload Plagiarism Report :
        <!-- <input #inputElement style="width: 120px" nz-input nz-tooltip nzTooltipTrigger="focus" nzTooltipPlacement="topLeft"
          nzOverlayClassName="numeric-input" [ngModel]="thesisPlagiarismScore" [nzTooltipTitle]="plagiarismTooltip" placeholder="Enter Score"
          (ngModelChange)="onChangePlgScore($event)" (blur)="onBlurPlgScore()" max="100" min="1" /> -->

          <i  (click)="selectFileplagReport()" style="margin-left: 2%; color: #1890ff;" class="font15" nz-icon nzType="upload" nzTheme="outline" aria-hidden="true" nz-tooltip nzTooltipTitle="Upload Plagiarism Report" (click)="plagiarismCheckComplete = true"></i>
          <!-- <i *ngIf="disableUploadPlgReport" style="margin-left: 2%; color: gray; cursor: not-allowed;" class="font15" aria-hidden="true" nz-icon nzType="upload" nzTheme="outline" nz-tooltip nzTooltipTitle="Upload Plagiarism Report"></i> -->
          <input type="file" #Reportfile style="display: none" (change)="onPlagReport($event)" />
        </div>

      <div *ngIf="plagiarismErrorScore" class="mt-1">
        <span style="color: red;">Plagiarism score should be less than 100% to upload the plagiarism report. Modify your thesis as per the suggestions in the plagiarism report.</span>
      </div>

      <div *ngIf="uploadRevisedVersionMsg" class="mt-1">
        <span style="color: green;">Revised version of the thesis is uploaded successfully.</span>
      </div>

      <br>
     </div>
    </div>

    <div class="row-body-timeline" *ngIf="role === 'student' && currentTimeLineLevel === 2 && currentTimeLineStatus === 'pending' && currentTimeLineSubmitted === true">
      <div>
        You have uploaded the plagiarism report for your thesis.<br>
        <div class="mt-1">
          <span>Upload revised version of your thesis:</span>
          <button class="ml-1" nz-button nzSize="small" nzType="primary" (click)="selectFileRevisedThesis()">Upload Revised Version</button>
          <input type="file" #file style="display: none" (change)="onReUploadThesis($event, 'plagiarismCheck')" />
        </div>
        <div *ngIf="uploadRevisedVersionMsg" class="mt-1">
          <span style="color: green;">Revised version of the thesis is uploaded successfully.</span>
        </div>
        <div class="mt-1">
          <span>Submit your thesis for internal member's review: </span>
          <button [disabled]="!revisedVersionUploaded" class="ml-1" nz-button nzSize="small" nzType="primary" (click)="submitToIM()">Submit to next
            level</button>
        </div>    
      </div>
    </div>

    <div class="row-body-timeline" *ngIf="role === 'student' && currentTimeLineLevel === 7 && currentTimeLineStatus === 'pending' && currentTimeLineSubmitted === true">
      <div>
        You have uploaded the plagiarism report for your thesis.<br>
        <div class="mt-1">
          <span>Upload revised version of your thesis:</span>
          <button class="ml-1" nz-button nzSize="small" nzType="primary" (click)="selectFileRevisedThesis()">Upload Revised Version</button>
          <input type="file" #file style="display: none" (change)="onReUploadThesis($event, 'plagiarismCheck')" />
        </div>
        <div *ngIf="uploadRevisedVersionMsg" class="mt-1">
          <span style="color: green;">Revised version of the thesis is uploaded successfully.</span>
        </div>
        <div class="mt-1">
          <span>Submit your thesis for PG Coordinator review: </span>
          <button [disabled]="!revisedVersionUploaded" class="ml-1" nz-button nzSize="small" nzType="primary" (click)="submitToIM()">Submit to next
            level</button>
        </div>    
      </div>
    </div>

    <div class="row-body-timeline" *ngIf="role === 'student' && currentTimeLineLevel === 9 && currentTimeLineStatus === 'complete' && currentTimeLineCurrentState === 'Approved'">
      <div>
        Congratulations! Your thesis '{{thesisName}}'  has been approved by all review members.  <br> 
      </div>
      <div>
        Notes/Comments: <br>
        &nbsp;&nbsp; {{currentTimeLineComment}}
      </div>
    </div>

    <div class="row-body-timeline" *ngIf="role === 'student' && currentTimeLineLevel === 9 && currentTimeLineStatus === 'complete' && currentTimeLineCurrentState === 'Rejected'">
      <div>
        Sorry, your thesis '{{thesisName}}'  has been rejected.  <br> 
      </div>
      <div>
        Reason for rejection: <br>
        &nbsp;&nbsp; {{currentTimeLineComment}}
      </div>
    </div>

    <div class="row-body-timeline" *ngIf="(role === 'major_advisor' || role === 'pg_coordinator') && currentTimeLineLevel === 9 && currentTimeLineStatus === 'complete' && currentTimeLineCurrentState === 'Approved'">
      <div>
        '{{thesisName}}'  has been approved by all review members.  <br> 
      </div>
      <div>
        Notes/Comments: <br>
        &nbsp;&nbsp; {{currentTimeLineComment}}
      </div>
    </div>

    <div class="row-body-timeline" *ngIf="(role === 'major_advisor' || role === 'pg_coordinator') && currentTimeLineLevel === 9 && currentTimeLineStatus === 'complete' && currentTimeLineCurrentState === 'Rejected'">
      <div>
        '{{thesisName}}'  has been rejected.  <br> 
      </div>
      <div>
        Reason for rejection: <br>
        &nbsp;&nbsp; {{currentTimeLineComment}}
      </div>
    </div>

    <!-- <div nz-row class="row-heading">
      Review Status
    </div> -->

  </div>
  <div nz-col nzSpan="1" class="">
  </div>
  </div>
  
  
</div>

