import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { LoginComponent } from './component/login.component';
import { LoginService } from './service/login.service';
import { LoginRoutingModule, loginRoutes } from './login.routing.module';
import { HttpClientModule } from '@angular/common/http';
import {SharedModule} from '../../shared/shared.module';
import { NzGridModule } from 'ng-zorro-antd/grid';
import {MatGridListModule} from '@angular/material/grid-list';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzLayoutModule } from 'ng-zorro-antd/layout';


@NgModule({
  imports: [
    CommonModule,
    LoginRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    NzGridModule,
    MatGridListModule,
    NzModalModule,
    NzAlertModule,
    NzIconModule,
    NzInputModule,
    NzButtonModule,
    NzLayoutModule,
    NzMenuModule,
    // RouterModule.forChild([
    //   {path: 'load-me', component: LoginComponent}
    // ])
    RouterModule.forChild(loginRoutes)
  ],
  declarations: [LoginComponent],
   providers: [LoginService]
})
export class LoginModule { }
