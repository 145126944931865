import { Component, OnInit, Input, OnChanges, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { SideNavService } from '../service/side-nav.service';
import { UserProfileService } from '../../../shared/services/userprofile.service';

@Component({
  selector: 'app-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss']
})
export class DrawerComponent implements OnInit, OnChanges {
	@Input() drawerVisible: boolean;
  isOpened = false;
  selectedHeaderMenu: any;
  profileData: any;
  UserName: any;
  UserEmail: any;
  UserRole: any;

  constructor(private sideNavService: SideNavService, private userProfileService: UserProfileService,) { }
  @ViewChild('sidenav') public sidenav: MatSidenav;


 ngOnInit(): void {
   this.getProfileData();
   
   document.getElementsByTagName("mat-sidenav-container")[0].removeAttribute('fullscreen');
   this.isOpened = false;
   this.sideNavService.sideNavToggleSubject.subscribe((data) => {
     
     if (data >= 1) {
       this.sidenav.open();
       this.isOpened = this.sidenav.opened;
       this.sideNavService.onHeaderMenuSelect.subscribe((select) => {
         this.selectedHeaderMenu = select;
       });
     }
     if (this.isOpened === true) {
       document.getElementsByTagName("mat-sidenav-container")[0].setAttribute('fullscreen', 'true');
     } else {
       this.isOpened = false;
       document.getElementsByTagName("mat-sidenav-container")[0].removeAttribute('fullscreen');
     }
   });
  }
  ngOnChanges(changes: any): void {
    
  }

  getProfileData(): void {
    const profileMetaData = { email: localStorage.getItem('email') };
    this.userProfileService.getUserProfile(profileMetaData).subscribe(data => {
      
      if (data.status_code === '01') {
        this.UserName = data.profile_data.name;
        this.UserEmail = data.profile_data.email;
        this.UserRole = data.profile_data.role;

      }
    }, (err) => {
      // this.failureMsg = 'Something went wrong. Please Try again';
    });
  }

  close(reason: string): void {
    this.sideNavService.domainToggle(0, '');
    this.sidenav.close();
    this.isOpened = false;
    document.getElementsByTagName("mat-sidenav-container")[0].removeAttribute('fullscreen');
  }

}
