<div nz-row class="home-header-row">
  <div nz-col nzSpan="24">
      <app-header (toggleDrawer)="profileToggleDrawer($event)"></app-header>
  </div>
</div>

<div *ngIf="!showDocViewer">
  <app-drawer [drawerVisible]="profileDrawer"></app-drawer>
  <div nz-row class="home-content-row" *ngIf="role === 'student'">
    <div nz-col nzSpan="1" class="">
  </div>
  <div nz-col nzSpan="22" class="" >
    <div nz-row class="row-heading">
      Upload Thesis
    </div>
    <div class="row-dropzone-container mt-01">
      <ngx-dropzone (change)="onSelect($event)" [multiple]="false"  style="background: #f9f9f9;z-index: 0 !important;">
        <ngx-dropzone-label>Drag and drop documents or click here to upload!</ngx-dropzone-label>
        <div>
          <ngx-dropzone-preview class="dropzone-preview" *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
              <ngx-dropzone-label >{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
          </ngx-dropzone-preview>
        </div>
    </ngx-dropzone>
    <div nz-row class="title-heading" *ngIf="files.length > 0">
      Thesis Title
    </div>
    <div nz-row class="title-input-container" *ngIf="files.length > 0">
      <input style="width: 50%;" [ngClass]="{'highlightTextArea': titleError}" (keypress)="onKeypressTitleEvent($event)" nz-input [placeholder]="titlePlaceholder" autocomplete="no-auto-complete" [(ngModel)]="thesisTitle" />
      <button nz-button class="ml-4" nzSize="default" (click)="handleUploadThesis(thesisTitle)" nzType="default">Submit</button>
      <button nz-button class="ml-2" nzSize="default" (click)="handleCancelUpload()" nzType="default">Cancel</button>
      
    </div>
    <div *ngIf="failureMsg" class="uploadError">
      <nz-alert nzType="error" nzCloseable [nzMessage]="failureMsg"
        (nzOnClose)="afterCloseAlert()">
      </nz-alert>
    </div>
    </div>
  </div>
  <div nz-col nzSpan="1" class="">
  </div>
  </div>
  
  <div nz-row *ngIf="role === 'student'">
      <div nz-col nzSpan="1"></div>
      <div nz-col nzSpan="22">
        <div nz-row class="row-heading-student-list">
          Uploaded Thesis
        </div>
        <nz-table #basicTable [nzData]="listOfData" class="mt-01">
          <thead>
            <tr>
              <th nzWidth="50%">Thesis Name</th>
              <th nzWidth="30%">Submission Date</th>
              <th nzWidth="20%">Stage</th>
              <th nzWidth="10%">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of basicTable.data" (click)="docDetails(data)" class="thesis-uploaded-table">
              <td>{{ data.thesis_name }}</td>
              <td>{{ data.uploaded_datetime | date: 'longDate' }}</td>
              <td>{{ data.version }}</td>
              <td><button nz-button nzSize="small" (click)="docDetails(data)" nzType="primary">View</button></td>
              
            </tr>
          </tbody>
        </nz-table>
      </div>
      <div nz-col nzSpan="1"></div>
  </div>

  <div nz-row *ngIf="role === 'advisor' || role === 'dean_pgs'">

    <div *ngIf="showAdvisorThesis === true && showpendingAdvisorThesis === true" nz-row class="row-container" style="width: 100%;">
      <div nz-col nzSpan="1"></div>
      <div nz-col nzSpan="22">
        <nz-tabset [nzTabBarExtraContent]="refreshTemplate">
          <nz-tab nzTitle="Thesis pending for your review">
            
            
            <div *ngIf="listOfReviewPendingData" class="search-filter">
          <input style="width: 20%" type="text" nz-input placeholder="Search name/Thesis title" autocomplete="no-autosearchpendthesis" (keypress)="filterKeyEvent($event)" [(ngModel)]="searchFilterValue" />
              <button  nz-button nzSize="small" nzType="primary" (click)="filterPendingInternalData()" class="search-button ml-2">
                Search
              </button>
              <button nz-button nzSize="small" (click)="reset()">Reset</button>
            </div>
            <nz-table #basicPendingTable [nzData]="listOfReviewPendingData" class="mt-01 mb-2">
              <thead>
                <tr>
                  <th nzWidth="19%" nzCustomFilter>Student Name</th>
                  <th nzWidth="9%">Student ID</th>
                  <th nzWidth="20%">Thesis Title</th>
                  <th nzWidth="13%">Uploaded Date</th>
                  <th nzWidth="13%">Role</th>
                  <th nzWidth="5%">Stage</th>
                  <!-- <th nzWidth="7%">Review Status</th> -->
                  <th nzWidth="30%">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of basicPendingTable.data" class="thesis-uploaded-table">
                  <td >{{ data.student_name }}</td>
                  <td (click)="viewThesis(data)">{{ data.student_ref_no }}</td>
                  <td >{{ data.thesis_name }}</td>
                  <td >{{ data.start_datetime | date: 'longDate' }}</td>
                  <td >{{ data.advisor_type_verbose === 'Internal Member' ? 'Advisory Committee Member' : data.advisor_type_verbose }}</td>
                  <td >{{ data.stage_level }}</td>
                  <!-- <td (click)="viewThesis(data)">{{ data.accepted_comments }}</td>
                  <td (click)="viewThesis(data)">{{ data.rejected_comments }}</td> -->
                  <!-- <td  (click)="viewThesis(data)">{{ data.review_status === 'N/A' ? data.overall_status : data.review_status}}</td> -->
                  <td *ngIf="data.alert === ''">

                    <!-- <a href="{{data.plag_url}}" target="_self" download="{{data.thesis_name+'plagiarism_report.pdf'}}"> -->
                      <button nz-button (click)="downloadFiles(data.plag_url, data.thesis_name+'plagiarism_report.pdf')" nzType="primary" [nzSize]="small"  nz-tooltip nzTooltipTitle="Download Plagiarism report"><i nz-icon nzType="file-done"></i></button>
                    <!-- </a> -->
                    <!-- <a href="{{data.download_url}}" target="_self" download="{{data.thesis_name+'.docx'}}"> -->
                      <button nz-button (click)="downloadFiles(data.download_url, data.thesis_name+'.docx')" nzType="primary" [nzSize]="small" style="margin-left: 1%;" nz-tooltip nzTooltipTitle="Download thesis"><i nz-icon nzType="download"></i></button>
                    <!-- </a> -->
    
    
                    <button nz-button nzType="primary" [nzSize]="small" style="margin-left: 1%;" *ngIf="data.advisor_type !== 'hod'" (click)="selectFileUploadReviewDoc(data)" nz-tooltip nzTooltipTitle="Upload review thesis"><i nz-icon nzType="upload"></i></button>
                    <input type="file" #ReviewFile style="display: none" (change)="onUploadReviewedThesis($event, data.review_docid, data)" />
                    
                    
                    <!-- <button nz-button nzType="primary" [nzSize]="small" style="margin-left: 1%;" nz-tooltip nzTooltipTitle="Upload review thesis"><i nz-icon nzType="upload"></i></button> -->
                    <button (click)="viewDocument(data)" *ngIf="data.advisor_type === 'hod' || data.stage_type === 'dean_2'"  nz-button nzType="primary" [nzSize]="small" nz-tooltip nzTooltipTitle="View" style="margin-left: 1%;"><i nz-icon nzType="file-pdf"></i></button>
                    <button (click)="markAsComplete(data)" nz-button nzType="primary" [nzSize]="small" nz-tooltip nzTooltipTitle="Mark as complete" style="margin-left: 1%;"><i nz-icon nzType="check-circle"></i></button>
                    <!-- <button   nz-button nzSize="small" (click)="confirmSubmit(data)" nzType="primary" nzShape="round">Mark as complete</button> <br /> -->
                  </td>
                  <td *ngIf="data.alert === 'external'">
                    <button   nz-button nzSize="small" (click)="viewThesis(data)" nzType="primary">View</button> <br />
                  </td>
                  
                </tr>
              </tbody>
            </nz-table>
           
    
            <nz-modal [(nzVisible)]="isVisible" [nzFooter]="modalFooter" nzTitle="Are you sure you want to approve this thesis?" (nzOnCancel)="handleCancel()">
              <p><b> {{selectedThesis}}</b> will be marked as complete.</p>
              <textarea [(ngModel)]="acceptReason" style="margin-top: 7px;" class="textarea-override" nz-input
                            placeholder="Write your comment here" [nzAutosize]="{ minRows: 2, maxRows: 6 }">
              </textarea>
              <ng-template #modalFooter>
                <button nz-button nzType="default" (click)="handleCancel()">Cancel</button>
                <button nz-button nzType="primary" (click)="handleOk()">Approve Thesis</button>
              </ng-template>
            </nz-modal>
    
            <nz-modal [(nzVisible)]="isRejectVisible" [nzFooter]="rejectModalFooter" nzTitle="Are you sure you want to reject this thesis?" (nzOnCancel)="handleRejectCancel()">
              <p><b> {{selectedThesis}}</b> will be rejected.</p>
              <textarea [ngClass]="{'highlightTextArea': rejectReasonRequired, 'borderTextarea': !rejectReasonRequired}" [(ngModel)]="rejectReason" style="margin-top: 7px;" class="textarea-override" nz-input
              (keypress)="onKeypressEvent($event)" placeholder="Write your comment here" [nzAutosize]="{ minRows: 2, maxRows: 6 }">
              </textarea>
              <ng-template #rejectModalFooter>
                <button nz-button nzType="default" (click)="handleRejectCancel()">Cancel</button>
                <button nz-button nzType="primary" (click)="handleRejectOk()">Reject Thesis</button>
              </ng-template>
            </nz-modal>
    
    
    
    
    
          </nz-tab>
          <nz-tab *ngIf="this.listOfinternalHomeData.length >= 1" nzTitle="Thesis List">
            
            <div *ngIf="listOfinternalHomeData" class="search-filter">
              <input style="width: 20%" type="text" (keypress)="filterKeyEvent($event)" autocomplete="no-autosearchlist" nz-input placeholder="Search name/ID/Thesis title" [(ngModel)]="searchFilterValue" />
              <button  nz-button nzSize="small" nzType="primary" (click)="filterInternalData()" class="search-button ml-2">
                Search
              </button>
              <button nz-button nzSize="small" (click)="reset()">Reset</button>
    
            </div>
            <nz-table #basicTable [nzData]="listOfinternalHomeData" class="mt-01 mb-2">
              <thead>
                <tr>
                  <th nzWidth="15%" nzCustomFilter>Student Name</th>
                  <th nzWidth="10%">Student ID</th>
                  <th nzWidth="25%">Thesis Title</th>
                  <th nzWidth="15%">Uploaded Date</th>
                  <th nzWidth="10%">Role</th>
                  <th nzWidth="5%">Stage</th>
                  <th nzWidth="10%">Approval Status</th>
                  <th nzWidth="10%">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of basicTable.data" class="thesis-uploaded-table">
                  <td (click)="viewThesis(data)">{{ data.student_name }}</td>
                  <td (click)="viewThesis(data)">{{ data.student_ref_no }}</td>
                  <td (click)="viewThesis(data)">{{ data.thesis_name }}</td>
                  <td (click)="viewThesis(data)">{{ data.uploaded_datetime | date: 'longDate' }}</td>
                  <td (click)="viewThesis(data)">{{ data.advisor_type_verbose }}</td>
                  <td (click)="viewThesis(data)">{{ data.current_stage }}</td>
                  <td  (click)="viewThesis(data)">{{ data.status}}</td>
                  <td>
                    <button   nz-button nzSize="small" (click)="viewThesis(data)" nzType="primary">View</button> <br />
                  </td>
                </tr>
              </tbody>
            </nz-table>
    
          </nz-tab>
        </nz-tabset>
        <ng-template #refreshTemplate>
          <button nz-button (click)="refreshTable()">Refresh table</button>
        </ng-template>
      </div>
      <div nz-col nzSpan="1"></div>
      

    </div>

  </div>



  <div nz-row *ngIf="role === 'external_member'">
    <div nz-col nzSpan="1"></div>
    <div nz-col nzSpan="22">
      <div nz-row class="row-heading-student-list">
        <div nz-col nzSpan="12" style="text-align: start !important;">
          Thesis pending for your review
        </div>
        <div nz-col nzSpan="12" style="text-align: end !important;">
          <span (click)="displayGuidelines()">
            Guidelines
          </span>
        </div>
      </div>
      <div *ngIf="listOfExternalHomeData" class="search-filter">
        <input style="width: 20%" type="text" nz-input placeholder="Search name/ID/Thesis title" (keypress)="filterKeyEvent($event)" autocomplete="no-autopendthesis" [(ngModel)]="searchFilterValue" />
        <button  nz-button nzSize="small" nzType="primary" (click)="filterExternalData()" class="search-button ml-2">
          Search
        </button>
        <button nz-button nzSize="small" (click)="reset()">Reset</button>
      </div>
      <nz-table #basicTable [nzData]="listOfExternalHomeData" class="mt-01 mb-2">
        <thead>
          <tr>
            <th nzWidth="19%" nzCustomFilter>Student Name</th>
                  <th nzWidth="10%">Student ID</th>
                  <th nzWidth="22%">Thesis Title</th>
                  <th nzWidth="15%">Uploaded Date</th>
                  <th nzWidth="13%">Status</th>
                  <!-- <th nzWidth="5%">Stage</th> -->
                  <!-- <th nzWidth="7%">Review Status</th> -->
                  <th nzWidth="30%">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of basicTable.data" class="thesis-uploaded-table">
            <td >{{ data.student_name }}</td>
                  <td >{{ data.student_ref_no }}</td>
                  <td >{{ data.thesis_name }}</td>
                  <td >{{ data.start_datetime | date: 'longDate' }}</td>
                  <td >{{ data.review_complete === 'no' ? 'Pending' : 'Complete' }}</td>
                  <!-- <td >{{ data.stage_level }}</td> -->
                  <!-- <td (click)="viewThesis(data)">{{ data.accepted_comments }}</td>
                  <td (click)="viewThesis(data)">{{ data.rejected_comments }}</td> -->
                  <!-- <td  (click)="viewThesis(data)">{{ data.review_status === 'N/A' ? data.overall_status : data.review_status}}</td> -->
                  <td>
                    <!-- <a *ngIf="data.review_complete !== 'yes'" href="{{data.download_url}}" download="{{data.thesis_name+'.docx'}}">
                      <button nz-button nzType="primary" [nzSize]="small" nz-tooltip nzTooltipTitle="Download thesis"><i nz-icon nzType="download"></i></button>
                    </a> -->
    
    
                    <!-- <button *ngIf="data.review_complete !== 'yes'" nz-button nzType="primary" [nzSize]="small" style="margin-left: 1%;" (click)="selectFileUploadReviewDoc(data)" nz-tooltip nzTooltipTitle="Upload review thesis"><i nz-icon nzType="upload"></i></button> -->
                    <!-- <input type="file" #ReviewFile style="display: none" (change)="onUploadReviewedThesis($event, data.review_docid, data)" /> -->
                    
                    <button *ngIf="data.review_complete !== 'yes'" (click)="answerQuestionnaire(data)" nz-button nzType="primary" [nzSize]="small" nz-tooltip nzTooltipTitle="Answer Questionnaire" style="margin-left: 1%;"><i nz-icon nzType="question-circle"></i></button>
                    <button *ngIf="data.review_complete === 'yes'" (click)="printAnswers(data)" nz-button nzType="primary" [nzSize]="small" nz-tooltip nzTooltipTitle="Print Questionnaire" style="margin-left: 1%;"><i nz-icon nzType="printer"></i></button>


                    <!-- <button nz-button nzType="primary" [nzSize]="small" style="margin-left: 1%;" nz-tooltip nzTooltipTitle="Upload review thesis"><i nz-icon nzType="upload"></i></button> -->
                    <button (click)="viewDocument(data)" nz-button nzType="primary" [nzSize]="small" nz-tooltip nzTooltipTitle="View" style="margin-left: 1%;"><i nz-icon nzType="file-pdf"></i></button>
                    <button *ngIf="data.review_complete !== 'yes'" (click)="markAsComplete(data)" nz-button nzType="primary" [nzSize]="small" nz-tooltip nzTooltipTitle="Mark as complete" style="margin-left: 1%;"><i nz-icon nzType="check-circle"></i></button>
                    <!-- <button disabled nz-button nzType="primary" [nzSize]="small" nz-tooltip nzTooltipTitle="Mark as complete" style="margin-left: 1%;"><i nz-icon nzType="check-circle"></i></button> -->
                    <!-- <button   nz-button nzSize="small" (click)="confirmSubmit(data)" nzType="primary" nzShape="round">Mark as complete</button> <br /> -->
                    <!-- <button   nz-button nzSize="small" nzType="primary">View</button> <br />
                    <span nz-icon nzType="file-pdf" nzTheme="outline"></span> -->
                  </td>
          </tr>
        </tbody>
      </nz-table>
      <nz-modal [(nzVisible)]="isAnswerVisible" nzOkText="Save" nzCancelText="Cancel" nzWidth="800px" nzTitle="Questionnaire" (nzOnCancel)="handleAnswerCancel()" (nzOnOk)="handleAnswerOk()">
        <ng-container>
          <div *ngIf="displayQuestions" style="max-height: 40vh;overflow: scroll;">
            <div class="questionList" *ngFor="let ques of questions_data; let i = index">

              <div class="content-body" >
                <p class="question_text">{{i+1}}) {{ques.question_text}}</p>
             </div>
             <div>
               <textarea 
               [(ngModel)]="ques.answer_text"
                   style="margin-top: 7px; margin-bottom: 20px;"
                   class="textarea-question"
                   nz-input
                   placeholder="Write your answer here"
                   [nzAutosize]="{ minRows: 2, maxRows: 6 }">
                 </textarea>
             </div>

            </div>
          </div>
        </ng-container>
      </nz-modal>
    </div>
    <div nz-col nzSpan="1"></div>
  </div>  
</div>

<nz-modal
      [nzStyle]="{ top: '20px' }"
      [(nzVisible)]="showGuidelines"
      nzTitle="Guidelines"
      nzOkText="Ok"
      nzWidth="1200px"
      (nzOnCancel)="onCloseModal()"
      (nzOnOk)="onCloseModal()"
    >
      <div style="height: 60vh; overflow: scroll;" class="medium-text ">
        <div class="bold-text" style="text-align: center; font-size: large;">
          Directorate of Postgraduate Studies
        </div>
        <br>
        <div class="bold-text" style="text-align: center; font-size: large;">
          Thesis evaluation guidelines for external examiners
        </div>
        <br>
        <div>
          External thesis evaluators play a crucial role in the evaluation process of a student's thesis. They provide an objective perspective 
          on the research work, ensuring its quality and adherence to the academic standards of the UASB. Reviewing a postgraduate (Masters & 
          Doctoral) thesis involves handling confidential academic work. The important confidentiality guidelines of the Directorate are 
          indicated below.<br><br>
        </div>

        <div class="nz-row ">
          <span class="bold-text">Authentication and Access Control:</span><br>
          <span>Verify the identities of reviewers and ensure they have legitimate reasons to access the thesis content.</span>
        </div><br>
        <div class="nz-row ">
          <span class="bold-text">Non-Disclosure Agreement (NDA):</span><br>
          <span>Require reviewers to sign an NDA, clearly stating their obligation to maintain the confidentiality of the thesis content.</span>
        </div><br>
        <div class="nz-row ">
          <span class="bold-text">Limited Distribution:</span><br>
          <span>Limit the distribution of the thesis to only the authorized reviewers, advisors and relevant academic staff involved in the evaluation process.</span>
        </div><br>
        <div class="nz-row ">
          <span class="bold-text">Secure Environment:</span><br>
          <span>Review the thesis in a secure location/online system, such as a controlled academic environment, that is isolated from public access.</span>
        </div><br>
        <div class="nz-row ">
          <span class="bold-text">Data Handling:</span><br>
          <span>Handle physical / electronic copies of the thesis with care, keeping / saving them in secure locations when not being reviewed.</span>
        </div><br>
        <div class="nz-row ">
          <span class="bold-text">Digital Security:</span><br>
          <span>If the thesis is distributed electronically, use secure file-sharing methods with encryption to prevent unauthorized access.</span>
        </div><br>
        <div class="nz-row ">
          <span class="bold-text">Secure Communication:</span><br>
          <span>Discuss the thesis content only in secure and private communication channels, such as encrypted emails or platforms.</span>
        </div><br>
        <div class="nz-row ">
          <span class="bold-text">No Unauthorized Copies:</span><br>
          <span>Reviewers should not make unauthorized copies of the thesis content or share it with individuals who are not part of the review process.</span>
        </div><br>
        <div class="nz-row ">
          <span class="bold-text">Version Control:</span><br>
          <span>Maintain proper version control to track changes made during the review process and ensure everyone is working on the latest version.</span>
        </div><br>
        <div class="nz-row ">
          <span class="bold-text">Secure Disposal:</span><br>
          <span>Once the review process is completed, securely dispose of any physical or digital materials related to the thesis.</span>
        </div><br>
        <div class="nz-row ">
          <span class="bold-text">Ethical Considerations:</span><br>
          <span>Respect any ethical guidelines set by the Directorate / UASB / ICAR / UGC regarding the review and handling of academic work.</span>
        </div><br>
        <div>
          Make sure that all reviewers are aware of the confidentiality guidelines and understand the importance of adhering to them.
        </div>


      </div>
    </nz-modal>