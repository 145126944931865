import { NgModule } from '@angular/core';
import { ExternalLandingComponent } from './component/external-landing.component';
import { RouterModule, Routes } from '@angular/router';

export const externalLandingRoutes: Routes = [
  // { path: 'resetpass/:id', component: ExternalLandingComponent }
  // { path: 'default', component: ExternalLandingComponent }
  // { path: 'default?id=:guid', component: ExternalLandingComponent }
];

@NgModule({
  imports: [RouterModule.forChild(externalLandingRoutes)],
  exports: [RouterModule]
})
export class ExternalLandingRoutingModule { }
