<nz-layout>
      <div class="wrapper">
        <ul nz-menu nzMode="inline" nzTheme="dark" [nzInlineCollapsed]="isCollapsed" >
          <li nz-menu-item nz-tooltip nzTooltipPlacement="right">
            <i nz-icon nzType="mail"></i>
            <span style="text-align: center;">Admin Panel</span>
          </li>

          <li nz-menu-item nzSelected (click)="selectMenu(4)" nz-tooltip nzTooltipPlacement="right">
            <i nz-icon nzType="setting"></i>
            <span style="text-align: center;">Dashboard</span>
          </li>

          <li nz-submenu  nzTitle="Register User" nzIcon="setting">
            <ul>
              <li nz-menu-item (click)="selectMenu(0)">Single User</li>
              <li nz-menu-item (click)="selectMenu(1)">Multiple Users</li>
            </ul>
          </li>

          <li nz-menu-item (click)="selectMenu(6)" nz-tooltip nzTooltipPlacement="right">
            <i nz-icon nzType="setting"></i>
            <span style="text-align: center;">Manage Users</span>
          </li>
          <!-- <li nz-menu-item (click)="selectMenu(7)" nz-tooltip nzTooltipPlacement="right">
            <i nz-icon nzType="setting"></i>
            <span style="text-align: center;">Manage Members</span>
          </li> -->

          <li nz-menu-item (click)="selectMenu(2)" nz-tooltip nzTooltipPlacement="right">
            <i nz-icon nzType="setting"></i>
            <span style="text-align: center;">Manage Approvers</span>
          </li>

          <li nz-menu-item (click)="selectMenu(3)" nz-tooltip nzTooltipPlacement="right">
            <i nz-icon nzType="setting"></i>
            <span style="text-align: center;">Manage Questions</span>
          </li>

          <li nz-menu-item (click)="selectMenu(5)" nz-tooltip nzTooltipPlacement="right">
            <i nz-icon nzType="setting"></i>
            <span style="text-align: center;">Archive Thesis</span>
          </li>
          
        </ul>
      </div>
    <nz-layout>
