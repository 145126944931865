import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NotFoundComponent } from './component/not-found.component';
import { NotFoundRoutingModule } from './not-found.routing.module';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  imports: [
    CommonModule,
    NotFoundRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule
  ],
  declarations: [NotFoundComponent],
   providers: []
})
export class NotFoundModule { }
