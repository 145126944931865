import { Injectable, OnInit } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { User } from '../../modules/login/model/login.model';
import { UserProfileService } from '../services/userprofile.service';
import { take, map } from 'rxjs/operators';
import { Roles, RouteUrls } from '../constants/constants';
import { Observable } from 'rxjs';

@Injectable({providedIn: 'root'})
export class InternalUserAuthGuardService implements CanActivate {

  private user: User;
  statevalue;

  constructor(private router: Router, private ups: UserProfileService) { }

  // canActivate(next: ActivatedRouteSnapshot) {
  //   return this.checkLogin();
  // }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      const url: string = state.url;
      this.statevalue = state;
      return this.checkLogin(url);
  }

  checkLogin(url: string) {
        
    if (localStorage.getItem('role') === 'admin') {
        return true
    } else {
        // this.router.navigate(['/']);
        if(localStorage.getItem('id')){
          if(localStorage.getItem('role') !== 'admin') {
            this.router.navigate(['/home']);
          }
        } else {
          this.router.navigate(['/']);
        }
        return false
    }
}

}
