import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss']
})
export class AlertModalComponent implements OnInit {

  @Input() modalTitle: string;
  @Input() modalMsg: string;
  @Input() ModalRedirect: string;

  constructor(public modal: BsModalRef, private router: Router) { }

  ngOnInit() { }

  close() {
    if (this.ModalRedirect) {
      this.modal.hide();
      this.router.navigateByUrl(this.ModalRedirect);
      return;
    }
    this.modal.hide();
  }

}
