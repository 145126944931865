<div *ngIf="timelineStageData" class="thesis-main">
  <div nz-row class="row-heading">
      Review Status
    </div>
  <div *ngIf="userType === 'student'" class="page">
    <div *ngFor="let item of timelineStageData" [ngClass]="[item.status]">
      <div class="timeline__group">
        <span *ngIf="item.stage_name !== 'Submission 3'" class="timeline__year time" aria-hidden="true">{{item.stage_name}}</span>
        <span *ngIf="item.stage_name === 'Submission 3'" class="timeline__year time" aria-hidden="true">{{item.stage_name}} (Final Viva voce)</span>
        <div  *ngFor="let itemDetails of item.details" class="timeline__cards">

          <div *ngIf="item.stage_name == 'Submission 1'">
            <div *ngIf="itemDetails.type == 'upload'" [ngClass]="{timeline__card: itemDetails.type == 'upload', completeCard: itemDetails.status == 'complete', pendingCard: itemDetails.status == 'pending'}">
              <header class="card__header">
                <time class="time">
                  {{ itemDetails.start_datetime | date: 'longDate' }}
                </time>
              </header>
              <div style="margin-top: 2%;" class="card__content">
                <span [ngClass]="{complete_details: itemDetails.status == 'complete', pending_details: itemDetails.status == 'pending'}">Submitted</span>
              </div>
            </div>

            <div *ngIf="itemDetails.type == 'plagiarism'" [ngClass]="{timeline__card: itemDetails.type == 'plagiarism', completeCard: itemDetails.status == 'complete', pendingCard: itemDetails.status == 'pending'}">
              <div *ngIf="itemDetails.status == 'complete'">
                <header class="card__header">
                  <time class="time">
                    {{ itemDetails.end_datetime | date: 'longDate' }}
                  </time>
                </header>
                <div style="margin-top: 2%;" class="card__content">
                  <!-- <p>Plagiarism report: {{itemDetails.score}}%</p> -->
                  <p>
                    <!-- <a href="{{itemDetails.report_url}}" target="_self" download="{{thesisName+'_plagiarism_report.pdf'}}"> -->
                      <button class="enableBtn" (click)="downloadFiles(itemDetails.report_url, thesisName +'_plagiarism_report.pdf')" nz-button nzType="default" nzShape="round"><i nz-icon nzType="download"></i>Download plagiarism report</button>
                    <!-- </a> -->
                  </p>
                </div>
              </div>

              <div *ngIf="itemDetails.status == 'pending'">
                <header class="card__header">
                  <time class="time">
                    {{ itemDetails.start_datetime | date: 'longDate' }}
                  </time>
                </header>
                <div style="margin-top: 2%;" class="card__content">
                  <p> Please upload the plagiarism report for your thesis.</p>
                  
                  <div class="mt-1"> 
                    Upload Plagiarism Report :
                    <!-- <input #inputElement style="width: 120px" nz-input nz-tooltip nzTooltipTrigger="focus" nzTooltipPlacement="topLeft"
                      nzOverlayClassName="numeric-input" [ngModel]="thesisPlagiarismScore" [nzTooltipTitle]="plagiarismTooltip" placeholder="Enter Score"
                      (ngModelChange)="onChangePlgScore($event)" (blur)="onBlurPlgScore()" max="100" min="1" /> -->
            
                      <i (click)="selectFileplagReport()" style="margin-left: 2%; color: #1890ff;" class="font15" nz-icon nzType="upload" nzTheme="outline" nz-tooltip nzTooltipTitle="Upload Plagiarism Report" (click)="plagiarismCheckComplete = true"></i>
                      <!-- <i *ngIf="disableUploadPlgReport" style="margin-left: 2%; color: gray; cursor: not-allowed;" class="font15" nz-icon nzType="upload" nzTheme="outline" nz-tooltip nzTooltipTitle="Upload Plagiarism Report"></i> -->
                      <input type="file" #Reportfile style="display: none" (change)="onPlagReport($event, 'submission_1')" />
                    </div>
                    
                    <div *ngIf="plagiarismFileError" class="mt-1">
                      <span style="color: red;">{{plagiarismFileError}}</span>
                    </div>

                  <div *ngIf="plagiarismErrorScore" class="mt-1">
                    <span style="color: red;">Plagiarism score should be less than 100% to upload the plagiarism report. Modify your thesis as per the suggestions in the plagiarism report.</span>
                  </div>
                  <div *ngIf="plagiarismErrorEntered" class="mt-1">
                    <span style="color: red;">Please enter a valid plagiarism score.</span>
                  </div>
                  
                </div>
              </div>

            </div>

              <div *ngIf="itemDetails.type == 'review' && item.stage_name == 'Submission 1'" [ngClass]="{timeline__card: itemDetails.type == 'review', completeCard: itemDetails.status == 'complete', pendingCard: itemDetails.status == 'pending'}">
                <header class="card__header">
                  <time class="time" *ngIf="itemDetails.start_datetime">
                    {{ itemDetails.start_datetime | date: 'longDate' }}
                  </time>
                  <h3 class="card__title r-title">{{itemDetails.name}}</h3>
                </header>
                <div class="card__content">
                  <p>{{itemDetails.role === 'Internal Member' ? 'Advisory Committee Member' : itemDetails.role }}</p>
                  <p>Current status: <span [ngClass]="{complete_details: itemDetails.status == 'complete', pending_details: itemDetails.status == 'pending'}">{{itemDetails.status}}</span></p>
                  <p>
                    <a *ngIf="itemDetails.review_url !== '' && item.stage_name == 'Submission 1' && itemDetails.status === 'complete'">
                      <button class="enableBtn" (click)="downloadFiles(itemDetails.review_url, thesisName +'.docx')" *ngIf="itemDetails.status == 'complete' && item.stage_name == 'Submission 1'" nz-button nzType="default" nzShape="round"><i nz-icon nzType="download"></i>Download reviewed thesis</button>
                    </a>

                    <time class="time" *ngIf="itemDetails.review_url === '' && item.stage_name == 'Submission 1' && itemDetails.status === 'complete'">
                      No review document available
                    </time>
                    
                    <button *ngIf="itemDetails.status == 'pending'" disabled nz-button nzType="default" nzShape="round"><i nz-icon nzType="download"></i>Download reviewed thesis</button>
                  </p>
                </div>
              </div>

          </div>
          
          <div *ngIf="item.stage_name == 'Submission 2'">

            <div *ngIf="itemDetails.type == 'alert' && itemDetails.alert_type == 'previous_stage_incomplete'" [ngClass]="{timeline__card: itemDetails.type == 'alert', pendingCard: itemDetails.alert_type == 'previous_stage_incomplete'}">
             
              <div class="card__content">
                <p>{{itemDetails.message}}</p>
              </div>
            </div>

            <div *ngIf="itemDetails.type == 'alert' && itemDetails.alert_type == 'waiting_for_review'" [ngClass]="{timeline__card: itemDetails.type == 'alert', pendingCard: itemDetails.alert_type == 'waiting_for_review'}">
             
              <div class="card__content">
                <p>{{itemDetails.message}}</p>
              </div>
            </div>

            <div *ngIf="itemDetails.type == 'upload'" [ngClass]="{timeline__card: itemDetails.type == 'upload', completeCard: itemDetails.status == 'complete', pendingCard: itemDetails.status == 'pending'}">
              <div *ngIf="itemDetails.status == 'complete'">
                <header class="card__header">
                  <time class="time">
                    {{ itemDetails.start_datetime | date: 'longDate' }}
                  </time>
                </header>
                <div style="margin-top: 2%;" class="card__content">
                  <span [ngClass]="{complete_details: itemDetails.status == 'complete', pending_details: itemDetails.status == 'pending'}">Submitted</span>
                </div>
              </div>
              <div *ngIf="itemDetails.status == 'pending'">
                <header class="card__header">
                  <time class="time">
                    {{ itemDetails.start_datetime | date: 'longDate' }}
                  </time>
                </header>
                <div style="margin-top: 2%;" class="card__content">
                  Please upload revised version of your thesis.
                  <div class="mt-1">
                    <button class="enableBtn" nz-button nzType="default" nzShape="round" (click)="selectFileRevisedThesis()"><i nz-icon nzType="upload"></i>Upload Revised Version</button>
                    <input type="file" #file style="display: none" (change)="onReUploadThesis($event, 'submission_2')" />
                  </div>

                  <div *ngIf="item.stage_name == 'Submission 2' && itemDetails.status === 'pending' && failureMsg" class="mt-1">
                    <span style="color: red;margin-top: 1%;">{{failureMsg}}</span>
                  </div>
                  <!-- <span [ngClass]="{complete_details: itemDetails.status == 'complete', pending_details: itemDetails.status == 'pending'}">Submitted</span> -->
                </div>
              </div>
            </div>

            <div *ngIf="itemDetails.type == 'plagiarism'" [ngClass]="{timeline__card: itemDetails.type == 'plagiarism', completeCard: itemDetails.status == 'complete', pendingCard: itemDetails.status == 'pending'}">
              <div *ngIf="itemDetails.status == 'complete'">
                <header class="card__header">
                  <time class="time">
                    {{ itemDetails.end_datetime | date: 'longDate' }}
                  </time>
                </header>
                <div style="margin-top: 2%;" class="card__content">
                  <!-- <p>Plagiarism report: {{itemDetails.score}}%</p> -->
                  <p>
                    <!-- <a href="{{itemDetails.report_url}}" target="_self" target="_self" download="{{thesisName+'_plagiarism_report_2.pdf'}}"> -->
                      <button class="enableBtn" (click)="downloadFiles(itemDetails.report_url, thesisName +'_plagiarism_report_2.pdf')" nz-button nzType="default" nzShape="round"><i nz-icon nzType="download"></i>Download plagiarism report</button>
                    <!-- </a> -->
                  </p>
                </div>
              </div>

              <div *ngIf="itemDetails.status == 'pending'">
                <header class="card__header">
                  <time class="time">
                    {{ itemDetails.start_datetime | date: 'longDate' }}
                  </time>
                </header>
                <div style="margin-top: 2%;" class="card__content">
                  <p> Please upload the plagiarism report for your thesis.</p>
                  
                  <div class="mt-1"> 
                    Upload Plagiarism Report :
                    <!-- <input #inputElement style="width: 120px" nz-input nz-tooltip nzTooltipTrigger="focus" nzTooltipPlacement="topLeft"
                      nzOverlayClassName="numeric-input" [ngModel]="thesisPlagiarismScore" [nzTooltipTitle]="plagiarismTooltip" placeholder="Enter Score"
                      (ngModelChange)="onChangePlgScore($event)" (blur)="onBlurPlgScore()" max="100" min="1" /> -->
            
                      <i (click)="selectFileplagReport()" style="margin-left: 2%; color: #1890ff;" class="font15" nz-icon nzType="upload" nzTheme="outline" nz-tooltip nzTooltipTitle="Upload Plagiarism Report" (click)="plagiarismCheckComplete = true"></i>
                      <!-- <i *ngIf="disableUploadPlgReport" style="margin-left: 2%; color: gray; cursor: not-allowed;" class="font15" nz-icon nzType="upload" nzTheme="outline" nz-tooltip nzTooltipTitle="Upload Plagiarism Report"></i> -->
                      <input type="file" #Reportfile style="display: none" (change)="onPlagReport($event, 'submission_2')" />
                    </div>
            
                  <div *ngIf="plagiarismFileError" class="mt-1">
                    <span style="color: red;">{{plagiarismFileError}}</span>
                  </div>


                  <div *ngIf="plagiarismErrorScore" class="mt-1">
                    <span style="color: red;">Plagiarism score should be less than 100% to upload the plagiarism report. Modify your thesis as per the suggestions in the plagiarism report.</span>
                  </div>
                  <div *ngIf="plagiarismErrorEntered" class="mt-1">
                    <span style="color: red;">Please enter a valid plagiarism score.</span>
                  </div>
                  
                </div>
              </div>

            </div>

            
            <div *ngIf="itemDetails.type == 'review' && item.stage_name == 'Submission 2'" [ngClass]="{timeline__card: itemDetails.type == 'review', completeCard: itemDetails.status == 'complete', pendingCard: itemDetails.status == 'pending'}">
              <header class="card__header">
                <time class="time" *ngIf="itemDetails.start_datetime">
                  {{ itemDetails.start_datetime | date: 'longDate' }}
                </time>
                <h3 class="card__title r-title">{{itemDetails.name}}</h3>
              </header>
              <div class="card__content">
                <p>{{itemDetails.role === 'Internal Member' ? 'Advisory Committee Member' : itemDetails.role }}</p>
                <p>Current status: <span [ngClass]="{complete_details: itemDetails.status == 'complete', pending_details: itemDetails.status == 'pending'}">{{itemDetails.status}}</span></p>
                <p>
                  <a *ngIf="itemDetails.review_url !== '' && item.stage_name == 'Submission 2' && itemDetails.status === 'complete'">
                    <button class="enableBtn" (click)="downloadFiles(itemDetails.review_url, thesisName +'.docx')" *ngIf="itemDetails.status == 'complete' && item.stage_name == 'Submission 2'" nz-button nzType="default" nzShape="round"><i nz-icon nzType="download"></i>Download reviewed thesis</button>
                  </a>

                  <time class="time" *ngIf="itemDetails.review_url === '' && item.stage_name == 'Submission 2' && itemDetails.status === 'complete'">
                    No review document available
                  </time>
                  <button class="enableBtn" *ngIf="item.stage_name == 'Submission 2' && item.status !== 'stage_pending' && itemDetails.status === 'complete' && itemDetails.role === 'External'" (click)="showExternalAnswers(itemDetails)" nz-button nzType="default" nzShape="round" style="margin-left: 1%;"><i nz-icon nzType="read"></i>Show answers</button>
                  <button class="enableBtn" *ngIf="item.stage_name == 'Submission 2' && item.status !== 'stage_pending' && itemDetails.status === 'complete' && itemDetails.role === 'External'" (click)="viewDocument(itemDetails)" nz-button nzType="default" nzShape="round" style="margin-left: 1%;"><i nz-icon nzType="file-pdf"></i>View Document</button>

                  <button *ngIf="itemDetails.status == 'pending'" disabled nz-button nzType="default" nzShape="round"><i nz-icon nzType="download"></i>Download reviewed thesis</button>
                </p>
              </div>
            </div>


            <div *ngIf="itemDetails.type == 'approval_1' && item.stage_name == 'Submission 2'" [ngClass]="{timeline__card: itemDetails.type == 'approval_1', completeCard: itemDetails.status == 'complete', pendingCard: itemDetails.status == 'pending', rejectCard: itemDetails.status == 'rejected'}">
              <header class="card__header">
                <time class="time" *ngIf="itemDetails.start_datetime">
                  {{ itemDetails.start_datetime | date: 'longDate' }}
                </time>
                <h3 class="card__title r-title">{{itemDetails.name}}</h3>
              </header>
              <div class="card__content">
                <p>{{itemDetails.role}}</p>
                <p>Current status: <span [ngClass]="{complete_details: itemDetails.status == 'complete', pending_details: itemDetails.status == 'pending', rejected_details: itemDetails.status == 'rejected'}">{{itemDetails.status}}</span></p>
                
                
              </div>
            </div>

            <div *ngIf="itemDetails.type == 'approval_2' && item.stage_name == 'Submission 2'" [ngClass]="{timeline__card: itemDetails.type == 'approval_2', completeCard: itemDetails.status == 'complete', pendingCard: itemDetails.status == 'pending', rejectCard: itemDetails.status == 'rejected'}">
              <header class="card__header">
                <time class="time" *ngIf="itemDetails.start_datetime">
                  {{ itemDetails.start_datetime | date: 'longDate' }}
                </time>
                <h3 class="card__title r-title">{{itemDetails.name}}</h3>
              </header>
              <div class="card__content">
                <p>{{itemDetails.role}}</p>
                <p>Current status: <span [ngClass]="{complete_details: itemDetails.status == 'complete', pending_details: itemDetails.status == 'pending', rejected_details: itemDetails.status == 'rejected'}">{{itemDetails.status}}</span></p>
                
                
              </div>
            </div>





          </div>

          <div *ngIf="item.stage_name == 'Submission 3'">
            <div *ngIf="itemDetails.type == 'alert' && itemDetails.alert_type == 'previous_stage_incomplete'" [ngClass]="{timeline__card: itemDetails.type == 'alert', pendingCard: itemDetails.alert_type == 'previous_stage_incomplete'}">
             
              <div class="card__content">
                <p>{{itemDetails.message}}</p>
              </div>
            </div>
            <div *ngIf="itemDetails.type == 'alert' && itemDetails.alert_type == 'waiting_for_review'" [ngClass]="{timeline__card: itemDetails.type == 'alert', pendingCard: itemDetails.alert_type == 'waiting_for_review'}">
             
              <div class="card__content">
                <p>{{itemDetails.message}}</p>
              </div>
            </div>
            <div *ngIf="itemDetails.type == 'upload'" [ngClass]="{timeline__card: itemDetails.type == 'upload', completeCard: itemDetails.status == 'complete', pendingCard: itemDetails.status == 'pending'}">
              <div *ngIf="itemDetails.status == 'complete'">
                <header class="card__header">
                  <time class="time">
                    {{ itemDetails.start_datetime | date: 'longDate' }}
                  </time>
                </header>
                <div style="margin-top: 2%;" class="card__content">
                  <span [ngClass]="{complete_details: itemDetails.status == 'complete', pending_details: itemDetails.status == 'pending'}">Submitted</span>
                </div>
              </div>
              <div *ngIf="itemDetails.status == 'pending' && stage2Status === 'stage_complete'">
                <header class="card__header" *ngIf="itemDetails.start_datetime">
                  <time class="time">
                    {{ itemDetails.start_datetime | date: 'longDate' }}
                  </time>
                </header>
                <div style="margin-top: 2%;" class="card__content">
                  Please upload revised version of your thesis.
                  <div class="mt-1">
                    <button class="enableBtn" nz-button nzType="default" nzShape="round" (click)="selectFileRevisedThesis()"><i nz-icon nzType="upload"></i>Upload Revised Version</button>
                    <input type="file" #file style="display: none" (change)="onReUploadThesis($event, 'submission_3')" />
                  </div>
                  <div *ngIf="item.stage_name == 'Submission 3' && itemDetails.status === 'pending' && failureMsg" class="mt-1">
                    <span style="color: red;margin-top: 1%;">{{failureMsg}}</span>
                  </div>

                  <!-- <span [ngClass]="{complete_details: itemDetails.status == 'complete', pending_details: itemDetails.status == 'pending'}">Submitted</span> -->
                </div>
              </div>
            </div>

            <div *ngIf="itemDetails.type == 'plagiarism'" [ngClass]="{timeline__card: itemDetails.type == 'plagiarism', completeCard: itemDetails.status == 'complete', pendingCard: itemDetails.status == 'pending'}">
              <div *ngIf="itemDetails.status == 'complete'">
                <header class="card__header">
                  <time class="time">
                    {{ itemDetails.end_datetime | date: 'longDate' }}
                  </time>
                </header>
                <div style="margin-top: 2%;" class="card__content">
                  <p>
                    <!-- <a href="{{itemDetails.report_url}}" target="_self" target="_self" download="{{thesisName+'_plagiarism_report_2.pdf'}}"> -->
                      <button class="enableBtn" (click)="downloadFiles(itemDetails.report_url, thesisName +'_action_report.pdf')" nz-button nzType="default" nzShape="round"><i nz-icon nzType="download"></i>Download action report</button>
                    <!-- </a> -->
                  </p>
                </div>
              </div>

              <div *ngIf="itemDetails.status == 'pending'">
                <header class="card__header">
                  <time class="time">
                    {{ itemDetails.start_datetime | date: 'longDate' }}
                  </time>
                </header>
                <div style="margin-top: 2%;" class="card__content">
                  <p> Please upload the action taken report for your thesis.</p>
                  
                  <div class="mt-1"> 
                    Upload action Report :
            
                      <i (click)="selectFileplagReport()" style="margin-left: 2%; color: #1890ff;" class="font15" nz-icon nzType="upload" nzTheme="outline" nz-tooltip nzTooltipTitle="Upload Action Report" (click)="plagiarismCheckComplete = true"></i>
                      <!-- <i *ngIf="disableUploadPlgReport" style="margin-left: 2%; color: gray; cursor: not-allowed;" class="font15" nz-icon nzType="upload" nzTheme="outline" nz-tooltip nzTooltipTitle="Upload Plagiarism Report"></i> -->
                      <input type="file" #Reportfile style="display: none" (change)="onActionReport($event, 'submission_3')" />
                    </div>
            
                  <div *ngIf="plagiarismFileError" class="mt-1">
                    <span style="color: red;">{{plagiarismFileError}}</span>
                  </div>


                  <div *ngIf="plagiarismErrorScore" class="mt-1">
                    <span style="color: red;">Plagiarism score should be less than 100% to upload the plagiarism report. Modify your thesis as per the suggestions in the plagiarism report.</span>
                  </div>
                  <div *ngIf="plagiarismErrorEntered" class="mt-1">
                    <span style="color: red;">Please enter a valid plagiarism score.</span>
                  </div>
                  
                </div>
              </div>
            </div>

            
            <div *ngIf="itemDetails.type == 'review' && item.stage_name == 'Submission 3'" [ngClass]="{timeline__card: itemDetails.type == 'review', completeCard: itemDetails.status == 'complete', pendingCard: itemDetails.status == 'pending'}">
              <header class="card__header">
                <time class="time" *ngIf="itemDetails.start_datetime">
                  {{ itemDetails.start_datetime | date: 'longDate' }}
                </time>
                <h3 class="card__title r-title">{{itemDetails.name}}</h3>
              </header>
              <div class="card__content">
                <p>{{itemDetails.role === 'Internal Member' ? 'Advisory Committee Member' : itemDetails.role }}</p>
                <p>Current status: <span [ngClass]="{complete_details: itemDetails.status == 'complete', pending_details: itemDetails.status == 'pending'}">{{itemDetails.status}}</span></p>
                <!-- <p>
                  <a href="{{itemDetails.review_url}}" *ngIf="itemDetails.review_url !== '' && item.stage_name == 'Submission 3' && itemDetails.status === 'complete'" download="{{thesisName+'.docx'}}">
                    <button class="enableBtn" *ngIf="itemDetails.status == 'complete' && item.stage_name == 'Submission 3'" nz-button nzType="default" nzShape="round"><i nz-icon nzType="download"></i>Download reviewed thesis</button>
                  </a>

                  <time class="time" *ngIf="itemDetails.review_url === '' && item.stage_name == 'Submission 3' && itemDetails.status === 'complete'">
                    No review document available
                  </time>
                  
                  <button *ngIf="itemDetails.status == 'pending'" disabled nz-button nzType="default" nzShape="round"><i nz-icon nzType="download"></i>Download reviewed thesis</button>
                </p> -->
              </div>
            </div>

            <div *ngIf="itemDetails.type == 'approval_3' && item.stage_name == 'Submission 3'" [ngClass]="{timeline__card: itemDetails.type == 'approval_3', completeCard: itemDetails.status == 'complete', pendingCard: itemDetails.status == 'pending', rejectCard: itemDetails.status == 'rejected'}">
              <header class="card__header">
                <time class="time" *ngIf="itemDetails.start_datetime">
                  {{ itemDetails.start_datetime | date: 'longDate' }}
                </time>
                <h3 class="card__title r-title">{{itemDetails.name}}</h3>
              </header>
              <div class="card__content">
                <p>{{itemDetails.role}}</p>
                <p>Current status: <span [ngClass]="{complete_details: itemDetails.status == 'complete', pending_details: itemDetails.status == 'pending', rejected_details: itemDetails.status == 'rejected'}">{{itemDetails.status}}</span></p>
                
                
              </div>
            </div>


          </div>

        </div>
      </div>
    </div>
    <!--display final results -->
    <div [ngClass]="{stage_complete: thesisApproved === 'yes', stage_rejected : thesisApproved === 'no'}">
      <div class="timeline__group">
        <span *ngIf="thesisApproved === 'yes'" class="timeline__year time" aria-hidden="true">Accepted</span>
        <span *ngIf="thesisApproved === 'no'" class="timeline__year time" aria-hidden="true">Rejected</span>
        <div class="timeline__cards">
          <div [ngClass]="{timeline__card: thesisApproved, completeCard: thesisApproved === 'yes', rejectCard: thesisApproved === 'no'}">
            
            <div class="card__content">
              <span *ngIf="thesisApproved === 'yes'">Congratulations, Your thesis has been accepted.</span>
              <span *ngIf="thesisApproved === 'no'">Sorry, Your thesis has not been approved. Please contact your Major advisor for more details.</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>



  <div *ngIf="userType === 'major_advisor' || userType === 'dean_pgs'"  class="page">
    <div *ngFor="let item of timelineStageData" [ngClass]="[item.status]">
      <div class="timeline__group">
        <span class="timeline__year time" aria-hidden="true">{{item.stage_name}}</span>
        <div  *ngFor="let itemDetails of item.details" class="timeline__cards">

          <div *ngIf="item.stage_name == 'Submission 1'">
            <div *ngIf="itemDetails.type == 'upload'" [ngClass]="{timeline__card: itemDetails.type == 'upload', completeCard: itemDetails.status == 'complete', pendingCard: itemDetails.status == 'pending'}">
              <header class="card__header">
                <time class="time">
                  {{ itemDetails.start_datetime | date: 'longDate' }}
                </time>
              </header>
              <div style="margin-top: 2%;" class="card__content">
                <span [ngClass]="{complete_details: itemDetails.status == 'complete', pending_details: itemDetails.status == 'pending'}">Submitted</span>
              </div>
            </div>

            <div *ngIf="itemDetails.type == 'plagiarism'" [ngClass]="{timeline__card: itemDetails.type == 'plagiarism', completeCard: itemDetails.status == 'complete', pendingCard: itemDetails.status == 'pending'}">
              <div *ngIf="itemDetails.status == 'complete'">
                <header class="card__header">
                  <time class="time">
                    {{ itemDetails.end_datetime | date: 'longDate' }}
                  </time>
                </header>
                <div style="margin-top: 2%;" class="card__content">
                  <!-- <p>Plagiarism report: {{itemDetails.score}}%</p> -->
                  <p>
                    <!-- <a href="{{itemDetails.report_url}}" target="_self" download="{{thesisName+'_plagiarism_report.pdf'}}"> -->
                      <button class="enableBtn" (click)="downloadFiles(itemDetails.report_url, thesisName +'_plagiarism_report.pdf')" nz-button nzType="default" nzShape="round"><i nz-icon nzType="download"></i>Download plagiarism report</button>
                    <!-- </a> -->
                  </p>
                </div>
              </div>

              <div *ngIf="itemDetails.status == 'pending'">
                <header class="card__header">
                  <time class="time">
                    {{ itemDetails.start_datetime | date: 'longDate' }}
                  </time>
                </header>
                <div style="margin-top: 2%;" class="card__content">
                  <p> Please wait for the student to upload the plagiarism report</p>
                </div>
              </div>
            </div>

              <div *ngIf="itemDetails.type == 'review' && item.stage_name == 'Submission 1'" [ngClass]="{timeline__card: itemDetails.type == 'review', completeCard: itemDetails.status == 'complete', pendingCard: itemDetails.status == 'pending'}">
                <header class="card__header">
                  <time class="time" *ngIf="itemDetails.start_datetime">
                    {{ itemDetails.start_datetime | date: 'longDate' }}
                  </time>
                  <h3 class="card__title r-title">{{itemDetails.name}}</h3>
                </header>
                <div class="card__content">
                  <p>{{itemDetails.role === 'Internal Member' ? 'Advisory Committee Member' : itemDetails.role }}</p>
                  <p>Current status: <span [ngClass]="{complete_details: itemDetails.status == 'complete', pending_details: itemDetails.status == 'pending'}">{{itemDetails.status}}</span></p>
                  <p>
                    <!-- <a href="{{thesis_s1_url}}" target="_self" download="{{thesisName+'.docx'}}"> -->
                      <button class="enableBtn" (click)="downloadFiles(thesis_s1_url, thesisName +'.docx')" *ngIf="itemDetails.adv_id == sessionUserId && item.stage_name == 'Submission 1' && item.status !== 'stage_pending'" nz-button nzType="default" nzShape="round"><i nz-icon nzType="download"></i>Download thesis</button>
                    <!-- </a> -->
                        
                      <button class="enableBtn" (click)="selectFileUploadReviewDoc(itemDetails)" *ngIf="itemDetails.adv_id == sessionUserId && item.stage_name == 'Submission 1' && item.status == 'stage_ongoing' && itemDetails.status !== 'complete'" nz-button nzType="default" nzShape="round" style="margin-left: 1%;"><i nz-icon nzType="upload"></i>Upload reviewed document</button>
                      <input type="file" #ReviewFile style="display: none" (change)="onUploadReviewedThesis($event, itemDetails.adv_id, itemDetails.review_docid, itemDetails, 'submission_1')" />
                      
                    <button class="enableBtn" *ngIf="itemDetails.adv_id == sessionUserId && item.stage_name == 'Submission 1' && item.status !== 'stage_pending' && itemDetails.status !== 'complete'" (click)="markAsComplete(itemDetails, 'submission_1')" nz-button nzType="default" nzShape="round" style="margin-left: 1%;"><i nz-icon nzType="check"></i>Mark as complete</button>
                    

                    <a *ngIf="itemDetails.review_url !== ''">
                      <button class="enableBtn" (click)="downloadFiles(itemDetails.review_url, thesisName +'.docx')" *ngIf="itemDetails.adv_id !== sessionUserId && itemDetails.status === 'complete' && item.stage_name == 'Submission 1' && item.status !== 'stage_pending'" nz-button nzType="default" nzShape="round" style="margin-left: 1%;" ><i nz-icon nzType="download"></i>download reviewed document</button>
                    </a>
                    <a *ngIf="itemDetails.review_url !== ''">
                      <button class="enableBtn" (click)="downloadFiles(itemDetails.review_url, thesisName +'.docx')" *ngIf="itemDetails.adv_id === sessionUserId && itemDetails.status === 'complete' && item.stage_name == 'Submission 1' && item.status !== 'stage_pending'" nz-button nzType="default" nzShape="round" style="margin-left: 1%;"><i nz-icon nzType="download"></i>download reviewed document</button>
                    </a>
                    <time class="time" *ngIf="itemDetails.adv_id !== sessionUserId && itemDetails.review_url === '' && item.stage_name == 'Submission 1' && itemDetails.status === 'complete'">
                      No review document available
                    </time>
                    <button disabled *ngIf="itemDetails.adv_id !== sessionUserId && itemDetails.status === 'pending' && item.stage_name == 'Submission 1'" nz-button nzType="default" nzShape="round" style="margin-left: 1%;"><i nz-icon nzType="download"></i>download reviewed document</button>
                  
                  </p>
                  <div *ngIf="showUploadReviewSuccess && itemDetails.adv_id == sessionUserId && item.stage_name == 'Submission 1'" class="mt-1">
                    <span style="color: green;">You have successfully uploaded your review for the thesis.</span>
                  </div>
                </div>
              </div>

          </div>
          
          <div *ngIf="item.stage_name == 'Submission 2'">

            <div *ngIf="itemDetails.type == 'alert' && itemDetails.alert_type == 'previous_stage_incomplete'" [ngClass]="{timeline__card: itemDetails.type == 'alert', pendingCard: itemDetails.alert_type == 'previous_stage_incomplete'}">
             
              <div class="card__content">
                <p>{{itemDetails.message}}</p>
              </div>
            </div>
            
            <div *ngIf="itemDetails.type == 'alert' && itemDetails.alert_type == 'assign_external'" [ngClass]="{timeline__card: itemDetails.type == 'alert', pendingCard: itemDetails.alert_type == 'assign_external'}">
             
              <div class="card__content">
                <p>{{itemDetails.message}}</p>
                <!-- <div *ngIf="showExternalData">
                  <nz-select class="model-select width70" nzMode="multiple" nzPlaceHolder="Search for External member" (ngModelChange)="externalSelect($event)" [(ngModel)]="listOfExternalValue">
                    <nz-option *ngFor="let external of listOfExternals" [nzLabel]="external.name +' ('+ external.email +')'" [nzValue]="external.id" [nzHide]="!isNotSelected(external)"></nz-option>
                  </nz-select> <br>

                  <nz-select 
                  *ngIf="showAllExternalQuesData"
                    class="model-select width70 mt-1"
                    [nzMaxTagCount]="2"
                    [nzMaxTagPlaceholder]="tagPlaceHolder"
                    nzMode="multiple"
                    nzPlaceHolder="Please select questions"
                    [(ngModel)]="listOfExternalQuestionsValue"
                  >
                    <nz-option *ngFor="let item of listOfAllQuestions" nz-tooltip nzTooltipTrigger="focus" nzTooltipPlacement="topLeft" [nzTooltipTitle]="item.question_id" [nzLabel]="item.question_text" [nzValue]="item.question_id"></nz-option>
                  </nz-select>
                  <ng-template #tagPlaceHolder let-selectedList> and {{ selectedList.length }} more selected </ng-template>
                  <br>
                  <button class="enableBtn" (click)="assignExternals(thesisID,item.student_id)" style="margin-top: 1%;" nz-button nzType="default" nzShape="round"><i nz-icon nzType="plus-circle"></i>Assign</button>

                </div>  -->

              </div>
            </div>

            <div *ngIf="itemDetails.type == 'upload'" [ngClass]="{timeline__card: itemDetails.type == 'upload', completeCard: itemDetails.status == 'complete', pendingCard: itemDetails.status == 'pending'}">
              <div *ngIf="itemDetails.status == 'complete'">
                <header class="card__header">
                  <time class="time" *ngIf="itemDetails.start_datetime">
                    {{ itemDetails.start_datetime | date: 'longDate' }}
                  </time>
                </header>
                <div style="margin-top: 2%;" class="card__content">
                  <span [ngClass]="{complete_details: itemDetails.status == 'complete', pending_details: itemDetails.status == 'pending'}">Submitted</span>
                </div>
              </div>
              <div *ngIf="itemDetails.status == 'pending'">
                <header class="card__header">
                  <time class="time" *ngIf="itemDetails.start_datetime">
                    {{ itemDetails.start_datetime | date: 'longDate' }}
                  </time>
                </header>
                <div style="margin-top: 2%;" class="card__content">
                  Please wait for the revised version of the thesis to be uploaded by the student.
                  <!-- <span [ngClass]="{complete_details: itemDetails.status == 'complete', pending_details: itemDetails.status == 'pending'}">Submitted</span> -->
                </div>
              </div>
            </div>

            <div *ngIf="itemDetails.type == 'plagiarism'" [ngClass]="{timeline__card: itemDetails.type == 'plagiarism', completeCard: itemDetails.status == 'complete', pendingCard: itemDetails.status == 'pending'}">
              <div *ngIf="itemDetails.status == 'complete'">
                <header class="card__header">
                  <time class="time" *ngIf="itemDetails.end_datetime">
                    {{ itemDetails.end_datetime | date: 'longDate' }}
                  </time>
                </header>
                <div style="margin-top: 2%;" class="card__content">
                  <!-- <p>Plagiarism report: {{itemDetails.score}}%</p> -->
                  <p>
                    <!-- <a href="{{itemDetails.report_url}}" target="_self" download="{{thesisName+'_plagiarism_report_2.pdf'}}"> -->
                      <button class="enableBtn" (click)="downloadFiles(itemDetails.report_url, thesisName +'_plagiarism_report_2.pdf')" nz-button nzType="default" nzShape="round"><i nz-icon nzType="download"></i>Download plagiarism report</button>
                    <!-- </a> -->
                  </p>
                </div>
              </div>

              <div *ngIf="itemDetails.status == 'pending'">
                <header class="card__header">
                </header>
                <div style="margin-top: 2%;" class="card__content">
                  <p> Please wait for plagiarism report to be uploaded by the student.</p>
                </div>
              </div>
            </div>


            <div *ngIf="itemDetails.type == 'review' && item.stage_name == 'Submission 2'" [ngClass]="{timeline__card: itemDetails.type == 'review', completeCard: itemDetails.status == 'complete', pendingCard: itemDetails.status == 'pending'}">
              <header class="card__header">
                <time class="time" *ngIf="itemDetails.start_datetime">
                  {{ itemDetails.start_datetime | date: 'longDate' }}
                </time>
                <h3 class="card__title r-title">{{itemDetails.name}}</h3>
              </header>
              <div class="card__content">
                <p>{{itemDetails.role === 'Internal Member' ? 'Advisory Committee Member' : itemDetails.role }}</p>
                <p>Current status: <span [ngClass]="{complete_details: itemDetails.status == 'complete', pending_details: itemDetails.status == 'pending'}">{{itemDetails.status}}</span></p>
                
                <div *ngIf="itemDetails.enabled === false && itemDetails.adv_id == sessionUserId && item.stage_name == 'Submission 2' && itemDetails.status === 'pending'" class="mt-1">
                  <span style="color: red;margin-top: 1%;">{{itemDetails.message}}</span>
                </div>
                <div *ngIf="itemDetails.enabled === true" class="mt-1">
                  <p>
                    <!-- <a href="{{thesis_s2_url}}" target="_self" download="{{thesisName+'.docx'}}"> -->
                      <button class="enableBtn" (click)="downloadFiles(thesis_s2_url, thesisName +'.docx')" *ngIf="itemDetails.adv_id == sessionUserId && item.stage_name == 'Submission 2' && item.status !== 'stage_pending' && is_s2_url_valid !== 'pending'" nz-button nzType="default" nzShape="round"><i nz-icon nzType="download"></i>Download thesis</button>
                    <!-- </a> -->
                        
                      <button class="enableBtn" (click)="selectFileUploadReviewDoc_2(itemDetails)" *ngIf="itemDetails.adv_id == sessionUserId && item.stage_name == 'Submission 2' && item.status == 'stage_ongoing' && itemDetails.status !== 'complete' && is_s2_url_valid !== 'pending'" nz-button nzType="default" nzShape="round" style="margin-left: 1%;"><i nz-icon nzType="upload"></i>Upload reviewed document</button>
                      <input type="file" #ReviewFile_2 style="display: none" (change)="onUploadReviewedThesis_2($event, itemDetails.adv_id, itemDetails.review_docid, itemDetails, 'submission_2')" />
                      
                    <button class="enableBtn" *ngIf="itemDetails.adv_id == sessionUserId && item.stage_name == 'Submission 2' && item.status !== 'stage_pending' && itemDetails.status !== 'complete' && is_s2_url_valid !== 'pending'" (click)="markAsComplete(itemDetails, 'submission_2')" nz-button nzType="default" nzShape="round" style="margin-left: 1%;"><i nz-icon nzType="check"></i>Mark as complete</button>
                    
  
                    <a *ngIf="itemDetails.review_url !== ''">
                      <button class="enableBtn" (click)="downloadFiles(itemDetails.review_url, thesisName +'.docx')"  *ngIf="itemDetails.adv_id !== sessionUserId && itemDetails.status === 'complete' && item.stage_name == 'Submission 2' && item.status !== 'stage_pending'" nz-button nzType="default" nzShape="round" style="margin-left: 1%;"><i nz-icon nzType="download"></i>download reviewed document</button>
                    </a>
                    <a *ngIf="itemDetails.review_url !== ''">
                      <button class="enableBtn" (click)="downloadFiles(itemDetails.review_url, thesisName +'.docx')"  *ngIf="itemDetails.adv_id === sessionUserId && itemDetails.status === 'complete' && item.stage_name == 'Submission 2' && item.status !== 'stage_pending'" nz-button nzType="default" nzShape="round" style="margin-left: 1%;"><i nz-icon nzType="download"></i>download reviewed document</button>
                    </a>
                    <time class="time" *ngIf="itemDetails.adv_id !== sessionUserId && itemDetails.review_url === '' && item.stage_name == 'Submission 2' && itemDetails.status === 'complete'">
                      No review document available
                    </time>
  
                    <!-- {{item.stage_name}} == {{item.status}} == {{itemDetails.status}} == {{itemDetails.role}} -->
                    <button class="enableBtn" *ngIf="item.stage_name == 'Submission 2' && item.status !== 'stage_pending' && itemDetails.status === 'complete' && itemDetails.role === 'External'" (click)="showExternalAnswers(itemDetails)" nz-button nzType="default" nzShape="round" style="margin-left: 1%;"><i nz-icon nzType="read"></i>Show answers</button>
                    <button class="enableBtn" *ngIf="item.stage_name == 'Submission 2' && item.status !== 'stage_pending' && itemDetails.status === 'complete' && itemDetails.role === 'External'" (click)="viewDocument(itemDetails)" nz-button nzType="default" nzShape="round" style="margin-left: 1%;"><i nz-icon nzType="file-pdf"></i>View Document</button>
                    <!-- <button class="enableBtn" *ngIf="item.stage_name == 'Submission 2' && item.status !== 'stage_pending' && itemDetails.status === 'complete' && itemDetails.role === 'External'" (click)="viewDocument(itemDetails)" nz-button nzType="default" nzShape="round" style="margin-left: 1%;"><i nz-icon nzType="file-pdf"></i>View Document 45</button> -->
  
  
                    <button disabled *ngIf="itemDetails.adv_id !== sessionUserId && itemDetails.status === 'pending' && item.stage_name == 'Submission 2'" style="margin-left: 1%;" nz-button nzType="default" nzShape="round" style="margin-left: 1%;"><i nz-icon nzType="download"></i>download reviewed document</button>
                  
                  </p>
                </div>
                <div *ngIf="showUploadReviewSuccess && itemDetails.adv_id == sessionUserId && item.stage_name == 'Submission 2'" class="mt-1">
                  <span style="color: green;">You have successfully uploaded your review for the thesis.</span>
                </div>
              </div>
            </div>


            <div *ngIf="itemDetails.type == 'approval_1' && item.stage_name == 'Submission 2'" [ngClass]="{timeline__card: itemDetails.type == 'approval_1', completeCard: itemDetails.status == 'complete', pendingCard: itemDetails.status == 'pending', rejectCard: itemDetails.status == 'rejected'}">
              <header class="card__header">
                <time class="time" *ngIf="itemDetails.start_datetime">
                  {{ itemDetails.start_datetime | date: 'longDate' }}
                </time>
                <h3 class="card__title r-title">{{itemDetails.name}}</h3>
              </header>
              <div class="card__content">
                <p>{{itemDetails.role}}</p>
                <p>Current status: <span [ngClass]="{complete_details: itemDetails.status == 'complete', pending_details: itemDetails.status == 'pending', rejected_details: itemDetails.status == 'rejected'}">{{itemDetails.status}}</span></p>
                
                <div *ngIf="itemDetails.enabled === false && itemDetails.adv_id == sessionUserId && item.stage_name == 'Submission 2' && itemDetails.status === 'pending'" class="mt-1">
                  <!-- <span style="color: red;margin-top: 1%;">{{itemDetails.message}}</span> -->
                  <div>
                    <span>Assign externals to continue. </span><br>
                    <button class="enableBtn mt-1" *ngIf="itemDetails.adv_id == sessionUserId && item.stage_name == 'Submission 2' && item.status !== 'stage_pending' && itemDetails.status !== 'complete'" (click)="showExternalAssign = true" nz-button nzType="default" nzShape="round" style="margin-left: 0%;"><i nz-icon nzType="check"></i>Assign Externals</button>
                    <button class="rejectBtn mt-1" *ngIf="itemDetails.adv_id == sessionUserId && item.stage_name == 'Submission 2' && item.status !== 'stage_pending' && itemDetails.status !== 'complete'" (click)="rejectThesis(itemDetails, itemDetails.stage_type)" nz-button nzType="default" nzShape="round" style="margin-left: 1%;"><i nz-icon nzType="close"></i>Reject thesis</button>
  
                  </div>
                  <div *ngIf="showExternalAssign === true">
                    <div *ngIf="showExternalData">
                      <nz-select class="model-select width70 mt-1" nzMode="multiple" nzPlaceHolder="Search for External member" (ngModelChange)="externalSelect($event)" [(ngModel)]="listOfExternalValue">
                        <nz-option *ngFor="let external of listOfExternals" [nzLabel]="external.name +' ('+ external.email +')'" [nzValue]="external.id" [nzHide]="!isNotSelected(external)"></nz-option>
                      </nz-select> <br>
    
                      <nz-select 
                      *ngIf="showAllExternalQuesData"
                        class="model-select width70 mt-1"
                        [nzMaxTagCount]="2"
                        [nzMaxTagPlaceholder]="tagPlaceHolder"
                        nzMode="multiple"
                        nzPlaceHolder="Please select questions"
                        [(ngModel)]="listOfExternalQuestionsValue"
                      >
                        <nz-option *ngFor="let item of listOfAllQuestions" nz-tooltip nzTooltipTrigger="focus" nzTooltipPlacement="topLeft" [nzTooltipTitle]="item.question_id" [nzLabel]="item.question_text" [nzValue]="item.question_id"></nz-option>
                      </nz-select>
                      <ng-template #tagPlaceHolder let-selectedList> and {{ selectedList.length }} more selected </ng-template>
                      <br>
    
                      <button class="enableBtn mt-1" (click)="assignExternals(thesisID,item.student_id)"  nz-button nzType="default" nzShape="round"><i nz-icon nzType="plus-circle"></i>Assign</button>
                      <button class="rejectBtn mt-1" (click)="showExternalAssign = false" style="margin-left: 1%;" nz-button nzType="default" nzShape="round"><i nz-icon nzType="close-circle"></i>Cancel</button>
    
                    </div> 
                  </div>
                </div>

                

                <div *ngIf="itemDetails.enabled === true && itemDetails.adv_id == sessionUserId && item.stage_name == 'Submission 2' && itemDetails.status === 'pending'" class="mt-1">
                  <span>Approve thesis for external review.</span><br>
                  <button class="enableBtn mt-1" *ngIf="itemDetails.adv_id == sessionUserId && item.stage_name == 'Submission 2' && item.status !== 'stage_pending' && itemDetails.status !== 'complete'" (click)="markAsComplete(itemDetails, 'submission_2')" nz-button nzType="default" nzShape="round" style="margin-left: 0%;"><i nz-icon nzType="check"></i>Yes</button>
                  <button class="rejectBtn mt-1" *ngIf="itemDetails.adv_id == sessionUserId && item.stage_name == 'Submission 2' && item.status !== 'stage_pending' && itemDetails.status !== 'complete'" (click)="rejectThesis(itemDetails, itemDetails.stage_type)" nz-button nzType="default" nzShape="round" style="margin-left: 1%;"><i nz-icon nzType="close"></i>No</button>

                </div>
              </div>
            </div>

            <div *ngIf="itemDetails.type == 'approval_2' && item.stage_name == 'Submission 2'" [ngClass]="{timeline__card: itemDetails.type == 'approval_2', completeCard: itemDetails.status == 'complete', pendingCard: itemDetails.status == 'pending', rejectCard: itemDetails.status == 'rejected'}">
              <header class="card__header">
                <time class="time" *ngIf="itemDetails.start_datetime">
                  {{ itemDetails.start_datetime | date: 'longDate' }}
                </time>
                <h3 class="card__title r-title">{{itemDetails.name}}</h3>
              </header>
              <div class="card__content">
                <p>{{itemDetails.role}}</p>
                <p>Current status: <span [ngClass]="{complete_details: itemDetails.status == 'complete', pending_details: itemDetails.status == 'pending', rejected_details: itemDetails.status == 'rejected'}">{{itemDetails.status}}</span></p>
                
                <div *ngIf="itemDetails.enabled === false && itemDetails.adv_id == sessionUserId && item.stage_name == 'Submission 2' && itemDetails.status === 'pending'" class="mt-1">
                  <span style="color: red;margin-top: 1%;">{{itemDetails.message}}</span>
                </div>
                <div *ngIf="itemDetails.enabled === true && itemDetails.adv_id == sessionUserId && item.stage_name == 'Submission 2' && itemDetails.status === 'pending'" class="mt-1">
                  <span>Approve thesis for submission 3.</span><br>
                  <button class="enableBtn mt-1" *ngIf="itemDetails.adv_id == sessionUserId && item.stage_name == 'Submission 2' && item.status !== 'stage_pending' && itemDetails.status !== 'complete'" (click)="markAsComplete(itemDetails, 'submission_2')" nz-button nzType="default" nzShape="round" style="margin-left: 0%;"><i nz-icon nzType="check"></i>Yes</button>
                  <button class="rejectBtn mt-1" *ngIf="itemDetails.adv_id == sessionUserId && item.stage_name == 'Submission 2' && item.status !== 'stage_pending' && itemDetails.status !== 'complete'" (click)="rejectThesis(itemDetails, itemDetails.stage_type)" nz-button nzType="default" nzShape="round" style="margin-left: 1%;"><i nz-icon nzType="close"></i>No</button>

                </div>
              </div>
            </div>



            
          </div>

          <div *ngIf="item.stage_name == 'Submission 3'">
            <div *ngIf="itemDetails.type == 'alert' && itemDetails.alert_type == 'previous_stage_incomplete'" [ngClass]="{timeline__card: itemDetails.type == 'alert', pendingCard: itemDetails.alert_type == 'previous_stage_incomplete'}">
             
              <div class="card__content">
                <p>Please wait for all reviewers to submit their review in the previous stages.</p>
              </div>
            </div>
            <div *ngIf="itemDetails.type == 'upload'" [ngClass]="{timeline__card: itemDetails.type == 'upload', completeCard: itemDetails.status == 'complete', pendingCard: itemDetails.status == 'pending'}">
              <div *ngIf="itemDetails.status == 'complete'">
                <header class="card__header">
                  <time class="time" *ngIf="itemDetails.start_datetime">
                    {{ itemDetails.start_datetime | date: 'longDate' }}
                  </time>
                </header>
                <div style="margin-top: 2%;" class="card__content">
                  <span [ngClass]="{complete_details: itemDetails.status == 'complete', pending_details: itemDetails.status == 'pending'}">Submitted</span>
                </div>
              </div>
              <div *ngIf="itemDetails.status == 'pending'">
                <header class="card__header">
                  <time class="time" *ngIf="itemDetails.start_datetime">
                    {{ itemDetails.start_datetime | date: 'longDate' }}
                  </time>
                </header>
                <div style="margin-top: 2%;" class="card__content">
                  Please wait for the revised version of the thesis to be uploaded.
                  <!-- <span [ngClass]="{complete_details: itemDetails.status == 'complete', pending_details: itemDetails.status == 'pending'}">Submitted</span> -->
                </div>
              </div>
            </div>

            <div *ngIf="itemDetails.type == 'plagiarism'" [ngClass]="{timeline__card: itemDetails.type == 'plagiarism', completeCard: itemDetails.status == 'complete', pendingCard: itemDetails.status == 'pending'}">
              <div *ngIf="itemDetails.status == 'complete'">
                <header class="card__header">
                  <time class="time" *ngIf="itemDetails.end_datetime">
                    {{ itemDetails.end_datetime | date: 'longDate' }}
                  </time>
                </header>
                <div style="margin-top: 2%;" class="card__content">
                  <!-- <p>Plagiarism report: {{itemDetails.score}}%</p> -->
                  <p>
                    <!-- <a href="{{itemDetails.report_url}}" target="_self" download="{{thesisName+'_plagiarism_report_2.pdf'}}"> -->
                      <button class="enableBtn" (click)="downloadFiles(itemDetails.report_url, thesisName +'_action_report.pdf')"  nz-button nzType="default" nzShape="round"><i nz-icon nzType="download"></i>Download action report</button>
                    <!-- </a> -->
                  </p>
                </div>
              </div>

              <div *ngIf="itemDetails.status == 'pending'">
                <header class="card__header">
                  <time class="time" *ngIf="itemDetails.start_datetime">
                    {{ itemDetails.start_datetime | date: 'longDate' }}
                  </time>
                </header>
                <div style="margin-top: 2%;" class="card__content">
                  <p> Please wait for action report to be uploaded by the student.</p>
                </div>
              </div>
            </div>


            <div *ngIf="itemDetails.type == 'review' && item.stage_name == 'Submission 3'" [ngClass]="{timeline__card: itemDetails.type == 'review', completeCard: itemDetails.status == 'complete', pendingCard: itemDetails.status == 'pending'}">
              <header class="card__header">
                <time class="time" *ngIf="itemDetails.start_datetime">
                  {{ itemDetails.start_datetime | date: 'longDate' }}
                </time>
                <h3 class="card__title r-title">{{itemDetails.name}}</h3>
              </header>
              <div class="card__content">
                <p>{{itemDetails.role === 'Internal Member' ? 'Advisory Committee Member' : itemDetails.role }}</p>
                <p>Current status: <span [ngClass]="{complete_details: itemDetails.status == 'complete', pending_details: itemDetails.status == 'pending'}">{{itemDetails.status}}</span></p>
                <p>
                  <!-- <a href="{{thesis_s3_url}}" target="_self" download="{{thesisName+'.docx'}}"> -->
                    <button class="enableBtn" (click)="downloadFiles(thesis_s3_url, thesisName +'.docx')" *ngIf="itemDetails.adv_id == sessionUserId && item.stage_name == 'Submission 3' && item.status !== 'stage_pending' && is_s2_url_valid !== 'pending'" nz-button nzType="default" nzShape="round"><i nz-icon nzType="download"></i>Download thesis</button>
                  <!-- </a> -->
                      
                    <button class="enableBtn" (click)="selectFileUploadReviewDoc_3(itemDetails)" *ngIf="itemDetails.adv_id == sessionUserId && item.stage_name == 'Submission 3' && item.status == 'stage_ongoing' && itemDetails.status !== 'complete' && is_s2_url_valid !== 'pending'" nz-button nzType="default" nzShape="round" style="margin-left: 1%;"><i nz-icon nzType="upload"></i>Upload reviewed document</button>
                    <input type="file" #ReviewFile_3 style="display: none" (change)="onUploadReviewedThesis_3($event, itemDetails.adv_id, itemDetails.review_docid, itemDetails, 'submission_3')" />
                    
                  <button class="enableBtn" *ngIf="itemDetails.adv_id == sessionUserId && item.stage_name == 'Submission 3' && item.status !== 'stage_pending' && itemDetails.status !== 'complete' && is_s2_url_valid !== 'pending'" (click)="markAsComplete(itemDetails, 'submission_3')" nz-button nzType="default" nzShape="round" style="margin-left: 1%;"><i nz-icon nzType="check"></i>Mark as complete</button>
                  

                  <a *ngIf="itemDetails.review_url !== ''">
                    <button class="enableBtn" (click)="downloadFiles(itemDetails.review_url, thesisName +'.docx')" *ngIf="itemDetails.adv_id !== sessionUserId && itemDetails.status === 'complete' && item.stage_name == 'Submission 3' && item.status !== 'stage_pending'" style="margin-left: 1%;" nz-button nzType="default" nzShape="round"><i nz-icon nzType="download"></i>download reviewed document</button>
                  </a>
                  <a *ngIf="itemDetails.review_url !== ''">
                    <button class="enableBtn" (click)="downloadFiles(itemDetails.review_url, thesisName +'.docx')" *ngIf="itemDetails.adv_id === sessionUserId && itemDetails.status === 'complete' && item.stage_name == 'Submission 3' && item.status !== 'stage_pending'" nz-button nzType="default" nzShape="round" style="margin-left: 1%;"><i nz-icon nzType="download"></i>download reviewed document</button>
                  </a>
                  <time class="time" *ngIf="itemDetails.adv_id !== sessionUserId && itemDetails.review_url === '' && item.stage_name == 'Submission 3' && itemDetails.status === 'complete'">
                    No review document available
                  </time>

                  <button disabled *ngIf="itemDetails.adv_id !== sessionUserId && itemDetails.status === 'pending' && item.stage_name == 'Submission 3'" nz-button nzType="default" nzShape="round" style="margin-left: 1%;"><i nz-icon nzType="download"></i>download reviewed document</button>
                
                </p>
                <div *ngIf="showUploadReviewSuccess && itemDetails.adv_id == sessionUserId && item.stage_name == 'Submission 3'" class="mt-1">
                  <span style="color: green;">You have successfully uploaded your review for the thesis.</span>
                </div>
              </div>
            </div>

            <div *ngIf="itemDetails.type == 'approval_3' && item.stage_name == 'Submission 3'" [ngClass]="{timeline__card: itemDetails.type == 'approval_3', completeCard: itemDetails.status == 'complete', pendingCard: itemDetails.status == 'pending', rejectCard: itemDetails.status == 'rejected'}">
              <header class="card__header">
                <time class="time" *ngIf="itemDetails.start_datetime">
                  {{ itemDetails.start_datetime | date: 'longDate' }}
                </time>
                <h3 class="card__title r-title">{{itemDetails.name}}</h3>
              </header>
              <div class="card__content">
                <p>{{itemDetails.role}}</p>
                <p>Current status: <span [ngClass]="{complete_details: itemDetails.status == 'complete', pending_details: itemDetails.status == 'pending', rejected_details: itemDetails.status == 'rejected'}">{{itemDetails.status}}</span></p>
                
                <div *ngIf="itemDetails.enabled === false && itemDetails.adv_id == sessionUserId && item.stage_name == 'Submission 3' && itemDetails.status === 'pending'" class="mt-1">
                  <span style="color: red;margin-top: 1%;">{{itemDetails.message}}</span>
                </div>
                <div *ngIf="itemDetails.enabled === true && itemDetails.adv_id == sessionUserId && item.stage_name == 'Submission 3' && itemDetails.status === 'pending'" class="mt-1">
                  <button class="enableBtn" *ngIf="itemDetails.adv_id == sessionUserId && item.stage_name == 'Submission 3' && item.status !== 'stage_pending' && itemDetails.status !== 'complete'" (click)="markAsComplete(itemDetails, 'submission_3')" nz-button nzType="default" nzShape="round" style="margin-left: 0%;"><i nz-icon nzType="check"></i>Approve thesis</button>
                  <button class="rejectBtn" *ngIf="itemDetails.adv_id == sessionUserId && item.stage_name == 'Submission 3' && item.status !== 'stage_pending' && itemDetails.status !== 'complete'" (click)="rejectThesis(itemDetails, itemDetails.stage_type)"  nz-button nzType="default" nzShape="round" style="margin-left: 1%;"><i nz-icon nzType="close"></i>Reject thesis</button>

                </div>
              </div>
            </div>


          </div>

          

        </div>
      </div>
    </div>
    <!--display final results -->
    <div [ngClass]="{stage_complete: thesisApproved === 'yes', stage_rejected : thesisApproved === 'no'}">
      <div class="timeline__group">
        <span *ngIf="thesisApproved === 'yes'" class="timeline__year time" aria-hidden="true">Accepted</span>
        <span *ngIf="thesisApproved === 'no'" class="timeline__year time" aria-hidden="true">Rejected</span>
        <div class="timeline__cards">
          <div [ngClass]="{timeline__card: thesisApproved, completeCard: thesisApproved === 'yes', rejectCard: thesisApproved === 'no'}">
            
            <div class="card__content">
              <span *ngIf="thesisApproved === 'yes'">This thesis has been accepted.</span>
              <span *ngIf="thesisApproved === 'no'">This thesis has been rejected.</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <nz-modal [(nzVisible)]="isAnswerVisible" nzOkText="Ok"  nzWidth="800px" nzTitle="Questionnaire" (nzOnCancel)="handleAnswerCancel()" (nzOnOk)="handleAnswerOk()">
    <ng-container>
      <div *ngIf="displayQuestions" style="max-height: 40vh;overflow: scroll;">
        <div class="questionList" *ngFor="let ques of questions_data; let i = index">

          <div class="content-body" >
            <p class="question_text">{{i+1}}) {{ques.question_text}}</p>
         </div>
         <div class="answer_text">
          <span> {{ques.answer_text}}</span>
         </div>

        </div>
      </div>
    </ng-container>
  </nz-modal>
</div>